<template>
  <div class="all">
    <!-- <img class="Me" src="../assets/Sibel.png" />   -->
    <div style="justify-content: center; display: flex" class="col1-wrapper">
      <div class="contentPP">
        <div class="planett">
          <div class="ringg"></div>
          <div class="cover-ringg"></div>
          <div class="spotss">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>

    <div class="wrapper">
      <div class="typing">Bienvenue sur mon portfolio.</div>
    </div>

    <div class="Big-container">
      <ul class="flex-container">
        <li class="flex-item">
          <img
            src="../assets/me.png"
            alt="aboutMe"
            style="
              width: 100%;
              margin: 2px auto;
              border-radius: 20%;
              display: flex;
              background-color: #3b3c64;
              cursor: pointer;
            "
            @click="small = true"
          />
          <p style="font-size: 10px">A propos</p>
          <q-dialog v-model="small">
            <q-card style="width: 600px; background-color: #180429">
              <q-card-section>
                <div class="title-about-me">A propos de moi</div>
              </q-card-section>

              <q-card-section class="q-pt-none">
                <div class="text">
                  Hello, moi c'est Sibel 👋 <br /><br />
                  Diplômée d’un
                  <b style="color: #40cfef"
                    >BAC+3 en Chef de Projet Digital Marketing</b
                  >
                  à l’EEMI, je recherche de nouvelles opportunités pour relever
                  des défis en
                  <b style="color: #40cfef"
                    >marketing digital, communication globale, webdesign</b
                  >
                  ou <b style="color: #40cfef">e-commerce</b>. <br /><br />
                  Actuellement, j’approfondis mes compétences en e-commerce via
                  WordPress et Shopify et je me forme sur la suite Adobe
                  (Photoshop, Illustrator, After Effects).
                  <br /><br />
                  Par la suite, je souhaite intégrer un MBA (BAC+5) en marketing
                  digital pour approfondir mes connaissances 👩‍💻
                </div>
              </q-card-section>

              <q-card-actions align="right" style="background-color: #454b69">
                <q-btn flat label="OK" style="color: #40cfef" v-close-popup />
              </q-card-actions>
            </q-card>
          </q-dialog>
        </li>

        <li class="flex-item">
          <img
            src="../assets/Comp.png"
            alt="logoSkills"
            style="
              width: 100%;
              margin: 2px auto;
              border-radius: 20%;
              display: flex;
              background-color: #3b3c64;
              cursor: pointer;
            "
            @click="cardCompetence = true"
          />
          <p style="font-size: 10px">Compétences</p>
          <q-dialog v-model="cardCompetence">
            <q-card
              style="width: 700px; max-width: 80vw; background-color: #180429"
            >
              <q-card-section>
                <div class="text-h6">Compétences</div>
              </q-card-section>

              <q-card-section class="q-pt-none">
                <div
                  style="font-family: 'made_tommy', sans-serif; color: #f5f5f5"
                  class="rating"
                >
                  HTML &amp; CSS
                  <q-rating
                    v-model="ratingModelHTML"
                    style="color: #40cfef"
                    size="1.5em"
                    readonly
                  />
                </div>

                <div
                  style="font-family: 'made_tommy', sans-serif; color: #f5f5f5"
                  class="rating"
                >
                  Javascript<q-rating
                    v-model="ratingModelJS"
                    style="color: #40cfef"
                    size="1.5em"
                    readonly
                  />
                </div>

                <div
                  style="font-family: 'made_tommy', sans-serif; color: #f5f5f5"
                  class="rating"
                >
                  PhpMyAdmin/MySQL/VSC<q-rating
                    v-model="ratingModelPhpMyAdmin"
                    style="color: #40cfef"
                    size="1.5em"
                    readonly
                  />
                </div>

                <div
                  style="font-family: 'made_tommy', sans-serif; color: #f5f5f5"
                  class="rating"
                >
                  Quasar/Bootstrap<q-rating
                    v-model="ratingModelQuasar"
                    style="color: #40cfef"
                    size="1.5em"
                    readonly
                  />
                </div>

                <div
                  style="font-family: 'made_tommy', sans-serif; color: #f5f5f5"
                  class="rating"
                >
                  React/Vue.js/Node.js<q-rating
                    v-model="ratingModelReact"
                    style="color: #40cfef"
                    size="1.5em"
                    readonly
                  />
                </div>

                <div
                  style="font-family: 'made_tommy', sans-serif; color: #f5f5f5"
                  class="rating"
                >
                  CMS Wordpress &amp; Shopify<q-rating
                    v-model="ratingModelCMS"
                    style="color: #40cfef"
                    size="1.5em"
                    readonly
                  />
                </div>

                <div
                  style="font-family: 'made_tommy', sans-serif; color: #f5f5f5"
                  class="rating"
                >
                  Jira / Trello<q-rating
                    v-model="ratingModelJira"
                    style="color: #40cfef"
                    size="1.5em"
                    readonly
                  />
                </div>

                <div
                  style="font-family: 'made_tommy', sans-serif; color: #f5f5f5"
                  class="rating"
                >
                  Photopea &amp; Canva<q-rating
                    v-model="ratingModelCanva"
                    style="color: #40cfef"
                    size="1.5em"
                    readonly
                  />
                </div>

                <div
                  style="font-family: 'made_tommy', sans-serif; color: #f5f5f5"
                  class="rating"
                >
                  Github &amp; Gitlab &amp; Git<q-rating
                    v-model="ratingModelGit"
                    style="color: #40cfef"
                    size="1.5em"
                    readonly
                  />
                </div>

                <div
                  style="font-family: 'made_tommy', sans-serif; color: #f5f5f5"
                  class="rating"
                >
                  UI-UX/Figma/Mockflow<q-rating
                    v-model="ratingModelFigma"
                    style="color: #40cfef"
                    size="1.5em"
                    readonly
                  />
                </div>

                <div
                  style="font-family: 'made_tommy', sans-serif; color: #f5f5f5"
                  class="rating"
                >
                  SemRush/SEO<q-rating
                    v-model="ratingModelSemrush"
                    style="color: #40cfef"
                    size="1.5em"
                    readonly
                  />
                </div>
                <div
                  style="font-family: 'made_tommy', sans-serif; color: #f5f5f5"
                  class="rating"
                >
                  Google Ads<q-rating
                    v-model="ratingModelGGads"
                    style="color: #40cfef"
                    size="1.5em"
                    readonly
                  />
                </div>
                <div
                  style="font-family: 'made_tommy', sans-serif; color: #f5f5f5"
                  class="rating"
                >
                  Photoshop, Indesign<q-rating
                    v-model="ratingModelAdobes"
                    style="color: #40cfef"
                    size="1.5em"
                    readonly
                  />
                </div>
                <div
                  style="font-family: 'made_tommy', sans-serif; color: #f5f5f5"
                  class="rating"
                >
                  Bureautique (office 365)<q-rating
                    v-model="ratingModelOffice"
                    style="color: #40cfef"
                    size="1.5em"
                    readonly
                  />
                </div>
              </q-card-section>

              <q-card-actions align="right" style="background-color: #454b69">
                <q-btn flat label="OK" style="color: #40cfef" v-close-popup />
              </q-card-actions>
            </q-card>
          </q-dialog>
        </li>

        <li class="flex-item">
          <img
            src="../assets/dip.png"
            alt="logoFormations"
            style="
              width: 100%;
              margin: 2px auto;
              border-radius: 20%;
              display: flex;
              background-color: #3b3c64;
              cursor: pointer;
            "
            @click="openDiplome('right')"
          />
          <p style="font-size: 10px">Diplômes</p>
          <q-dialog v-model="dialogDiplome" :position="positionDiplome">
            <q-card style="background-color: #180429">
              <q-card-section class="row items-center no-wrap">
                <div>
                  <div
                    style="
                      font-size: 1.5em;
                      font-family: 'made_tommy', sans-serif;
                      color: #94745b;
                    "
                    class="text-weight-bold"
                  >
                    <!-- <div class="text-h6" style="color: #8892b0; font-size: 20px; margin-bottom:10px;">Cheffe de Projet Digital spécialité</div>
                  
                          <div class="display" style="display:flex; width:100%;">
                            <q-btn
                              outline 
                              label="Marketing &amp; e-Business"
                              style="color: #40cfef;"
                              @click="smallDiplome = true"
                            />
                            <q-dialog v-model="smallDiplome">
                              <q-card style="width: 90%; background-color: #180429;">
                                <q-card-section>
                                  <div class="text-h6" style=" font-size: 20px;">
                                    <b>Marketing &amp; e-Business</b>
                                  </div>
                                </q-card-section>

                                  <q-card-section style="background-color: #180429;" >
                                      <div class="text">
                                        <b style="color: #8892b0; font-size: 18px;">Initiation :</b>
                                        <ul class="tech-stack">
                                          <li style="color: #f5f5f5 ; font-size :16px; font-weight:600;">➤ Marketing Stratégique</li>
                                          <li style="color: #f5f5f5 ; font-size :16px; font-weight:600;">➤ Social Media</li>
                                          <li style="color: #f5f5f5 ; font-size :16px; font-weight:600;">➤ Social Selling</li>
                                          <li style="color: #f5f5f5 ; font-size :16px; font-weight:600;">➤ SEO/SEM</li>
                                          <li style="color: #f5f5f5 ; font-size :16px; font-weight:600;">➤ Neuromarketing</li>
                                          <li style="color: #f5f5f5 ; font-size :16px; font-weight:600;">➤ IA</li>
                                          <li style="color: #f5f5f5 ; font-size :16px; font-weight:600;">➤ Sites E-commerce</li>
                                          <li style="color: #f5f5f5 ; font-size :16px; font-weight:600;">➤ Création de contenu</li>
                                          <li style="color: #f5f5f5 ; font-size :16px; font-weight:600;">➤ Copy Strategy</li>
                                          <li style="color: #f5f5f5 ; font-size :16px; font-weight:600;">➤ Influence</li>
                                          <li style="color: #f5f5f5 ; font-size :16px; font-weight:600;">➤ Benchmark et veille concurrentielle</li>
                                          <li style="color: #f5f5f5 ; font-size :16px; font-weight:600;">➤ Automatisation</li>
                                          <li style="color: #f5f5f5 ; font-size :16px; font-weight:600;">➤ Google Analytics</li>
                                          <li style="color: #f5f5f5 ; font-size :16px; font-weight:600;">➤ DataViz</li>
                                          <li style="color: #f5f5f5 ; font-size :16px; font-weight:600;">➤ Customer Data Platform</li>
                                          <li style="color: #f5f5f5 ; font-size :16px; font-weight:600;">➤ Data Management Platform</li>
                                        </ul>


                                        <div class="container">
                                          <q-btn
                                              outline 
                                              label="Voir le programme en détail"
                                              style="color: #40cfef;"
                                              href="https://www.eemi.com/formations/bachelor-digital-marketing-business/"
                                              target="_blank"
                                            @click="small = true"
                                          />
                                        </div>

        
                                      </div> 
                                   </q-card-section>

                                    <q-card-actions align="right" style="background-color: #454B69;" >
                                      <q-btn flat label="OK" style="color: #40cfef;" v-close-popup />
                                    </q-card-actions>
                                 </q-card>
                            </q-dialog>
                        </div>  -->

                    <div
                      class="text-h6"
                      style="
                        color: #8892b0;
                        font-size: 20px;
                        margin-bottom: 5px;
                        margin-top: 30px;
                      "
                    >
                      Mon parcours :
                    </div>
                    <q-expansion-item
                      expand-separator
                      label="2024 - 2023"
                      style="
                        border: 2px solid;
                        flex-wrap: wrap;
                        background-color: black;
                        color: #40cfef;
                        font-size: 14px;
                      "
                    >
                      <q-card>
                        <q-card-section>
                          <div class="title-panel-parcours">
                            BAC+3 Cheffe de Projet Digital alternance
                          </div>
                          <div class="text-panel-parcours">
                            Enseignement supérieure à l'EEMI avec l'entreprise
                            B&amp;C Formation.
                          </div>
                          <div class="mission-panel-parcours">
                            ➤ Refonte et optimisation du site web<br />
                            ➤ Community Management : Gestion de la communication
                            sur les réseaux sociaux<br />
                            ➤ Création de newsletters internes et externes<br />
                            ➤ Conception de kits de communication pour des
                            salons étudiants<br />
                            ➤ Réalisation de visuels tels que flyers et
                            affiches<br />
                            ➤ Collaboration à la conception d'un jeu sur la
                            sécurité avec un formateur<br />
                            ➤ Piloter un projet <br />
                            ➤ Démarchage téléphonique <br />
                          </div>
                        </q-card-section>
                      </q-card>
                    </q-expansion-item>

                    <q-expansion-item
                      expand-separator
                      label="2020 - 2022"
                      style="
                        border: 2px solid;
                        flex-wrap: wrap;
                        background-color: black;
                        color: #40cfef;
                        font-size: 14px;
                      "
                    >
                      <q-card>
                        <q-card-section>
                          <div class="title-panel-parcours">
                            BAC+2 BTS SIO alternance
                          </div>
                          <div class="text-panel-parcours">
                            Centre de formation à l'Aftec avec l'entreprise Cood
                            du Lab'O d'Orléans.
                          </div>
                          <div class="mission-panel-parcours">
                            ➤ Refonte visuelle et structurelle du site officiel
                            de Cood.<br />
                            ➤ Création de maquette.<br />
                            ➤ Création d'une documentation interactive | quasar-
                            vue.js<br />
                            ➤ Refonte de certains jeux et tutos avec la
                            technologie blockly<br />
                            ➤ Création d'illustration d'univers de jeu à travers
                            des assets existants.<br />
                            ➤ Communication hebdomadaires.<br />
                          </div>
                        </q-card-section>
                      </q-card>
                    </q-expansion-item>

                    <q-expansion-item
                      expand-separator
                      label="2019 - 2020"
                      style="
                        border: 2px solid;
                        flex-wrap: wrap;
                        background-color: black;
                        color: #40cfef;
                        font-size: 14px;
                      "
                    >
                      <q-card>
                        <q-card-section>
                          <div class="title-panel-parcours">
                            Titre professionnel Développeuse web et web mobile
                          </div>
                          <div class="text-panel-parcours">
                            Formation à la Wild Code School spécialisation en
                            JavaScript
                          </div>
                          <div class="mission-panel-parcours">
                            ➤ Etude sur les langages de programmation HTML/CSS,
                            Python, JavaScript.<br />
                            ➤ Design: UX UI<br />
                            ➤ Découverte et initiation de logiciels : MySql,
                            PhpMyAdmin, VSC Visual Studio Code, Github,
                            Gitlab<br />
                            ➤ Méthode agile : SCRUM<br />
                            ➤ Relation Client pour projet<br />
                            ➤ Participation à des hackathons.<br />
                          </div>
                        </q-card-section>
                      </q-card>
                    </q-expansion-item>

                    <q-expansion-item
                      expand-separator
                      label="2017 - 2018"
                      style="
                        border: 2px solid;
                        flex-wrap: wrap;
                        background-color: black;
                        color: #40cfef;
                        font-size: 14px;
                      "
                    >
                      <q-card>
                        <q-card-section>
                          <div class="title-panel-parcours">
                            Baccalauréat STMG - SIG
                          </div>
                          <div class="text-panel-parcours">
                            - STMG : Sciences et Technologies du Management et
                            de la Gestion<br /><br />
                            - SIG : Systèmes d'Information et de Gestion<br />
                            <div class="mission-panel-parcours">
                              ➤ Programmation web HTML, CSS, PHP et Python.
                              <br />
                              ➤ Bases de données language : SQL<br />
                              ➤ Communication de machine : Réseaux
                              informatiques<br />
                              ➤ Notions connexes : gestion de projet, métiers de
                              l'informatique<br />
                            </div>
                          </div>
                        </q-card-section>
                      </q-card>
                    </q-expansion-item>
                  </div>
                </div>
              </q-card-section>

              <q-card-actions align="right" style="background-color: #454b69">
                <q-btn flat label="OK" style="color: #40cfef" v-close-popup />
              </q-card-actions>
            </q-card>
          </q-dialog>
        </li>

        <li class="flex-item">
          <img
            src="../assets/crea.png"
            alt="creation"
            style="
              width: 100%;
              margin: 2px auto;
              border-radius: 20%;
              display: flex;
              background-color: #3b3c64;
              cursor: pointer;
            "
            @click="dialogMaxToggle = true"
          />
          <p style="font-size: 10px">Créations</p>
          <q-dialog v-model="dialogMaxToggle">
            <q-card style="background-color: #454b69">
              <q-card
                style="
                  background-color: #f5f5f5;
                  margin: 20px;
                  border-radius: 20px;
                  top: 10;
                "
              >
                <q-card-section style="background-color: #180429">
                  <div class="folder-icon">
                    <svg
                      class="MuiSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      style="font-size: 35px"
                    >
                      <path
                        d="M20 6h-8l-1.41-1.41C10.21 4.21 9.7 4 9.17 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-1 12H5c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1z"
                      ></path>
                    </svg>
                    <div
                      class="text-h6"
                      style="
                        color: #8892b0;
                        font-size: 20px;
                        margin-bottom: 10px;
                      "
                    >
                      B&amp;C Formation
                    </div>
                    <br />
                  </div>
                  <div class="text-subtitle2">Création de flyers</div>
                  <div class="text-grey">
                    Chez B&amp;C Formation, j’ai réalisé la création d’affiches
                    pour divers événements, notamment des portes ouvertes, des
                    collaborations avec des partenaires, ainsi que des cartes de
                    vœux et d’autres supports visuels destinés à renforcer la
                    communication de l’entreprise.
                  </div>
                </q-card-section>

                <q-separator />

                <q-card-actions
                  style="justify-content: end; background-color: black"
                >
                  <span class="external-links">
                    <svg
                      class="MuiSvgIcon-root"
                      @click="carouselFlyers = true"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      style="font-size: 25px; color: #f5f5f5"
                    >
                      <path
                        d="M19 4H5c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h4v-2H5V8h14v10h-4v2h4c1.1 0 2-.9 2-2V6c0-1.1-.89-2-2-2zm-7 6l-4 4h3v6h2v-6h3l-4-4z"
                      ></path>
                    </svg>
                    <q-dialog v-model="carouselFlyers">
                      <q-carousel
                        transition-prev="slide-right"
                        transition-next="slide-left"
                        swipeable
                        animated
                        v-model="slide"
                        control-color="primary"
                        navigation
                        padding
                        height="500px"
                        max-width="100%"
                        class="bg-white shadow-1 rounded-borders"
                      >
                        <q-carousel-slide
                          :name="1"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/FlyersJPO.png"
                            alt="Affiche journée porte ouverte"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="2"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/FlyersVisuelFormation.png"
                            alt="Affiche, Visuel de formation"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="3"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/FlyersSekuritelle2.png"
                            alt="Première affiche de Sékurit'elles"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="4"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/FlyersSekuritelle1.png"
                            alt="Deuxieme affiche de Sékuit'elles"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="5"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/FlyersCartedeVœux31.png"
                            alt="Carte de voeux 31"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="6"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/FlyersCartedeVœux32.png"
                            alt="Carte de voeux 32"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="7"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/FlyersCartedeVœux21.png"
                            alt="Carte de voeux 21"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="8"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/FlyersCartedeVœux22.png"
                            alt="Carte de voeux 22"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="9"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/FlyersCartedeVœux11.png"
                            alt="Carte de voeux 11"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="10"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/FlyersCartedeVœux12.png"
                            alt="Carte de Voeux 12"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                      </q-carousel>
                    </q-dialog>
                  </span>
                </q-card-actions>
              </q-card>

              <q-card
                style="
                  background-color: #f5f5f5;
                  margin: 20px;
                  border-radius: 20px;
                  top: 10;
                "
              >
                <q-card-section style="background-color: #180429">
                  <div class="folder-icon">
                    <svg
                      class="MuiSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      style="font-size: 35px"
                    >
                      <path
                        d="M20 6h-8l-1.41-1.41C10.21 4.21 9.7 4 9.17 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-1 12H5c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1z"
                      ></path>
                    </svg>
                    <div
                      class="text-h6"
                      style="
                        color: #8892b0;
                        font-size: 20px;
                        margin-bottom: 10px;
                      "
                    >
                      B&amp;C Formation
                    </div>
                    <br />
                  </div>
                  <div class="text-subtitle2">
                    Création de kit pour des salons étudiant
                  </div>
                  <div class="text-grey">
                    Chez B&amp;C Formation, j’ai conçu des kits pour des
                    événements comme des portes ouvertes et le Salon de
                    l'Étudiant, incluant la création de maquettes, le
                    chiffrement, et la conception de goodies avant de finaliser
                    les commandes.
                  </div>
                </q-card-section>

                <q-separator />

                <q-card-actions
                  style="justify-content: end; background-color: black"
                >
                  <span class="external-links">
                    <svg
                      class="MuiSvgIcon-root"
                      @click="carouselKit = true"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      style="font-size: 25px; color: #f5f5f5"
                    >
                      <path
                        d="M19 4H5c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h4v-2H5V8h14v10h-4v2h4c1.1 0 2-.9 2-2V6c0-1.1-.89-2-2-2zm-7 6l-4 4h3v6h2v-6h3l-4-4z"
                      ></path>
                    </svg>
                    <q-dialog v-model="carouselKit">
                      <q-carousel
                        transition-prev="slide-right"
                        transition-next="slide-left"
                        swipeable
                        animated
                        v-model="slide"
                        control-color="primary"
                        navigation
                        padding
                        max-height="100%"
                        max-width="100%"
                        class="bg-white shadow-1 rounded-borders"
                      >
                        <q-carousel-slide
                          :name="1"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/brochure1.png"
                            alt="Brochure1"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="2"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/brochure2.png"
                            alt="Brochure 2"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="4"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/brochure4.jpg"
                            alt="Brochure 4"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="5"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/chemise.png"
                            alt="Chemise a rabat"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="6"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/chemise2.jpg"
                            alt="Chemise a rabat"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="7"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/kakemono.jpg"
                            alt="Kakemono"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="8"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/kakemono1.png"
                            alt="Kakemono 1"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="9"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/kakemono2.png"
                            alt="Kakemono 2"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="10"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/kakemono3.png"
                            alt="Kakemono 3"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="11"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/kakemono4.png"
                            alt="Kakemono 4"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="12"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/Houssetable.png"
                            alt="housse de table 1"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="13"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/houssetablle.png"
                            alt="Housse de table 2"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                      </q-carousel>
                    </q-dialog>
                  </span>
                </q-card-actions>
              </q-card>

              <q-card
                style="
                  background-color: #f5f5f5;
                  margin: 20px;
                  border-radius: 20px;
                  top: 10;
                "
              >
                <q-card-section style="background-color: #180429">
                  <div class="folder-icon">
                    <svg
                      class="MuiSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      style="font-size: 35px"
                    >
                      <path
                        d="M20 6h-8l-1.41-1.41C10.21 4.21 9.7 4 9.17 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-1 12H5c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1z"
                      ></path>
                    </svg>
                    <div
                      class="text-h6"
                      style="
                        color: #8892b0;
                        font-size: 20px;
                        margin-bottom: 10px;
                      "
                    >
                      B&amp;C Formation
                    </div>
                    <br />
                  </div>
                  <div class="text-subtitle2">
                    Création de roll-up pour B&amp;C Formation
                  </div>
                  <div class="text-grey">
                    J'ai B&amp;C Formation, j'ai conçu des roll-ups selon
                    l'identité visuel.
                  </div>
                </q-card-section>

                <q-separator />

                <q-card-actions
                  style="justify-content: end; background-color: black"
                >
                  <span class="external-links">
                    <svg
                      class="MuiSvgIcon-root"
                      @click="carouselId = true"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      style="font-size: 25px; color: #f5f5f5"
                    >
                      <path
                        d="M19 4H5c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h4v-2H5V8h14v10h-4v2h4c1.1 0 2-.9 2-2V6c0-1.1-.89-2-2-2zm-7 6l-4 4h3v6h2v-6h3l-4-4z"
                      ></path>
                    </svg>
                    <q-dialog v-model="carouselId">
                      <q-carousel
                        transition-prev="slide-right"
                        transition-next="slide-left"
                        swipeable
                        animated
                        v-model="slide"
                        control-color="primary"
                        navigation
                        padding
                        height="800px"
                        max-width="100%"
                        class="bg-white shadow-1 rounded-borders"
                      >
                        <q-carousel-slide
                          :name="1"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/rollUp1.png"
                            alt="Roll up 1"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="2"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/rollUp2.png"
                            alt="Roll Up 2"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="3"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/rollUp3.png"
                            alt="roll Up 3"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="4"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/rollUp4.png"
                            alt="Roll Up 4"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                      </q-carousel>
                    </q-dialog>
                  </span>
                </q-card-actions>
              </q-card>

              <q-card
                style="
                  background-color: #f5f5f5;
                  margin: 20px;
                  border-radius: 20px;
                  top: 10;
                "
              >
                <q-card-section style="background-color: #180429">
                  <div class="folder-icon">
                    <svg
                      class="MuiSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      style="font-size: 35px"
                    >
                      <path
                        d="M20 6h-8l-1.41-1.41C10.21 4.21 9.7 4 9.17 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-1 12H5c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1z"
                      ></path>
                    </svg>

                    <div
                      class="text-h6"
                      style="
                        color: #8892b0;
                        font-size: 20px;
                        margin-bottom: 10px;
                      "
                    >
                      B&amp;C Formation
                    </div>
                    <br />
                  </div>
                  <div class="text-subtitle2">
                    Création de poste sur les réseaux sociaux et de newsletter
                  </div>
                  <div class="text-grey">
                    Chez B&amp;C Formation, j’ai penser, créer et plannifié des
                    postes sur les réseaux sociaux et développé une
                    fonctionnalité de newsletters en codant sur le site
                    WordPress, permettant de tenir informés employés et
                    collaborateurs des actualités de l’entreprise.
                  </div>
                </q-card-section>

                <q-separator />

                <q-card-actions
                  style="justify-content: end; background-color: black"
                >
                  <span class="external-links">
                    <svg
                      class="MuiSvgIcon-root"
                      @click="carouselRSNews = true"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      style="font-size: 25px; color: #f5f5f5"
                    >
                      <path
                        d="M19 4H5c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h4v-2H5V8h14v10h-4v2h4c1.1 0 2-.9 2-2V6c0-1.1-.89-2-2-2zm-7 6l-4 4h3v6h2v-6h3l-4-4z"
                      ></path>
                    </svg>
                    <q-dialog v-model="carouselRSNews">
                      <q-carousel
                        transition-prev="slide-right"
                        transition-next="slide-left"
                        swipeable
                        animated
                        v-model="slide"
                        control-color="primary"
                        navigation
                        padding
                        max-height="100%"
                        max-width="100%"
                        class="bg-white shadow-1 rounded-borders"
                      >
                        <q-carousel-slide
                          :name="1"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/Rs.png"
                            alt="Réseaux sociaux 1"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="2"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/RsJPO.png"
                            alt="Réseaux sociaux 2"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="3"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/RsPorteouverte.png"
                            alt="Réseaux sociaux 3"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="4"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/RsRejoindre.png"
                            alt="Réseaux sociaux 4"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="5"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/RsSalon.png"
                            alt="Réseaux sociaux 5"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="6"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/News1.png"
                            alt="Newsletter page 1"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="7"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/News2.png"
                            alt="Newsletter Page 2"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="8"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/News3.png"
                            alt="Newsletter Page 3"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="9"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/News4.png"
                            alt="Newsletter Page 4"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="10"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/News5.png"
                            alt="Newsletter Page 5"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                      </q-carousel>
                    </q-dialog>
                  </span>
                </q-card-actions>
              </q-card>

              <q-card
                style="
                  background-color: #f5f5f5;
                  margin: 20px;
                  border-radius: 20px;
                  top: 10;
                "
              >
                <q-card-section style="background-color: #180429">
                  <div class="folder-icon">
                    <svg
                      class="MuiSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      style="font-size: 35px"
                    >
                      <path
                        d="M20 6h-8l-1.41-1.41C10.21 4.21 9.7 4 9.17 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-1 12H5c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1z"
                      ></path>
                    </svg>
                    <div
                      class="text-h6"
                      style="
                        color: #8892b0;
                        font-size: 20px;
                        margin-bottom: 10px;
                      "
                    >
                      La Fabrique de Com
                    </div>
                    <br />
                  </div>
                  <div class="text-subtitle2">
                    Aide à la conception du site Local et Bocal
                  </div>
                  <div class="text-grey">
                    J’ai collaboré avec une graphiste et un illustrateur pour
                    enrichir le site de Local et Bocal, l’un de nos clients.
                    J’ai également codé certaines fonctionnalités pour éviter
                    l’utilisation de plugins payants.
                  </div>
                </q-card-section>

                <q-separator />

                <q-card-actions
                  style="justify-content: end; background-color: black"
                >
                  <span class="external-links">
                    <a
                      class="open-icon"
                      target="_blank"
                      href="https://www.local-et-bocal.fr/"
                    >
                      <svg
                        class="MuiSvgIcon-root"
                        focusable="false"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        style="font-size: 25px; color: white"
                      >
                        <path
                          d="M19 4H5c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h4v-2H5V8h14v10h-4v2h4c1.1 0 2-.9 2-2V6c0-1.1-.89-2-2-2zm-7 6l-4 4h3v6h2v-6h3l-4-4z"
                        ></path>
                      </svg>
                    </a>
                  </span>
                </q-card-actions>
              </q-card>

              <q-card
                style="
                  background-color: #f5f5f5;
                  margin: 20px;
                  border-radius: 20px;
                  top: 10;
                "
              >
                <q-card-section style="background-color: #180429">
                  <div class="folder-icon">
                    <svg
                      class="MuiSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      style="font-size: 35px"
                    >
                      <path
                        d="M20 6h-8l-1.41-1.41C10.21 4.21 9.7 4 9.17 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-1 12H5c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1z"
                      ></path>
                    </svg>
                    <div
                      class="text-h6"
                      style="
                        color: #8892b0;
                        font-size: 20px;
                        margin-bottom: 10px;
                      "
                    >
                      La Fabrique de Com
                    </div>
                    <br />
                  </div>
                  <div class="text-subtitle2">
                    Aide à la conception du site Envie
                  </div>
                  <div class="text-grey">
                    J’ai collaboré avec une graphiste et un illustrateur pour
                    enrichir le site d'Envie, l’un de nos clients. J’ai
                    également développé une barre de recherche permettant de
                    trouver des téléphones dans le catalogue.
                  </div>
                </q-card-section>

                <q-separator />

                <q-card-actions
                  style="justify-content: end; background-color: black"
                >
                  <span class="external-links">
                    <a
                      class="open-icon"
                      target="_blank"
                      href="https://www.envie.org/"
                    >
                      <svg
                        class="MuiSvgIcon-root"
                        focusable="false"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        style="font-size: 25px; color: white"
                      >
                        <path
                          d="M19 4H5c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h4v-2H5V8h14v10h-4v2h4c1.1 0 2-.9 2-2V6c0-1.1-.89-2-2-2zm-7 6l-4 4h3v6h2v-6h3l-4-4z"
                        ></path>
                      </svg>
                    </a>
                  </span>
                </q-card-actions>
              </q-card>

              <q-card
                style="
                  background-color: #f5f5f5;
                  margin: 20px;
                  border-radius: 20px;
                  top: 10;
                "
              >
                <q-card-section style="background-color: #180429">
                  <div class="folder-icon">
                    <svg
                      class="MuiSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      style="font-size: 35px"
                    >
                      <path
                        d="M20 6h-8l-1.41-1.41C10.21 4.21 9.7 4 9.17 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-1 12H5c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1z"
                      ></path>
                    </svg>
                    <div
                      class="text-h6"
                      style="
                        color: #8892b0;
                        font-size: 20px;
                        margin-bottom: 10px;
                      "
                    >
                      Cood
                    </div>
                    <br />
                  </div>
                  <div class="text-subtitle2">Création d'univers de jeux</div>
                  <div class="text-grey">
                    Création d'univers de jeux en collaboration avec un
                    directeur artistique. L’objectif était d’assembler les
                    assets créés afin de concevoir un univers intégré dans le
                    Cood Studio pour les joueurs.
                  </div>
                </q-card-section>

                <q-separator />

                <q-card-actions
                  style="justify-content: end; background-color: black"
                >
                  <span class="external-links">
                    <svg
                      class="MuiSvgIcon-root"
                      @click="carousel = true"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      style="font-size: 25px; color: #f5f5f5"
                    >
                      <path
                        d="M19 4H5c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h4v-2H5V8h14v10h-4v2h4c1.1 0 2-.9 2-2V6c0-1.1-.89-2-2-2zm-7 6l-4 4h3v6h2v-6h3l-4-4z"
                      ></path>
                    </svg>
                    <q-dialog v-model="carousel">
                      <q-carousel
                        transition-prev="slide-right"
                        transition-next="slide-left"
                        swipeable
                        animated
                        v-model="slide"
                        control-color="primary"
                        navigation
                        padding
                        height="500px"
                        max-width="100%"
                        class="bg-white shadow-1 rounded-borders"
                      >
                        <q-carousel-slide
                          :name="1"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/extra-terrestre-int.png"
                            alt="extra-terrestre-int"
                            style="width: 100%; height: 50%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="2"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/Forêt-ext.png"
                            alt="Forêt-ext"
                            style="width: 100%; height: 50%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="3"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/Foret-int.png"
                            alt="Foret-int"
                            style="width: 100%; height: 50%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="4"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/monde-merveilleur-exterieure.png"
                            alt="monde-merveilleur-exterieure"
                            style="width: 100%; height: 50%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="5"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/monde-merveilleurx-int.png"
                            alt="monde-merveilleur-int"
                            style="width: 100%; height: 50%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="6"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/monde-montagne-int.png"
                            alt="monde-montagne-int"
                            style="width: 100%; height: 50%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="7"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/montagne-ext.png"
                            alt="montagne-ext"
                            style="width: 100%; height: 50%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="8"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/extra-terrestre-ext.png"
                            alt="extra-terrestre-ext"
                            style="width: 100%; height: 50%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="9"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/ville-ext.png"
                            alt="ville-ext"
                            style="width: 100%; height: 50%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="10"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/Ville-int.png"
                            alt="Ville-int"
                            style="width: 100%; height: 50%"
                          />
                        </q-carousel-slide>
                      </q-carousel>
                    </q-dialog>
                  </span>
                </q-card-actions>
              </q-card>

              <q-card
                style="
                  background-color: #f5f5f5;
                  margin: 20px;
                  border-radius: 20px;
                  top: 10;
                "
              >
                <q-card-section style="background-color: #180429">
                  <div class="folder-icon">
                    <svg
                      class="MuiSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      style="font-size: 35px"
                    >
                      <path
                        d="M20 6h-8l-1.41-1.41C10.21 4.21 9.7 4 9.17 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-1 12H5c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1z"
                      ></path>
                    </svg>
                    <div
                      class="text-h6"
                      style="
                        color: #8892b0;
                        font-size: 20px;
                        margin-bottom: 10px;
                      "
                    >
                      Cood
                    </div>
                    <br />
                  </div>
                  <div class="text-subtitle2">Refonte du site Cood</div>
                  <div class="text-grey">
                    Refonte du site officiel : Clarification des objectifs;
                    Cadrage du projet; Planification de l'arborescence;
                    Référentiel des exigences fontionnelles; Référencement SEO;
                    Wireframming/ Maquettes; Création de contenu; Ui Design;
                    Méthode agile.
                  </div>
                </q-card-section>

                <q-separator />

                <q-card-actions
                  style="justify-content: end; background-color: black"
                >
                  <span class="external-links">
                    <svg
                      class="MuiSvgIcon-root"
                      @click="carouselCood = true"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      style="font-size: 25px; color: #f5f5f5"
                    >
                      <path
                        d="M19 4H5c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h4v-2H5V8h14v10h-4v2h4c1.1 0 2-.9 2-2V6c0-1.1-.89-2-2-2zm-7 6l-4 4h3v6h2v-6h3l-4-4z"
                      ></path>
                    </svg>
                    <q-dialog v-model="carouselCood">
                      <q-carousel
                        transition-prev="slide-right"
                        transition-next="slide-left"
                        swipeable
                        animated
                        v-model="slide"
                        control-color="primary"
                        navigation
                        padding
                        height="300px"
                        max-width="100%"
                        class="bg-white shadow-1 rounded-borders"
                      >
                        <q-carousel-slide
                          :name="1"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/RefonteCood.png"
                            alt="Refonte avane"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="2"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/RefonteApres.png"
                            alt="Refonte du site apres"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="3"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/RefonteAccueil.png"
                            alt="Refonte du site quelques example"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="4"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/RefonteCood2.png"
                            alt="Refonte du site quelques example"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="5"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/RefonteCharte.png"
                            alt="Charte graphique du site"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                      </q-carousel>
                    </q-dialog>
                  </span>
                </q-card-actions>
              </q-card>

              <q-card
                style="
                  background-color: #f5f5f5;
                  margin: 20px;
                  border-radius: 20px;
                  top: 10;
                "
              >
                <q-card-section style="background-color: #180429">
                  <div class="folder-icon">
                    <svg
                      class="MuiSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      style="font-size: 35px"
                    >
                      <path
                        d="M20 6h-8l-1.41-1.41C10.21 4.21 9.7 4 9.17 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-1 12H5c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1z"
                      ></path>
                    </svg>
                    <div
                      class="text-h6"
                      style="
                        color: #8892b0;
                        font-size: 20px;
                        margin-bottom: 10px;
                      "
                    >
                      Cood
                    </div>
                    <br />
                  </div>
                  <div class="text-subtitle2">Community Management</div>
                  <div class="text-grey">
                    Communication hebdomadaire : Création de plusieurs mini-jeux
                    pour les réseaux sociaux, comme le jeu des 7 erreurs, afin
                    d'amuser nos joueurs. Ces posts étaient publiés
                    exclusivement le samedi et dimanche.
                  </div>
                </q-card-section>

                <q-separator />

                <q-card-actions
                  style="justify-content: end; background-color: black"
                >
                  <span class="external-links">
                    <svg
                      class="MuiSvgIcon-root"
                      @click="carouselRsCood = true"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      style="font-size: 25px; color: #f5f5f5"
                    >
                      <path
                        d="M19 4H5c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h4v-2H5V8h14v10h-4v2h4c1.1 0 2-.9 2-2V6c0-1.1-.89-2-2-2zm-7 6l-4 4h3v6h2v-6h3l-4-4z"
                      ></path>
                    </svg>
                    <q-dialog v-model="carouselRsCood">
                      <q-carousel
                        transition-prev="slide-right"
                        transition-next="slide-left"
                        swipeable
                        animated
                        v-model="slide"
                        control-color="primary"
                        navigation
                        padding
                        height="300px"
                        max-width="100%"
                        class="bg-white shadow-1 rounded-borders"
                      >
                        <q-carousel-slide
                          :name="1"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/RsCood.png"
                            alt="Reseaux sociaux Cood"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="2"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/RsCood1.png"
                            alt="Reseaux sociaux Cood"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                      </q-carousel>
                    </q-dialog>
                  </span>
                </q-card-actions>
              </q-card>

              <q-card
                style="
                  background-color: #f5f5f5;
                  margin: 20px;
                  border-radius: 20px;
                  top: 10;
                "
              >
                <q-card-section style="background-color: #180429">
                  <div class="folder-icon">
                    <svg
                      class="MuiSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      style="font-size: 35px"
                    >
                      <path
                        d="M20 6h-8l-1.41-1.41C10.21 4.21 9.7 4 9.17 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-1 12H5c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1z"
                      ></path>
                    </svg>
                    <div
                      class="text-h6"
                      style="
                        color: #8892b0;
                        font-size: 20px;
                        margin-bottom: 10px;
                      "
                    >
                      Wild Code School
                    </div>
                    <br />
                  </div>
                  <div class="text-subtitle2">Projet Déliv'green</div>
                  <div class="text-grey">
                    Application mettant en relation des livreurs particuliers et
                    professionnels. Une application où l'on peut être coursier
                    et un simple client souhaitant faire livrer son colis !
                  </div>
                </q-card-section>

                <q-separator />

                <q-card-actions
                  style="justify-content: end; background-color: black"
                >
                  <span class="external-links">
                    <svg
                      class="MuiSvgIcon-root"
                      @click="carouselWCSDeliv = true"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      style="font-size: 25px; color: #f5f5f5"
                    >
                      <path
                        d="M19 4H5c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h4v-2H5V8h14v10h-4v2h4c1.1 0 2-.9 2-2V6c0-1.1-.89-2-2-2zm-7 6l-4 4h3v6h2v-6h3l-4-4z"
                      ></path>
                    </svg>
                    <q-dialog v-model="carouselWCSDeliv">
                      <q-carousel
                        transition-prev="slide-right"
                        transition-next="slide-left"
                        swipeable
                        animated
                        v-model="slide"
                        control-color="primary"
                        navigation
                        padding
                        height="300px"
                        max-width="100%"
                        class="bg-white shadow-1 rounded-borders"
                      >
                        <q-carousel-slide
                          :name="1"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/Deliv1.png"
                            alt="Delivgreen site capture 1"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                        <q-carousel-slide
                          :name="2"
                          class="column no-wrap flex-center"
                        >
                          <img
                            src="../assets/Deliv2.png"
                            alt="Delivgreen site capture 2"
                            style="width: 100%; height: 100%"
                          />
                        </q-carousel-slide>
                      </q-carousel>
                    </q-dialog>
                  </span>
                </q-card-actions>
              </q-card>

              <q-card
                style="
                  background-color: #f5f5f5;
                  margin: 20px;
                  border-radius: 20px;
                  top: 10;
                "
              >
                <q-card-section style="background-color: #180429">
                  <div class="folder-icon">
                    <svg
                      class="MuiSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      style="font-size: 35px"
                    >
                      <path
                        d="M20 6h-8l-1.41-1.41C10.21 4.21 9.7 4 9.17 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-1 12H5c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1z"
                      ></path>
                    </svg>
                    <div
                      class="text-h6"
                      style="
                        color: #8892b0;
                        font-size: 20px;
                        margin-bottom: 10px;
                      "
                    >
                      Wild Code School
                    </div>
                    <br />
                  </div>
                  <div class="text-subtitle2">Projet PokéBattle</div>
                  <div class="text-grey">
                    Petit jeu de combat dans le thème "Pokemon". Le jeu met en
                    scène un combat avec un personnage choisi aléatoirement.
                  </div>
                </q-card-section>

                <q-separator />

                <q-card-actions
                  style="justify-content: end; background-color: black"
                >
                  <span class="external-links">
                    <a
                      class="open-icon"
                      target="_blank"
                      href="https://pokemon-battle.netlify.app/"
                    >
                      <svg
                        class="MuiSvgIcon-root"
                        focusable="false"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        style="font-size: 25px; color: white"
                      >
                        <path
                          d="M19 4H5c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h4v-2H5V8h14v10h-4v2h4c1.1 0 2-.9 2-2V6c0-1.1-.89-2-2-2zm-7 6l-4 4h3v6h2v-6h3l-4-4z"
                        ></path>
                      </svg>
                    </a>
                  </span>
                </q-card-actions>
              </q-card>

              <q-card
                style="
                  background-color: #f5f5f5;
                  margin: 20px;
                  border-radius: 20px;
                  top: 10;
                "
              >
                <q-card-section style="background-color: #180429">
                  <div class="folder-icon">
                    <svg
                      class="MuiSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      style="font-size: 35px"
                    >
                      <path
                        d="M20 6h-8l-1.41-1.41C10.21 4.21 9.7 4 9.17 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-1 12H5c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1z"
                      ></path>
                    </svg>
                    <div
                      class="text-h6"
                      style="
                        color: #8892b0;
                        font-size: 20px;
                        margin-bottom: 10px;
                      "
                    >
                      E-Commerce en autonomie
                    </div>
                    <br />
                  </div>

                  <div class="text-grey">
                    Projet e-commerce de tapisgrimaud.com et trousselite.com.<br /><br />
                    Les sites offrent également une expérience de navigation
                    fluide, une sécurité de paiement optimale et une livraison
                    rapide et fiable.
                  </div>
                </q-card-section>

                <q-separator />

                <q-card-actions
                  style="justify-content: end; background-color: black"
                >
                  <span class="external-links">
                    <a class="open-icon" href="https://tapisgrimaud.com">
                      <svg
                        class="MuiSvgIcon-root"
                        focusable="false"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        style="font-size: 25px; color: #f5f5f5"
                      >
                        <path
                          d="M19 4H5c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h4v-2H5V8h14v10h-4v2h4c1.1 0 2-.9 2-2V6c0-1.1-.89-2-2-2zm-7 6l-4 4h3v6h2v-6h3l-4-4z"
                        ></path>
                      </svg>
                    </a>
                  </span>
                </q-card-actions>
              </q-card>

              <q-card-actions align="right" style="background-color: #454b69">
                <q-btn flat label="OK" style="color: #40cfef" v-close-popup />
              </q-card-actions>
            </q-card>
          </q-dialog>
        </li>

        <li class="flex-item">
          <img
            src="../assets/expp.png"
            alt="expériences"
            style="
              width: 100%;
              margin: 2px auto;
              border-radius: 20%;
              display: flex;
              background-color: #3b3c64;
              cursor: pointer;
            "
            @click="cardExperience = true"
          />
          <p style="font-size: 10px">Expériences</p>

          <q-dialog v-model="cardExperience">
            <q-card
              style="
                min-width: 80%;
                background-color: #180429;
                padding: 20px;
                display: flex;
                justify-content: center;
              "
              class="my-card"
            >
              <q-btn
                outline
                label="Expérience dans le domaine du web"
                style="background-color: #180429; color: #40cfef"
                @click="alertExperienceDansLeDomaine = true"
              />

              <q-btn
                outline
                label="Expérience en dehors du web"
                style="background-color: #180429; color: #40cfef"
                @click="alertExperienceEnDehors = true"
              />

              <q-dialog v-model="alertExperienceDansLeDomaine">
                <q-card style="padding: 10px; background-color: black">
                  <div>
                    <div
                      class="text-h6"
                      style="
                        color: #8892b0;
                        font-size: 20px;
                        margin-bottom: 5px;
                        margin-top: 30px;
                      "
                    >
                      Expériences dans le Domaine du Web :
                    </div>
                    <q-expansion-item
                      expand-separator
                      label="B&amp;C Formation"
                      style="
                        border: 2px solid;
                        flex-wrap: wrap;
                        background-color: black;
                        color: #40cfef;
                        font-size: 14px;
                      "
                    >
                      <q-card>
                        <q-card-section>
                          <div class="title-panel-parcours">
                            BAC+3 Cheffe de Projet Digital alternance
                          </div>
                          <div
                            class="date-experience"
                            style="
                              color: #777;
                              font-size: 15px;
                              font-weight: 600;
                            "
                          >
                            2024 - 2023
                          </div>
                          <div class="text-panel-parcours">
                            Enseignement supérieure à l'EEMI avec l'entreprise
                            B&amp;C Formation.
                          </div>
                          <div class="mission-panel-parcours">
                            ➤ Refonte et optimisation du site web<br />
                            ➤ Community Management : Gestion de la communication
                            sur les réseaux sociaux<br />
                            ➤ Création de newsletters internes et externes<br />
                            ➤ Conception de kits de communication pour des
                            salons étudiants<br />
                            ➤ Réalisation de visuels tels que flyers et
                            affiches<br />
                            ➤ Collaboration à la conception d'un jeu sur la
                            sécurité avec un formateur<br />
                            ➤ Piloter un projet <br />
                            ➤ Démarchage téléphonique <br />
                          </div>
                        </q-card-section>
                      </q-card>
                    </q-expansion-item>

                    <q-expansion-item
                      expand-separator
                      label="La Fabrique de Com"
                      style="
                        border: 2px solid;
                        flex-wrap: wrap;
                        background-color: black;
                        color: #40cfef;
                        font-size: 14px;
                      "
                    >
                      <q-card>
                        <q-card-section>
                          <div class="title-panel-parcours">
                            | BAC +3 | Conceptrice Développeuse d’Applications
                            alternance
                          </div>
                          <div class="text-panel-parcours">
                            Chez 2iTech avec l'entreprise la Fabrique de Com
                            d'Orléans.
                          </div>
                          <div class="mission-panel-parcours">
                            ➤ Responsabilité de la gestion et des suivi des
                            sites web au quotidien.<br />
                            ➤ Conception de site web et mise à jour des sites
                            existants.<br />
                            ➤ Intégration web et programmation web.<br />
                            ➤ Veille technologique<br />
                          </div>
                        </q-card-section>
                      </q-card>
                    </q-expansion-item>

                    <q-expansion-item
                      expand-separator
                      label="Cood"
                      style="
                        border: 2px solid;
                        flex-wrap: wrap;
                        background-color: black;
                        color: #40cfef;
                        font-size: 14px;
                      "
                    >
                      <q-card>
                        <q-card-section>
                          <div class="title-panel-parcours">
                            BAC+2 BTS SIO alternance
                          </div>
                          <div class="text-panel-parcours">
                            Centre de formation à l'Aftec avec l'entreprise Cood
                            du Lab'O d'Orléans.
                          </div>
                          <div class="mission-panel-parcours">
                            ➤ Refonte visuelle et structurelle du site officiel
                            de Cood.<br />
                            ➤ Création de maquette.<br />
                            ➤ Création d'une documentation interactive | quasar-
                            vue.js<br />
                            ➤ Refonte de certains jeux et tutos avec la
                            technologie blockly<br />
                            ➤ Création d'illustration d'univers de jeu à travers
                            des assets existants.<br />
                            ➤ Communication hebdomadaires.<br />
                          </div>
                        </q-card-section>
                      </q-card>
                    </q-expansion-item>

                    <q-expansion-item
                      expand-separator
                      label="Wild Code School"
                      style="
                        border: 2px solid;
                        flex-wrap: wrap;
                        background-color: black;
                        color: #40cfef;
                        font-size: 14px;
                      "
                    >
                      <q-card>
                        <q-card-section>
                          <div class="title-panel-parcours">
                            Titre professionnel Développeuse web et web mobile
                          </div>
                          <div class="text-panel-parcours">
                            Formation à la Wild Code School spécialisation en
                            JavaScript
                          </div>
                          <div class="mission-panel-parcours">
                            ➤ Etude sur les langages de programmation HTML/CSS,
                            Python, JavaScript.<br />
                            ➤ Design: UX UI<br />
                            ➤ Découverte et initiation de logiciels : MySql,
                            PhpMyAdmin, VSC Visual Studio Code, Github,
                            Gitlab<br />
                            ➤ Méthode agile : SCRUM<br />
                            ➤ Relation Client pour projet<br />
                            ➤ Participation à des hackathons.<br />
                          </div>
                        </q-card-section>
                      </q-card>
                    </q-expansion-item>

                    <q-expansion-item
                      expand-separator
                      label="Alter Ego Digital"
                      style="
                        border: 2px solid;
                        flex-wrap: wrap;
                        background-color: black;
                        color: #40cfef;
                        font-size: 14px;
                      "
                    >
                      <q-card>
                        <q-card-section>
                          <div class="title-panel-parcours">
                            Stage professionnel
                          </div>
                          <div class="text-panel-parcours">
                            Développement front-end
                          </div>
                          <div class="mission-panel-parcours">
                            ➤ Découverte de la Refonte à travers le code.
                          </div>
                        </q-card-section>
                      </q-card>
                    </q-expansion-item>

                    <q-expansion-item
                      expand-separator
                      label="Kréa'Lab"
                      style="
                        border: 2px solid;
                        flex-wrap: wrap;
                        background-color: black;
                        color: #40cfef;
                        font-size: 14px;
                      "
                    >
                      <q-card>
                        <q-card-section>
                          <div class="title-panel-parcours">
                            Stage professionnel
                          </div>
                          <div class="text-panel-parcours">
                            Développement front-end
                          </div>
                          <div class="mission-panel-parcours">
                            ➤ Découverte du CMS Wordpress.
                          </div>
                        </q-card-section>
                      </q-card>
                    </q-expansion-item>
                  </div>
                  <q-card-actions align="right" style="background-color: black">
                    <q-btn
                      flat
                      label="OK"
                      style="color: #40cfef"
                      v-close-popup
                    />
                  </q-card-actions>
                </q-card>
              </q-dialog>

              <q-dialog v-model="alertExperienceEnDehors">
                <q-card style="padding: 10px; background-color: black">
                  <div>
                    <div
                      class="text-h6"
                      style="
                        color: #8892b0;
                        font-size: 20px;
                        margin-bottom: 5px;
                        margin-top: 30px;
                      "
                    >
                      Expériences Hors du Web :
                    </div>
                    <q-expansion-item
                      expand-separator
                      label="Auréa AgroSciences"
                      style="
                        border: 2px solid;
                        flex-wrap: wrap;
                        background-color: black;
                        color: #40cfef;
                        font-size: 14px;
                      "
                    >
                      <q-card>
                        <q-card-section>
                          <div class="title-panel-parcours">
                            Assistante de laboratoire
                          </div>
                          <div
                            class="date-experience"
                            style="
                              color: #777;
                              font-size: 15px;
                              font-weight: 600;
                            "
                          >
                            2020
                          </div>
                          <div class="text-panel-parcours">
                            Ardon, Centre Val de Loire
                          </div>
                          <div class="mission-panel-parcours">
                            ➤ Préparer et réaliser des analyses techniques,
                            scientifiques et/ou des activités expérimentales
                            dans le cadre de procédures définies.<br />
                            ➤ Appliquer les règles d’hygiène, de santé, de
                            sécurité et de conditions de travail<br />
                            ➤ Exploiter des résultats d’analyses<br />
                            ➤ Exécuter des tâches de gestion courante telles que
                            gestion des stocks, gestion des déchets<br />
                            ➤ Maintenir et entretenir des matériels<br />
                            ➤ Préparer et / ou réaliser des analyses techniques
                            ou scientifiques<br />
                            ➤ Préparer et exécuter des protocoles prévus <br />
                          </div>
                        </q-card-section>
                      </q-card>
                    </q-expansion-item>

                    <q-expansion-item
                      expand-separator
                      label="CTLOG International"
                      style="
                        border: 2px solid;
                        flex-wrap: wrap;
                        background-color: black;
                        color: #40cfef;
                        font-size: 14px;
                      "
                    >
                      <q-card>
                        <q-card-section>
                          <div class="title-panel-parcours">
                            Préparatrice de commande
                          </div>
                          <div
                            class="date-experience"
                            style="
                              color: #777;
                              font-size: 15px;
                              font-weight: 600;
                            "
                          >
                            2021
                          </div>
                          <div class="text-panel-parcours">
                            Meung-sur-Loire, Centre Val de Loire
                          </div>
                          <div class="mission-panel-parcours">
                            ➤ Réceptionner et stocker les marchandises<br />
                            ➤ Emballer et expédier les commandes clients<br />
                            ➤ Réceptionner les marchandises<br />
                            ➤ Vérifier la conformité des commandes reçues<br />
                            ➤ Création d'illustration d'univers de jeu à travers
                            des assets existants.<br />
                            ➤ Communication hebdomadaires.<br />
                          </div>
                        </q-card-section>
                      </q-card>
                    </q-expansion-item>

                    <q-expansion-item
                      expand-separator
                      label="Amazon Ory1"
                      style="
                        border: 2px solid;
                        flex-wrap: wrap;
                        background-color: black;
                        color: #40cfef;
                        font-size: 14px;
                      "
                    >
                      <q-card>
                        <q-card-section>
                          <div class="title-panel-parcours">
                            Préparatrice de commande <br />stow &amp; picking
                          </div>
                          <div
                            class="date-experience"
                            style="
                              color: #777;
                              font-size: 15px;
                              font-weight: 600;
                            "
                          >
                            2020
                          </div>
                          <div class="text-panel-parcours">
                            Saran, Centre Val de Loire
                          </div>
                          <div class="mission-panel-parcours">
                            ➤ Réceptionner et stocker les marchandises<br />
                            ➤ Assurer le flux de colis sur le site en utilisant
                            les moyens mis à disposition<br />
                          </div>
                        </q-card-section>
                      </q-card>
                    </q-expansion-item>

                    <q-expansion-item
                      expand-separator
                      label="TOUTENET"
                      style="
                        border: 2px solid;
                        flex-wrap: wrap;
                        background-color: black;
                        color: #40cfef;
                        font-size: 14px;
                      "
                    >
                      <q-card>
                        <q-card-section>
                          <div class="title-panel-parcours">
                            Technicienne de Surface
                          </div>
                          <div class="text-panel-parcours">
                            Saint-Jean-de-la-Ruelle, Centre Val de Loire
                          </div>
                          <div class="mission-panel-parcours">
                            Au sein de sociétés de services de propreté, de
                            collectivités territoriales, d'associations en
                            contact avec différents intervenants (clients,
                            usagers, agents ...).<br />
                            CDD durant les périodes de vacances scolaires.<br />
                            CDI durant le week-end.<br />
                          </div>
                        </q-card-section>
                      </q-card>
                    </q-expansion-item>

                    <q-expansion-item
                      expand-separator
                      label="SAMSIC"
                      style="
                        border: 2px solid;
                        flex-wrap: wrap;
                        background-color: black;
                        color: #40cfef;
                        font-size: 14px;
                      "
                    >
                      <q-card>
                        <q-card-section>
                          <div class="title-panel-parcours">
                            Technicienne de Surface
                          </div>
                          <div class="text-panel-parcours">
                            Saint-Jean-de-la-Ruelle, Centre Val de Loire
                          </div>
                        </q-card-section>
                      </q-card>
                    </q-expansion-item>

                    <q-expansion-item
                      expand-separator
                      label="PRO-IMPEC"
                      style="
                        border: 2px solid;
                        flex-wrap: wrap;
                        background-color: black;
                        color: #40cfef;
                        font-size: 14px;
                      "
                    >
                      <q-card>
                        <q-card-section>
                          <div class="title-panel-parcours">
                            Technicienne de Surface
                          </div>
                          <div class="text-panel-parcours">
                            Orléans, Centre Val de Loire
                          </div>
                        </q-card-section>
                      </q-card>
                    </q-expansion-item>

                    <q-expansion-item
                      expand-separator
                      label="PRO-NET SARL"
                      style="
                        border: 2px solid;
                        flex-wrap: wrap;
                        background-color: black;
                        color: #40cfef;
                        font-size: 14px;
                      "
                    >
                      <q-card>
                        <q-card-section>
                          <div class="title-panel-parcours">
                            Technicienne de Surface
                          </div>
                          <div class="text-panel-parcours">
                            Orléans, Centre Val de Loire
                          </div>
                        </q-card-section>
                      </q-card>
                    </q-expansion-item>

                    <q-expansion-item
                      expand-separator
                      label="INETEX CENTRE VAL DE LOIRE"
                      style="
                        border: 2px solid;
                        flex-wrap: wrap;
                        background-color: black;
                        color: #40cfef;
                        font-size: 14px;
                      "
                    >
                      <q-card>
                        <q-card-section>
                          <div class="title-panel-parcours">
                            Technicienne de Surface
                          </div>
                          <div class="text-panel-parcours">
                            Orléans, Centre Val de Loire
                          </div>
                        </q-card-section>
                      </q-card>
                    </q-expansion-item>
                  </div>

                  <q-card-actions align="right" style="background-color: black">
                    <q-btn
                      flat
                      label="OK"
                      style="color: #40cfef"
                      v-close-popup
                    />
                  </q-card-actions>
                </q-card>
              </q-dialog>
            </q-card>
          </q-dialog>
        </li>

        <li class="flex-item">
          <img
            src="../assets/langues.png"
            alt="logoLang"
            style="
              width: 100%;
              margin: 2px auto;
              border-radius: 20%;
              display: flex;
              background-color: #3b3c64;
              cursor: pointer;
            "
            @click="cardLang = true"
          />
          <p style="font-size: 10px">Langues</p>
          <q-dialog v-model="cardLang">
            <q-card
              style="width: 700px; max-width: 80vw; background-color: #180429"
            >
              <q-card-section>
                <div class="text-h6">Langues</div>
              </q-card-section>

              <q-card-section class="q-pt-none">
                <div
                  style="
                    font-family: 'made_tommy', sans-serif;
                    color: #8892b0;
                    font-weight: 400;
                  "
                  class="rating"
                >
                  Anglais <q-space></q-space> Niveau C1
                </div>

                <div
                  style="
                    font-family: 'made_tommy', sans-serif;
                    color: #8892b0;
                    font-weight: 400;
                  "
                  class="rating"
                >
                  Espagnol <q-space></q-space> Niveau A2
                </div>

                <div
                  style="
                    font-family: 'made_tommy', sans-serif;
                    color: #8892b0;
                    font-weight: 400;
                  "
                  class="rating"
                >
                  Turque <q-space></q-space> Niveau C1
                </div>
              </q-card-section>

              <q-card-actions align="right" style="background-color: #454b69">
                <q-btn flat label="OK" style="color: #40cfef" v-close-popup />
              </q-card-actions>
            </q-card>
          </q-dialog>
        </li>

        <li class="flex-item">
          <img
            src="../assets/hobby.png"
            alt="Hobby"
            style="
              width: 100%;
              margin: 2px auto;
              border-radius: 20%;
              display: flex;
              background-color: #3b3c64;
              cursor: pointer;
            "
            @click="cardHobby = true"
          />
          <p style="font-size: 10px">Hobbys</p>

          <q-dialog v-model="cardHobby">
            <div
              class="black-bg"
              style="
                background-color: black;
                width: 600px;
                padding: 2px;
                border-radius: 20px;
              "
            >
              <div class="Hobby-pocket">
                <q-card
                  class="my-card"
                  style="background-color: #180429; color: #f5f5f5"
                >
                  <q-item>
                    <q-item-section avatar>
                      <q-avatar>
                        <img src="../assets/tapis-roulant.png" />
                      </q-avatar>
                    </q-item-section>

                    <q-item-section>
                      <q-item-label>Activités physiques</q-item-label>
                      <q-item-label caption style="color: #8892b0"
                        >Séances d'entraînement : fitness et sport de combat
                        (boxe thaï et Taekwondo)</q-item-label
                      >
                    </q-item-section>
                  </q-item>
                </q-card>

                <q-separator />

                <q-card
                  class="my-card"
                  style="background-color: #180429; color: #f5f5f5"
                >
                  <q-item>
                    <q-item-section avatar>
                      <q-avatar>
                        <img src="../assets/pop-corn.png" />
                      </q-avatar>
                    </q-item-section>

                    <q-item-section>
                      <q-item-label>Cinéma</q-item-label>
                      <q-item-label caption style="color: #8892b0"
                        >Petit penchant pour l'univers des animés, des k-dramas,
                        j-dramas...</q-item-label
                      >
                    </q-item-section>
                  </q-item>
                </q-card>
                <q-separator />

                <q-card
                  class="my-card"
                  style="background-color: #180429; color: #f5f5f5"
                >
                  <q-item>
                    <q-item-section avatar>
                      <q-avatar>
                        <img src="../assets/livres.png" />
                      </q-avatar>
                    </q-item-section>

                    <q-item-section>
                      <q-item-label>Lire</q-item-label>
                      <q-item-label caption style="color: #8892b0"
                        >Toutes sortes de bouquins</q-item-label
                      >
                    </q-item-section>
                  </q-item>
                </q-card>

                <q-separator />

                <q-card
                  class="my-card"
                  style="background-color: #180429; color: #f5f5f5"
                >
                  <q-item>
                    <q-item-section avatar>
                      <q-avatar>
                        <img src="../assets/cupcake.png" />
                      </q-avatar>
                    </q-item-section>

                    <q-item-section>
                      <q-item-label>Cuisiner</q-item-label>
                      <q-item-label caption style="color: #8892b0"
                        >L'art de la pâtisserie</q-item-label
                      >
                    </q-item-section>
                  </q-item>
                </q-card>
                <q-separator />

                <!-- <q-card class="my-card" style="background-color: #180429; color:#f5f5f5;transition-delay: 500ms">
                              <q-item>
                                <q-item-section avatar>
                                  <q-avatar>
                                    <img src="../assets/boite-a-outils.png">
                                  </q-avatar>
                                </q-item-section>

                                <q-item-section>
                                  <q-item-label>Bricoler</q-item-label>
                                  <q-item-label caption style="color: #8892b0;">Passion pour l'assemblage et la construction en suivant des plans détaillés</q-item-label>
                                </q-item-section>
                              </q-item>
                            </q-card> -->
              </div>
            </div>
          </q-dialog>
        </li>

        <li class="flex-item">
          <img
            src="../assets/car.png"
            alt="Car"
            style="
              width: 100%;
              margin: 2px auto;
              border-radius: 20%;
              display: flex;
              background-color: #3b3c64;
              cursor: pointer;
            "
            @click="openCar('top')"
          />
          <p style="font-size: 10px">Véhiculé</p>

          <q-dialog v-model="dialogCar" :position="positionCar">
            <q-card style="width: 200px; background-color: #180429">
              <q-card-section class="row items-center no-wrap">
                <div>
                  <div
                    style="
                      font-size: 1.5em;
                      font-family: 'made_tommy', sans-serif;
                      color: #40cfef;
                    "
                    class="text-weight-bold"
                  >
                    Permis B
                  </div>
                  <div style="font-size: 1.5em" class="text-grey">Véhiculé</div>
                </div>
              </q-card-section>
            </q-card>
          </q-dialog>
        </li>

        <li class="flex-item">
          <img
            src="../assets/dis.png"
            alt="Car"
            style="
              width: 100%;
              margin: 2px auto;
              border-radius: 20%;
              display: flex;
              background-color: #3b3c64;
              cursor: pointer;
            "
            @click="openDis('bottom')"
          />
          <p style="font-size: 10px">Discord</p>

          <q-dialog v-model="dialogDis" :position="positionDis">
            <q-card style="width: 200px; background-color: #180429">
              <q-card-section class="row items-center no-wrap">
                <div>
                  <div
                    style="
                      font-size: 1.5em;
                      font-family: 'made_tommy', sans-serif;
                      color: #40cfef;
                    "
                    class="text-weight-bold"
                  >
                    Sibzzkzz
                  </div>
                  <div style="font-size: 1.5em" class="text-grey">Discord</div>
                </div>
              </q-card-section>
            </q-card>
          </q-dialog>
        </li>

        <li class="flex-item">
          <img
            src="../assets/git.png"
            alt="logoGithub"
            style="
              width: 100%;
              margin: 2px auto;
              border-radius: 20%;
              display: flex;
              background-color: #3b3c64;
              cursor: pointer;
            "
            @click="open1('right')"
          />
          <p style="font-size: 10px">Github</p>

          <q-dialog v-model="dialog1" :position="positionGithub">
            <q-card style="width: 200px; background-color: #180429">
              <q-card-section class="row items-center no-wrap">
                <div>
                  <div
                    style="
                      font-size: 1.5em;
                      font-family: 'made_tommy', sans-serif;
                      color: #40cfef;
                    "
                    class="text-weight-bold"
                  >
                    /sibelkoc
                  </div>
                  <div class="text-grey">Github</div>
                </div>
              </q-card-section>
            </q-card>
          </q-dialog>
        </li>

        <li class="flex-item">
          <img
            src="../assets/linkedin.png"
            alt="logoLinkedin"
            style="
              width: 100%;
              margin: 2px auto;
              border-radius: 20%;
              display: flex;
              background-color: #3b3c64;
              cursor: pointer;
            "
            @click="card = true"
          />
          <p style="font-size: 10px">LinkedIn</p>

          <q-dialog v-model="card">
            <q-card
              style="min-width: 80%; background-color: #8892b0"
              class="my-card"
            >
              <q-img
                src="../assets/linkedin_Banner.png"
                style="
                  width: 100%;
                  align-content: center;
                  justify-content: center;
                "
              />
              <q-card-section>
                <div style="font-size: 18px; font-weight: 600" class="col">
                  Cheffe de Projet Digital et Webdesigner <br />
                  Savoir-faire en Communication Visuelle <br />
                  En quête d'opportunités pour la prochaine rentrée 🚀 <br />
                </div>
              </q-card-section>

              <q-card-section class="q-pt-none">
                <div style="font-size: 15px; color: black">
                  <b>Sujets de prédilection :</b><br />
                  #Webdesign, #E-commerce, #CommunicationDigital et
                  #MarketingDigital <br /><br />
                  Orléans, Centre-Val de Loire, France<br />
                </div>
              </q-card-section>

              <q-separator />

              <q-card-actions align="right" style="background-color: #454b69">
                <q-btn
                  push
                  label="Voir le profil"
                  style="background-color: #180429; color: #f5f5f5"
                  a
                  href="https://www.linkedin.com/in/sibel-koc"
                  target="blank"
                />

                <q-card-actions align="right" style="background-color: #454b69">
                  <q-btn
                    flat
                    label="OK"
                    style="color: #180429; background-color: #8892b0"
                    v-close-popup
                  />
                </q-card-actions>
              </q-card-actions>
            </q-card>
          </q-dialog>
        </li>

        <li class="flex-item">
          <a href="mailto:sibel.koc.pro@outlook.fr">
            <img
              src="../assets/mail.png"
              alt="logoMail"
              style="
                width: 100%;
                margin: 2px auto;
                border-radius: 20%;
                display: flex;
                background-color: #3b3c64;
                cursor: pointer;
              "
            />
          </a>
          <p style="font-size: 10px">Envoyer mail</p>
        </li>
      </ul>
    </div>

    <div class="footer-container">
      <div class="footer">
        <div>Construit et réalisé par Sibel Koç</div>
        <div>Tous droits réservés ©</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "Mobile-page",
  props: {},
  setup() {
    const dialogDiplome = ref(false);
    const positionDiplome = ref("right");
    const dialog1 = ref(false);
    const positionGithub = ref("right");
    const dialogCar = ref(false);
    const positionCar = ref("top");
    const positionDis = ref("bottom");
    const dialogDis = ref("bottom");

    return {
      small: ref(false),
      smallDiplome: ref(false),
      dialogDiplome,
      positionDiplome,
      card: ref(false),
      cardExperience: ref(false),
      cardHobby: ref(false),

      alert: ref(false),
      alertExperienceDansLeDomaine: ref(false),
      alertExperienceEnDehors: ref(false),

      dialog1,
      positionDis,
      dialogDis,
      positionGithub,
      positionCar,
      dialogCar,
      dialogMaxToggle: ref(false),
      maximizedToggle: ref(true),
      cardCompetence: ref(false),
      cardLang: ref(false),
      model: ref(null),

      ratingModelHTML: ref(5),
      ratingModelJS: ref(3),
      ratingModelPhpMyAdmin: ref(4),
      ratingModelQuasar: ref(4),
      ratingModelReact: ref(3),
      ratingModelCMS: ref(5),
      ratingModelJira: ref(5),
      ratingModelCanva: ref(5),
      ratingModelGit: ref(4),
      ratingModelFigma: ref(4),
      ratingModelSemrush: ref(2),
      ratingModelGGads: ref(2),
      ratingModelAdobes: ref(3),
      ratingModelOffice: ref(5),

      carousel: ref(false),
      carouselFlyers: ref(false),
      carouselKit: ref(false),
      carouselId: ref(false),
      carouselRSNews: ref(false),
      carouselCood: ref(false),
      carouselRsCood: ref(false),
      carouselWCSDeliv: ref(false),
      slide: ref(1),
      persistent: ref(false),

      openDiplome(pos) {
        positionDiplome.value = pos;
        dialogDiplome.value = true;
      },

      open1(pos) {
        positionGithub.value = pos;
        dialog1.value = true;
      },

      openDis(pos) {
        positionDis.value = pos;
        dialogDis.value = true;
      },

      openCar(pos) {
        positionCar.value = pos;
        dialogCar.value = true;
      },
    };
  },
};
</script>

<style lang="scss">
body,
html {
  margin: 0;
  padding: 0;
}

.text {
  color: #f5f5f5;
  font-family: "made_tommy", sans-serif;
}

/* photo */
.Me {
  margin-top: 30px;
  width: 200px;
  /* box-shadow: 5px 5px 5px 0px #c7ad9e2f; */
  border-radius: 50%;
}

/* Deux lignes de texte */

.container {
  max-width: 90% !important;
}
.container[data-v-6d9415d2] {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
}
/* bienvenue ... -> text typing */
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.typing {
  width: 25ch;
  animation: typing 6s steps(35), blink 0.2s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: -"Square Peg", cursive;
  font-size: calc(18px + 2vw);
  color: #f1e2e3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-right: 10px solid rgba(#94745b, 0.75);
  text-shadow: 0 0 2px;
  margin: 10px;
}

/* sibelKreation title */

.title {
  margin-bottom: 15px;

  white-space: nowrap;
  overflow: hidden;
  font-family: -"Square Peg", cursive;
  font-size: calc(20px + 1vw);
  color: #94745b;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-right: 10px solid rgba(#94745b, 0.75);
  text-shadow: 0 0 2px;
}

/* style square -- interface menus */

.square {
  width: 100%;
  padding: 5px;
  justify-content: center;
}

/* style box first line */

.inline-all {
  display: flex;
  justify-content: center;
  justify-content: space-around;
  margin-bottom: 10px;
}

.first-box {
  flex: 1;
  margin-right: 10px;
}

.second-box {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 5px;
  align-items: flex-start;
}

/* style box second line */

/* .reverse-all {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  justify-content: space-around;
  margin-bottom: 10px;
} */

/* .first-box-reverse {
  flex: 1;
} */

.main-wrapper {
  display: flex;
  justify-content: center; /* Aligne le contenu au centre horizontalement */
  align-items: center; /* Aligne le contenu au centre verticalement */
}
.container-wrapper {
  display: flex;
  justify-content: space-around;
  /* Ajuste cela selon tes besoins */
}

.second-box-reverse {
  margin-right: 10px;
  display: inline-flex;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 5px;
  align-items: flex-start;
  grid-template-rows: 50%;
}

/* flip card aboutMe/creation */

.medContainer {
  background-color: transparent;
  perspective: 1000px;
}

.flip-box-inner-medContainer {
  position: relative;
  min-height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  justify-content: center;
}

.medContainer:hover .flip-box-inner-medContainer {
  transform: rotateY(180deg);
}

.flip-box-front-medContainer,
.flip-box-back-medContainer {
  position: relative;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-back-medContainer {
  background-color: #c7ad9e;
  color: white;
  transform: rotateY(180deg);
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

/* card à propos  */

.text-h6 {
  font-size: 1.2em;
  font-weight: 600;
  color: #40cfef;
  font-family: "made_tommy", sans-serif;
}

.text-subtitle2 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 8px;
  color: #f5f5f5;
}

/* flip card mail/discord/In/github/skills/car */

/* YYYYYYYYYYYYYYYYYYYYYYYY */

.lilContainer {
  background-color: transparent;
  perspective: 1000px;
}

.flip-box-inner-lilContainer {
  position: relative;
  min-height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  justify-content: center;
}

.lilContainer:hover .flip-box-inner-lilContainer {
  transform: rotateY(180deg);
}

.flip-box-front-lilContainer,
.flip-box-back-lilContainer {
  position: relative;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-back-lilContainer {
  background-color: #c7ad9e;
  color: white;
  transform: rotateY(180deg);
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

/* XXXXXXXXXXXXXXXXXXXXXXXX */

.lilContainerX {
  background-color: transparent;
  perspective: 1000px;
}

.flip-box-inner-lilContainerX {
  position: relative;
  min-height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  justify-content: center;
}

.lilContainerX:hover .flip-box-inner-lilContainerX {
  transform: rotateX(180deg);
}

.flip-box-front-lilContainerX,
.flip-box-back-lilContainerX {
  position: relative;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-back-lilContainerX {
  background-color: #c7ad9e;
  color: white;
  transform: rotateX(180deg);
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.mailto {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 8px;
  color: #fff;
}
.mailto:hover {
  transform: scale(1.33);
}

.rating {
  display: inline;
  justify-content: space-between;
  display: flex;
  padding-right: 10px;
  padding-left: 2;
  margin-bottom: 10px;
}

/* flip card lang */

.lilContainer-lang {
  background-color: transparent;
  perspective: 1000px;
  grid-column: 1/4;
  grid-row: 2;
}

.flip-box-inner-lilContainer-lang {
  position: relative;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.lilContainer-lang:hover .flip-box-inner-lilContainer-lang {
  transform: rotateX(180deg);
}

.flip-box-front-lilContainer-lang,
.flip-box-back-lilContainer-lang {
  position: relative;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-back-lilContainer-lang {
  background-color: #c7ad9e;
  color: white;
  transform: rotateX(180deg);
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

/* flip card experience*/

.exp {
  background-color: transparent;
  perspective: 1000px;
  margin-top: 5px;
}

.title-card {
  font-size: 1.5em;
  font-weight: 600;
  margin-top: 10px;
}

/* bouton dans experience */
.btn-exp {
  grid-column-gap: 10px;
  margin: 10px;
  justify-content: center;
  align-items: center;
  height: 70%;
  display: flex;
  grid-template-columns: repeat(2, 1fr);
}

.btn-exp-design {
  padding: 20px 15px 20px 15px;
  font-size: 10px;
  font-weight: 500;
}

/* typing dans experience */

.wrapper2 {
  display: flex;
  align-items: left;
  justify-content: left;
}

.typing2 {
  width: 29ch;
  animation: typing 6s steps(35), blink 0.2s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: -"Square Peg", cursive;
  font-size: calc(15px);
  color: #94745b;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-right: 10px solid rgba(#94745b, 0.75);
  text-shadow: 0 0 2px;
}

.flip-box-inner-exp {
  position: relative;
  min-height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  justify-content: center;
}
.exp:hover .flip-box-inner-exp {
  transform: rotateX(180deg);
}

/*pour cacher la box à l'arriere */
.flip-box-front-exp,
.flip-box-back-exp {
  position: relative;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-back-exp {
  background-color: #c7ad9e;
  color: white;
  transform: rotateX(180deg);
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.footer-container {
  margin-top: 30px;
  /* background : linear-gradient(to top, rgba(25, 6, 42, 0), #19062A, rgba(255, 255, 255, 0.5 )); */
  /* border-radius: 20px; */
  /* padding: 30px;  */
}

.footer {
  font-family: "NTR", sans-serif;
  color: #f1e2e3;
  text-align: center;
  width: 100%;
  font-size: 10px;
  align-items: end;
}

.folder-icon {
  color: #f5f5f5;
  justify-content: center;
  display: inline-flex;
}
.MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
  cursor: pointer;
  margin-right: 10px;
  margin-top: -5px;
}

/*BOUTON TELECHARGMENT PROGRAMME**/
:root {
  /* color type A */
  --line_color: #555555;
  --back_color: #d4af92;

  /* color type B */
  /* --line_color : #1b1919 ;
    --back_color : #E9ECFF  ; */

  /* color type C */
  /* --line_color : #00135C ;
    --back_color : #DEFFFA  ; */
}

.button {
  position: relative;
  z-index: 0;
  max-width: 450px;
  width: 100%;
  height: 56px;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  color: var(--line_color);
  letter-spacing: 2px;
  transition: all 0.3s ease;
}
.button__text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.button::before,
.button::after,
.button__text::before,
.button__text::after {
  content: "";
  position: absolute;
  height: 3px;
  border-radius: 2px;
  background: var(--line_color);
  transition: all 0.5s ease;
}
.button::before {
  top: 0;
  left: 54px;
  width: calc(100% - 56px * 2 - 16px);
}
.button::after {
  top: 0;
  right: 54px;
  width: 8px;
}
.button__text::before {
  bottom: 0;
  right: 54px;
  width: calc(100% - 56px * 2 - 16px);
}
.button__text::after {
  bottom: 0;
  left: 54px;
  width: 8px;
}
.button__line {
  position: absolute;
  top: 0;
  width: 56px;
  height: 100%;
  overflow: hidden;
}
.button__line::before {
  content: "";
  position: absolute;
  top: 0;
  width: 150%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 300px;
  border: solid 3px var(--line_color);
}
.button__line:nth-child(1),
.button__line:nth-child(1)::before {
  left: 0;
}
.button__line:nth-child(2),
.button__line:nth-child(2)::before {
  right: 0;
}
.button:hover {
  letter-spacing: 6px;
}
.button:hover::before,
.button:hover .button__text::before {
  width: 8px;
}
.button:hover::after,
.button:hover .button__text::after {
  width: calc(100% - 56px * 2 - 16px);
}
.button__drow1,
.button__drow2 {
  position: absolute;
  z-index: -1;
  border-radius: 16px;
  transform-origin: 16px 16px;
}
.button__drow1 {
  top: -16px;
  left: 40px;
  width: 32px;
  height: 0;
  transform: rotate(30deg);
}
.button__drow2 {
  top: 44px;
  left: 77px;
  width: 32px;
  height: 0;
  transform: rotate(-127deg);
}
.button__drow1::before,
.button__drow1::after,
.button__drow2::before,
.button__drow2::after {
  content: "";
  position: absolute;
}
.button__drow1::before {
  bottom: 0;
  left: 0;
  width: 0;
  height: 32px;
  border-radius: 16px;
  transform-origin: 16px 16px;
  transform: rotate(-60deg);
}
.button__drow1::after {
  top: -10px;
  left: 45px;
  width: 0;
  height: 32px;
  border-radius: 16px;
  transform-origin: 16px 16px;
  transform: rotate(69deg);
}
.button__drow2::before {
  bottom: 0;
  left: 0;
  width: 0;
  height: 32px;
  border-radius: 16px;
  transform-origin: 16px 16px;
  transform: rotate(-146deg);
}
.button__drow2::after {
  bottom: 26px;
  left: -40px;
  width: 0;
  height: 32px;
  border-radius: 16px;
  transform-origin: 16px 16px;
  transform: rotate(-262deg);
}
.button__drow1,
.button__drow1::before,
.button__drow1::after,
.button__drow2,
.button__drow2::before,
.button__drow2::after {
  background: var(--back_color);
}
.button:hover .button__drow1 {
  animation: drow1 ease-in 0.06s;
  animation-fill-mode: forwards;
}
.button:hover .button__drow1::before {
  animation: drow2 linear 0.08s 0.06s;
  animation-fill-mode: forwards;
}
.button:hover .button__drow1::after {
  animation: drow3 linear 0.03s 0.14s;
  animation-fill-mode: forwards;
}
.button:hover .button__drow2 {
  animation: drow4 linear 0.06s 0.2s;
  animation-fill-mode: forwards;
}
.button:hover .button__drow2::before {
  animation: drow3 linear 0.03s 0.26s;
  animation-fill-mode: forwards;
}
.button:hover .button__drow2::after {
  animation: drow5 linear 0.06s 0.32s;
  animation-fill-mode: forwards;
}
@keyframes drow1 {
  0% {
    height: 0;
  }
  100% {
    height: 100px;
  }
}
@keyframes drow2 {
  0% {
    width: 0;
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  11% {
    opacity: 1;
  }
  100% {
    width: 120px;
  }
}
@keyframes drow3 {
  0% {
    width: 0;
  }
  100% {
    width: 80px;
  }
}
@keyframes drow4 {
  0% {
    height: 0;
  }
  100% {
    height: 120px;
  }
}
@keyframes drow5 {
  0% {
    width: 0;
  }
  100% {
    width: 124px;
  }
}

/* 本体には関係ないスタイル */
/* .container{
    width : 100% ;
    height : 100px ;
    display : flex ;
    flex-direction : column ;
    justify-content : left ;
    align-items : left ;
} */

/*ul pour card formation et diplome*/
ul {
  list-style: none;
  margin-left: -40px;
}

@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

@keyframes pulse {
  0%,
  100% {
    animation-timing-function: ease-in;
  }
  50% {
    transform: scale(1.33);
  }
}

ul.flex-container {
  justify-content: space-around;
}
.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 10px;
  margin: 10px;
  list-style: none;
}

.flex-item {
  padding: 0px;
  flex: 0 0 calc(25% - 20px); /* flex-grow: 0, flex-shrink: 0, flex-basis: 25% - 20px */
  box-sizing: border-box;
  margin-top: 5px;
  font-weight: bold;
  text-align: center;
  margin-right: 5px; /* Espacement entre les flex-items, sauf pour le dernier de chaque ligne */
}

.flex-item:nth-child(n + 5) {
  margin-top: 10px; /* Espacement vertical pour les éléments de la deuxième ligne et suivantes */
}

.flex-item:nth-child(4n) {
  margin-right: 0; /* Pour enlever la marge à droite du 4ème élément et commencer une nouvelle ligne */
}

.title-about-me {
  color: #8892b0;
  margin-bottom: 10px;
}

.title-panel-parcours {
  color: #8892b0;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
}

.text-panel-parcours {
  color: #795666;
  font-size: 15px;
}

.mission-panel-parcours {
  margin-top: 10px;
  font-size: 15px;
  color: black;
}

.text-grey {
  font-size: 15px;
}
/* .q-splitter__panel.q-splitter__before{
  width:50% !important;
} */
.flex-container-hobby {
  /* We first create a flex layout context */
  display: flex;

  /* Then we define the flow direction 
     and if we allow the items to wrap 
   * Remember this is the same as:
   * flex-direction: row;
   * flex-wrap: wrap;
   */
  flex-wrap: wrap;

  /* Then we define how is distributed the remaining space */
  justify-content: center;

  padding: 0;
  margin: 0;
  list-style: none;
}

.flex-item-hobby {
  background: #180429;
  width: 200px;
  height: 150px;
  color: white;
  font-weight: 500;
  font-size: 3em;
  text-align: center;
  margin: 5px;
}

/* MOBILE PLANET */

/*planet*/
$m-01: #170329;
$m-02: #364c6b;
$m-03: #506f9c;
$m-04: #506f9c;

@import url("https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap");

.contentPP {
  width: 200px;
  height: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .planett {
    width: 55%;
    height: 55%;
    background-color: $m-02;
    border-radius: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    transform-origin: center center;
    box-shadow: inset 2px -10px 0px rgba(0, 0, 0, 0.1);

    box-shadow: 0 -20px 100px, 0 0 8px, 0 0 1em #0a102f, 0 0 0.2em #0a102f,
      0 0 0.1em #0a102f, 0 1px 3px #0a102f;

    color: #fff;

    animation: planett 5s ease infinite alternate;

    @keyframes planett {
      0% {
        transform: rotate(10deg);
      }

      100% {
        transform: rotate(-10deg);
      }
    }

    /* planet ring */
    .ringg {
      position: absolute;
      width: 200px;
      height: 200px;
      border-radius: 100%;
      background-color: $m-04;
      display: flex;
      align-items: center;
      justify-content: center;
      transform-origin: 30% center;
      box-shadow: 10px -10px 0px rgba(0, 0, 0, 0.1),
        inset -5px -10px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0 -10px 20px, 0 0 7px, 0 0 5em #0a102f, 0 0 0.5em #0a102f,
        0 0 0.1em #0a102f, 0 18px 20px #0a102f;

      animation: ringg 3s ease infinite;

      @keyframes ringg {
        0% {
          transform: rotateX(110deg) rotateZ(0deg) translate(-50px, 5px);
        }

        100% {
          transform: rotateX(110deg) rotateZ(360deg) translate(-50px, 5px);
        }
      }

      /* small ball */
      &:before {
        content: "";
        position: absolute;
        width: 10px;
        height: 20px;
        border-radius: 100%;
        background-color: $m-03;
        z-index: 2;
        left: calc(0px - 5px);
        box-shadow: inset -5px 5px 0px rgba(0, 0, 0, 0.2);
      }

      /* inner ring */
      &:after {
        content: "";
        position: absolute;
        width: 200px;
        height: 220px;
        border-radius: 100%;
        background-color: $m-03;
        box-shadow: inset 2px -10px 0px rgba(0, 0, 0, 0.1);
      }
    }

    /* to cover the back of the ring */
    .cover-ringg {
      position: absolute;
      width: 100%;
      height: 60%;
      border-bottom-left-radius: 80%;
      border-bottom-right-radius: 80%;
      border-top-left-radius: 100px;
      border-top-right-radius: 100px;
      transform: translate(0px, -17px);
      background-color: $m-02;
      z-index: 2;
      box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.1);
    }

    /* planet spots */
    .spotss {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 2;

      span {
        width: 10px;
        height: 10px;
        background-color: $m-01;
        position: absolute;
        border-radius: 100%;
        box-shadow: inset -2px 3px 0px rgba(0, 0, 0, 0.3);
        animation: dots 5s ease infinite alternate;

        @keyframes dots {
          0% {
            box-shadow: inset -3px 3px 0px rgba(0, 0, 0, 0.3);
          }
          100% {
            box-shadow: inset 3px 3px 0px rgba(0, 0, 0, 0.3);
          }
        }

        &:nth-child(1) {
          top: 20px;
          right: 60px;
        }

        // &:nth-child(2) {
        //    top: 40px;
        //    left: 50px;
        //    width: 15px;
        //    height: 15px;
        // }

        &:nth-child(3) {
          top: 40px;
          left: 20px;
          width: 25px;
          height: 25px;
        }

        &:nth-child(4) {
          top: 20px;
          left: 90px;
          width: 20px;
          height: 20px;
        }

        &:nth-child(5) {
          top: 60px;
          left: 100px;
          width: 10px;
          height: 10px;
        }

        &:nth-child(6) {
          top: 105px;
          left: 90px;
          width: 5px;
          height: 5px;
        }

        &:nth-child(7) {
          top: 110px;
          left: 35px;
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}
</style>
