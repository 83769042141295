<template>
  <div class="content">
    <div id="home">
      <div class="wrapper">
        <div class="premierSection">
          <div class="col1-wrapper">
            <div class="contentPP">
              <div class="planett">
                <div class="ringg"></div>
                <div class="cover-ringg"></div>
                <div class="spotss">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col2-wrapper">
          <div class="logo">
            <b>Si<span>be</span>l<span>Kré</span>a<span>tion</span>.com</b>
          </div>
        </div>
      </div>
      <div class="text-home">
        <p>
          <!--  Après avoir étudié dans les domaines du développement web, du
          webdesign, de la communication, de l'e-commerce et du webmarketing 
          je suis actuellement à la recherche de nouvelles oppportunités dans le
          <b style="color:#40cfef;">marketing digital, le webdesign et la communication.</b> <br /> -->

          <b style="color: #40cfef"
            >À la recherche de nouvelles opportunités dans le marketing digital,
            le webdesign et la communication</b
          >
          <br />

          Après avoir suivi plusieurs formations riches et variées dans les
          domaines du développement web, du webdesign, de la communication, de
          l'e-commerce et du webmarketing, je souhaite aujourd'hui mettre mes
          compétences au service de nouveaux projets stimulants.
        </p>
      </div>
      <div style="color: #40cfef">
        <q-btn
          outline
          style="color: #40cfef"
          class="intro-contact"
          href="mailto:sibel.koc.pro@outlook.fr"
          label="Envoyer un email"
        />
      </div>

      <div class="mouse">
        <div class="scrolldown">
          <div class="chevrons">
            <div class="chevrondown"></div>
            <div class="chevrondown"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="sidebar-nav">
      <div class="sidebar-links">
        <div class="fade-in-section is-visible" style="transition-delay: 100ms">
          <div><a href="#home">/Accueil</a></div>
        </div>

        <div class="fade-in-section is-visible" style="transition-delay: 200ms">
          <div><a href="#about">/A Propos</a></div>
        </div>

        <div class="fade-in-section is-visible" style="transition-delay: 300ms">
          <div><a href="#experience">/Expériences</a></div>
        </div>

        <div class="fade-in-section is-visible" style="transition-delay: 400ms">
          <div><a href="#projects">/Créations</a></div>
        </div>
      </div>

      <div class="sidebar-logos" href="/">
        <a href="mailto:sibel.koc.pro@outlook.fr"
          ><svg
            class="MuiSvgIcon-root"
            focusable="false"
            viewBox="0 0 512 512"
            aria-hidden="true"
            style="font-size: 19px"
          >
            <path
              d="M464 64C490.5 64 512 85.49 512 112C512 127.1 504.9 141.3 492.8 150.4L275.2 313.6C263.8 322.1 248.2 322.1 236.8 313.6L19.2 150.4C7.113 141.3 0 127.1 0 112C0 85.49 21.49 64 48 64H464zM217.6 339.2C240.4 356.3 271.6 356.3 294.4 339.2L512 176V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V176L217.6 339.2z"
            ></path></svg
        ></a>
        <a href="https://github.com/sibelkoc"
          ><svg
            class="MuiSvgIcon-root"
            focusable="false"
            viewBox="0 0 496 512"
            aria-hidden="true"
            style="font-size: 19px"
          >
            <path
              d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"
            ></path></svg
        ></a>
        <a href="https://www.linkedin.com/in/sibel-koc/"
          ><svg
            class="MuiSvgIcon-root"
            focusable="false"
            viewBox="0 0 448 512"
            aria-hidden="true"
            style="font-size: 19px"
          >
            <path
              d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
            ></path></svg
        ></a>
      </div>
    </div>

    <div id="about">
      <div class="section-header">
        <span class="section-title">A propos de moi</span>
      </div>
      <div class="about-content">
        <div class="about-description">
          <p>
            <!-- Etudiante en BAC+3 à l'école de l'<b style="color:#40cfef;"><a target="_blank" href="https://www.eemi.com/">EEMI</a></b>,
            je vise le titre de Cheffe de Projet Digital, spécialité <b style="color:#40cfef;"
              ><a
                target="_blank"
                href="https://www.eemi.com/formations/bachelor-digital-marketing-business/"
              >
                "Digital Marketing &amp; e-Business"</a
              ></b
            >. Actuellement en alternance chez B&amp;C Formation, je me forme également en communication globale et en design graphique. -->

            Diplômée d’un
            <b style="color: #40cfef"
              >BAC+3 en Chef de Projet Digital Marketing</b
            >
            à l’EEMI, je recherche de nouvelles opportunités pour relever des
            défis en
            <b style="color: #40cfef"
              >marketing digital, communication globale, webdesign</b
            >
            ou <b style="color: #40cfef">e-commerce</b>. <br /><br />
            Actuellement, j’approfondis mes compétences en e-commerce via
            WordPress et Shopify et je me forme sur la suite Adobe (Photoshop,
            Illustrator, After Effects).

            <br /><br />
            Par la suite, je souhaite intégrer un MBA (BAC+5) en marketing
            digital pour approfondir mes connaissances.
          </p>

          <!--<p>Cliquez, pour découvrir le programme de la formation :</p>
          <div class="display">
            <div class="q-pa-sm q-gutter-md">
              <q-btn
                push
                label="Digital Marketing &amp; e-Business"
                style="color: #40fcef; width: 100%; border-color: #40cfef; border:1px solid;"
                @click="small1 = true"
              />
              <q-dialog v-model="small1">
                <q-card style="width: 80%; background-color: #210533 ;">
                  <q-card-section>
                    <div
                      class="text-h6"
                      style="
                        color: #40cfef;
                        font-size: 25px;
                        text-decoration: underline;
                      "
                    >
                      <b>Digital Marketing &amp; e-Business<br /><br /></b>
                    </div>
                    <p style="color: #fff; margin-top: -25px">
                      Durée 12 mois.
                    </p>
                  </q-card-section>

                  <q-card-section class="q-pt-none">
                    <div class="text">
                      <b style="color: #40cfef; font-size: 18px"
                        >Initiation :</b
                      >
                      <ul class="tech-stack" style="color: #fff;">
                        ➤ Marketing Stratégique<br />
                        ➤ Social Media<br />
                        ➤ Social Selling<br />
                        ➤ SEO/SEM<br />
                        ➤ Neuromarketing<br />
                        ➤ IA<br />
                        ➤ Sites E-commerce<br />
                        ➤ Création de contenu<br />
                        ➤ Copy Strategy<br />
                        ➤ Influence<br />
                        ➤ Benchmark et veille concurrentielle<br />
                        ➤ Automatisation<br />
                        ➤ Google Analytics<br />
                        ➤ DataViz<br />
                        ➤ Customer Data Platform<br />
                        ➤ Data Management Platform<br />
                      </ul>
                      <br />
                      <div style="color: #40fcef; width: 50%; border-color: #40cfef; border:1px solid; border-radius:5px;">
                       <q-btn
                        push
                        label="Voir le programme en détail"
                        href="https://www.eemi.com/formations/bachelor-digital-marketing-business/"
                        target="_blank"
                      /></div>
                      --- <div class="container">
                        <a
                          href="https://www.eemi.com/formations/bachelor-digital-marketing-business/"
                          data-bs-target="#contactBrochureModal"
                          class="button"
                          target="_blank"
                        >
                          <div class="button__line"></div>
                          <div class="button__line"></div>
                          <span class="button__text"
                            >Voir le programme en détail</span
                          >
                          <div class="button__drow1"></div>
                          <div class="button__drow2"></div>
                        </a>
                      </div> ---
                    </div>
                  </q-card-section>

                  <q-card-actions
                    align="right"
                    class="nothing"
                    style="color: #40cfef"
                  >
                    <q-btn flat label="OK" v-close-popup />
                  </q-card-actions>
                </q-card>
              </q-dialog>
            </div> 
          </div> -->

          Voici quelques technologies et outils avec lesquelles je travaille :
          <ul class="tech-stack">
            <div
              class="fade-in-section is-visible"
              style="transition-delay: 100ms"
            >
              <li>HTML &amp; CSS</li>
            </div>
            <div
              class="fade-in-section is-visible"
              style="transition-delay: 200ms"
            >
              <li>Javascript</li>
            </div>
            <div
              class="fade-in-section is-visible"
              style="transition-delay: 300ms"
            >
              <li>PhpMyAdmin/MySQL/VSC</li>
            </div>
            <div
              class="fade-in-section is-visible"
              style="transition-delay: 400ms"
            >
              <li>Quasar/Bootstrap</li>
            </div>
            <div
              class="fade-in-section is-visible"
              style="transition-delay: 500ms"
            >
              <li>React/Vue.js/Node.js</li>
            </div>
            <div
              class="fade-in-section is-visible"
              style="transition-delay: 600ms"
            >
              <li>CMS Wordpress &amp; Shopify</li>
            </div>
            <div
              class="fade-in-section is-visible"
              style="transition-delay: 700ms"
            >
              <li>Jira / Trello</li>
            </div>
            <div
              class="fade-in-section is-visible"
              style="transition-delay: 800ms"
            >
              <li>Photopea &amp; Canva</li>
            </div>
            <div
              class="fade-in-section is-visible"
              style="transition-delay: 900ms"
            >
              <li>Github &amp; Gitlab &amp; Git</li>
            </div>
            <div
              class="fade-in-section is-visible"
              style="transition-delay: 900ms"
            >
              <li>UI-UX/Figma/Mockflow</li>
            </div>
            <div
              class="fade-in-section is-visible"
              style="transition-delay: 1000ms"
            >
              <li>SemRush/SEO</li>
            </div>
            <div
              class="fade-in-section is-visible"
              style="transition-delay: 1000ms"
            >
              <li>Google Ads</li>
            </div>
            <div
              class="fade-in-section is-visible"
              style="transition-delay: 1100ms"
            >
              <li>Photoshop, Indesign</li>
            </div>
            <div
              class="fade-in-section is-visible"
              style="transition-delay: 1200ms"
            >
              <li>Bureautique (office 365)</li>
            </div>
          </ul>
          <br />
          En dehors du travail, j'apprécie le sport (fitness et sport de
          combat), la lecture et la pâtisserie, qui nourrissent ma créativité et
          mon équilibre au quotidien.
        </div>

        <!-- <div class="about-image"> -->
        <!-- <img src="../assets/Sibelweb.jpg" /> -->

        <!-- 
<div class="bodyRoom">

<div class="house" id="h">
  <div class="h-lights">
    <div class="h-light"></div>
    <div class="h-light"></div>
    <div class="h-light"></div>
    <div class="h-light"></div>
    <div class="h-light"></div>
    <div class="h-light"></div>
  </div>
  <div class="h-shadow"></div>
  <div class="alt">
    <div class="alt__front face"> </div>
    <div class="alt__back face"> </div>
    <div class="alt__right face"> </div>
    <div class="alt__left face"> </div>
    <div class="alt__top face">
      <div class="light"></div>
      <div class="light"></div>
      <div class="light"></div>
      <div class="light"></div>
      <div class="light"></div>
      <div class="light"></div>
      <div class="light"></div>
      <div class="light"></div>
      <div class="light"></div>
    </div>
    <div class="alt__bottom face"> </div>
  </div>
  <div class="blt">
    <div class="blt__front face"> </div>
    <div class="blt__back face"> </div>
    <div class="blt__right face"> </div>
    <div class="blt__left face"> </div>
    <div class="blt__top face"> </div>
    <div class="blt__bottom face"> </div>
  </div>
  <div class="blt2">
    <div class="blt2__front face"> </div>
    <div class="blt2__back face"> </div>
    <div class="blt2__right face"> </div>
    <div class="blt2__left face"> </div>
    <div class="blt2__top face"> </div>
    <div class="blt2__bottom face"> </div>
  </div>
  <div class="blb">
    <div class="blb__front face"> </div>
    <div class="blb__back face"> </div>
    <div class="blb__right face"> </div>
    <div class="blb__left face"> </div>
    <div class="blb__top face"> </div>
    <div class="blb__bottom face"> </div>
  </div>
  <div class="blb2">
    <div class="blb2__front face"> </div>
    <div class="blb2__back face"> </div>
    <div class="blb2__right face"> </div>
    <div class="blb2__left face"> </div>
    <div class="blb2__top face"> </div>
    <div class="blb2__bottom face"> </div>
  </div>
  <div class="puerta-c">
    <div class="puerta">
      <div class="puerta__front face"> </div>
      <div class="puerta__back face"> </div>
      <div class="puerta__right face"> </div>
      <div class="puerta__left face"> </div>
      <div class="puerta__top face"> </div>
      <div class="puerta__bottom face"> </div>
    </div>
    <div class="puerta-l">
      <div class="puerta-l__front face"> </div>
      <div class="puerta-l__back face"> </div>
      <div class="puerta-l__right face"> </div>
      <div class="puerta-l__left face"> </div>
      <div class="puerta-l__top face"> </div>
      <div class="puerta-l__bottom face"> </div>
    </div>
    <div class="puerta-r">
      <div class="puerta-r__front face"> </div>
      <div class="puerta-r__back face"> </div>
      <div class="puerta-r__right face"> </div>
      <div class="puerta-r__left face"> </div>
      <div class="puerta-r__top face"> </div>
      <div class="puerta-r__bottom face"> </div>
    </div>
    <div class="puerta-t">
      <div class="puerta-t__front face"> </div>
      <div class="puerta-t__back face"> </div>
      <div class="puerta-t__right face"> </div>
      <div class="puerta-t__left face"> </div>
      <div class="puerta-t__top face"> </div>
      <div class="puerta-t__bottom face"> </div>
    </div>
  </div>
  <div class="librero">
    <div class="librero__front face"> </div>
    <div class="librero__back face"> </div>
    <div class="librero__right face"> </div>
    <div class="librero__left face"> </div>
    <div class="librero__top face"> </div>
    <div class="librero__bottom face"> </div>
  </div>
  <div class="libros">
    <div class="libro">
      <div class="libro__front face"> </div>
      <div class="libro__back face"> </div>
      <div class="libro__right face"> </div>
      <div class="libro__left face"> </div>
      <div class="libro__top face"> </div>
      <div class="libro__bottom face"> </div>
    </div>
    <div class="libro">
      <div class="libro__front face"> </div>
      <div class="libro__back face"> </div>
      <div class="libro__right face"> </div>
      <div class="libro__left face"> </div>
      <div class="libro__top face"> </div>
      <div class="libro__bottom face"> </div>
    </div>
    <div class="libro">
      <div class="libro__front face"> </div>
      <div class="libro__back face"> </div>
      <div class="libro__right face"> </div>
      <div class="libro__left face"> </div>
      <div class="libro__top face"> </div>
      <div class="libro__bottom face"> </div>
    </div>
    <div class="libro">
      <div class="libro__front face"> </div>
      <div class="libro__back face"> </div>
      <div class="libro__right face"> </div>
      <div class="libro__left face"> </div>
      <div class="libro__top face"> </div>
      <div class="libro__bottom face"> </div>
    </div>
    <div class="libro">
      <div class="libro__front face"> </div>
      <div class="libro__back face"> </div>
      <div class="libro__right face"> </div>
      <div class="libro__left face"> </div>
      <div class="libro__top face"> </div>
      <div class="libro__bottom face"> </div>
    </div>
    <div class="libro">
      <div class="libro__front face"> </div>
      <div class="libro__back face"> </div>
      <div class="libro__right face"> </div>
      <div class="libro__left face"> </div>
      <div class="libro__top face"> </div>
      <div class="libro__bottom face"> </div>
    </div>
  </div>
  <div class="tv">
    <div class="tv__front face"> </div>
    <div class="tv__back face"> </div>
    <div class="tv__right face"> </div>
    <div class="tv__left face"> </div>
    <div class="tv__top face"> </div>
    <div class="tv__bottom face"> </div>
  </div>
  <div class="repisa-right">
    <div class="repisa-right__front face"> </div>
    <div class="repisa-right__back face"> </div>
    <div class="repisa-right__right face"> </div>
    <div class="repisa-right__left face"> </div>
    <div class="repisa-right__top face"> </div>
    <div class="repisa-right__bottom face"> </div>
  </div>
  <div class="repisa-left">
    <div class="repisa-left__front face"> </div>
    <div class="repisa-left__back face"> </div>
    <div class="repisa-left__right face"> </div>
    <div class="repisa-left__left face"> </div>
    <div class="repisa-left__top face"> </div>
    <div class="repisa-left__bottom face"> </div>
  </div>
  <div class="repisa-b">
    <div class="repisa-b__front face"> </div>
    <div class="repisa-b__back face"> </div>
    <div class="repisa-b__right face"> </div>
    <div class="repisa-b__left face"> </div>
    <div class="repisa-b__top face"> </div>
    <div class="repisa-b__bottom face"> </div>
  </div>
    <div class="repisa-c">
    <div class="repisa-c__front face"> </div>
    <div class="repisa-c__back face"> </div>
    <div class="repisa-c__right face"> </div>
    <div class="repisa-c__left face"> </div>
    <div class="repisa-c__top face"> </div>
    <div class="repisa-c__bottom face"> </div>
  </div>
      <div class="repisa-d">
    <div class="repisa-d__front face"> </div>
    <div class="repisa-d__back face"> </div>
    <div class="repisa-d__right face"> </div>
    <div class="repisa-d__left face"> </div>
    <div class="repisa-d__top face"> </div>
    <div class="repisa-d__bottom face"> </div>
  </div>
   <div class="repisa-e">
    <div class="repisa-e__front face"> </div>
    <div class="repisa-e__back face"> </div>
    <div class="repisa-e__right face"> </div>
    <div class="repisa-e__left face"> </div>
    <div class="repisa-e__top face"> </div>
    <div class="repisa-e__bottom face"> </div>
  </div>
   <div class="repisa-f">
    <div class="repisa-f__front face"> </div>
    <div class="repisa-f__back face"> </div>
    <div class="repisa-f__right face"> </div>
    <div class="repisa-f__left face"> </div>
    <div class="repisa-f__top face"> </div>
    <div class="repisa-f__bottom face"> </div>
  </div>
   <div class="repisa-g">
    <div class="repisa-g__front face"> </div>
    <div class="repisa-g__back face"> </div>
    <div class="repisa-g__right face"> </div>
    <div class="repisa-g__left face"> </div>
    <div class="repisa-g__top face"> </div>
    <div class="repisa-g__bottom face"> </div>
  </div>
  <div class="muro">
    <div class="muro__front face"> </div>
    <div class="muro__back face"> </div>
    <div class="muro__right face"> </div>
    <div class="muro__left face"> </div>
    <div class="muro__top face"> </div>
    <div class="muro__bottom face"></div>
  </div>
</div> 
</div>-->
      </div>
    </div>

    <div id="experience">
      <div class="section-header">
        <span class="section-title">Expériences</span>
      </div>

      <q-splitter v-model="splitterModel" style="height: 480px; width: 1000px">
        <template v-slot:before>
          <q-tabs v-model="tab" vertical class="tab">
            <q-tab
              style="
                text-align: start;
                justify-content: left;
                font-family: made_tommy, sans-serif;
              "
              name="MBA"
              label="MBA à venir...😉"
            ></q-tab>

            <q-tab
              style="
                text-align: start;
                justify-content: left;
                font-family: made_tommy, sans-serif;
              "
              name="BC_Formation"
              label="B&amp;C Formation"
            ></q-tab>

            <q-tab
              style="
                text-align: start;
                justify-content: left;
                font-family: made_tommy, sans-serif;
              "
              name="La_fabrique"
              label="La Fabrique de com"
            ></q-tab>
            <q-tab
              style="
                text-align: start;
                justify-content: left;
                font-family: made_tommy, sans-serif;
              "
              name="Cood"
              label="Cood"
            ></q-tab>
            <q-tab
              style="
                text-align: start;
                justify-content: left;
                font-family: made_tommy, sans-serif;
              "
              name="WCS"
              label="Wild Code School"
            ></q-tab>
            <q-tab
              style="
                text-align: start;
                justify-content: left;
                font-family: made_tommy, sans-serif;
              "
              name="Alter"
              label="Alter Ego Digital"
            ></q-tab>
            <q-tab
              style="
                text-align: start;
                justify-content: left;
                font-family: made_tommy, sans-serif;
              "
              name="Krea"
              label="Krea'Lab"
            ></q-tab>
          </q-tabs>
        </template>

        <template v-slot:after>
          <q-tab-panels
            v-model="tab"
            class="job-description"
            animated
            swipeable
            vertical
            transition-prev="jump-up"
            transition-next="jump-up"
          >
            <q-tab-panel name="MBA">
              <div class="title-panel text-h5 q-mb-md">
                MBA (BAC+5) à venir...
              </div>

              <p style="font-size: 16px; color: #bababa; text-align: left">
                Je souhaite prochainement intégrer un MBA (BAC+5) en
                <b style="color: #40cfef"> marketing digital, </b>
                <b style="color: #40cfef">webdesign</b> ou
                <b style="color: #40cfef">communication digitale</b> afin
                d'approfondir mes compétences dans ces domaines.
              </p>
            </q-tab-panel>

            <q-tab-panel name="BC_Formation">
              <div class="title-panel text-h5 q-mb-md">
                BAC+3 Marketing Digital en alternance chez
                <p style="color: #40cfef; display: inline-block">
                  <a target="_blank" href="https://www.bcformation.com/"
                    ><u>B&amp;C Formation</u></a
                  >
                </p>
                avec l'école de
                <a target="_blank" href="https://www.eemi.com/">
                  <u style="color: #40cfef; display: inline-block">
                    l'EEMI.</u
                  ></a
                ><i style="font-size: 10px; margin-left: 10px"
                  >Diplôme obtenu✔️</i
                >
              </div>

              <ul class="job-description">
                <div class="fade-in-section1 is-visible text-tab">
                  <div style="font-size: 16px; color: #bababa">
                    Chargée de communication globale et marketing digital, mes
                    principales missions incluaient :<br /><br />
                  </div>

                  <div style="font-size: 15px">
                    <li>
                      Refonte et optimisation du site web de B&amp;C
                      Formation.<br />
                    </li>
                    <li>
                      Community Management : gestion de la communication sur les
                      réseaux sociaux via un planning stratégique.<br />
                    </li>
                    <li>Création de newsletters internes et externes<br /></li>
                    <li>
                      Conception de kits de communication pour des salons
                      étudiants (kakémonos, flyers, goodies, etc.)<br />
                    </li>
                    <li>
                      Réalisation de visuels tels que flyers et affiches (pour
                      certains partenaires, évenements, etc.)<br />
                    </li>
                    <li>
                      Collaboration à la conception d'un jeu sur la sécurité
                      avec un formateur<br />
                    </li>
                    <li>Piloter un projet <br /></li>
                    <li>Démarchage téléphonique <br /></li>
                  </div>
                </div>
                <div class="fade-in-section1 is-visible text-tab"></div>
              </ul>
            </q-tab-panel>

            <q-tab-panel name="La_fabrique">
              <div class="title-panel text-h5 q-mb-md">
                BAC+3 Développeur Web et Web Mobile en alternance chez
                <p style="display: inline-block">
                  <a target="_blank" href="https://www.lafabriquedecom.fr/"
                    ><u style="color: #40cfef">La fabrique de com</u></a
                  >
                  avec l'école
                  <a
                    target="_blank"
                    href="https://www.2itechacademy.com/programmes/concepteur-developpeur-dapplications/"
                    ><u style="color: #40cfef">2iTech</u></a
                  >
                </p>
                <i style="font-size: 10px; margin-left: 10px"
                  >Diplôme non obtenu car j'ai quitté la formation❌</i
                >
              </div>
              <ul class="job-description">
                <div class="fade-in-section1 is-visible text-tab">
                  <div style="font-size: 16px; color: #bababa">
                    Au sein de La Fabrique de Com, j’ai participé à :<br /><br />
                  </div>
                  <div style="font-size: 15px">
                    <li>
                      Responsabilité de la gestion et des suivis des sites web
                      au quotidien.<br />
                    </li>
                    <li>
                      Conception de sites web et mise à jour des sites
                      existants.<br />
                    </li>
                    <li>
                      Webdesign, intégration web et programmation web <br />
                    </li>
                    <li>Veille technologique.<br /></li>
                  </div>
                </div>
                <div class="fade-in-section1 is-visible text-tab"></div>
              </ul>
            </q-tab-panel>
            <q-tab-panel name="Cood">
              <div class="title-panel text-h5 q-mb-md">
                BAC+2 BTS SIO Développeur Web et Web Mobile en alternance chez
                <p style="color: #40cfef; display: inline-block">
                  <a target="_blank" href="https://cood.fr/"><u>Cood</u></a>
                </p>
                avec l'ecole de
                <a target="_blank" href="https://www.formation-aftec.com/">
                  <u style="color: #40cfef">L'Aftec</u></a
                ><i style="font-size: 10px; margin-left: 10px"
                  >Diplôme obtenu✔️</i
                >
              </div>
              <ul class="job-description">
                <div class="fade-in-section1 is-visible text-tab">
                  <div style="font-size: 16px; color: #bababa">
                    Nous étions une petite équipe basée à Orléans, collaborant
                    avec une partie de l’équipe à Paris.<br />
                    Cood a créé deux univers :
                    <b style="color: #40cfef">Cood Studio</b> et
                    <b style="color: #40cfef">Cood Académie</b>.<br /><br />
                    ➤ <b style="color: #40cfef">Cood Studio</b> : une
                    application ludique permettant d'apprendre le codage à
                    travers des jeux et des assets, un peu comme Scratch.<br /><br />
                    ➤ <b style="color: #40cfef">Cood Académie</b> : une
                    application dédiée à l’enseignement du numérique, permettant
                    aux petits et grands de se préparer à des certifications
                    comme PIX, tout en abordant les dangers du numérique.<br /><br />
                    Mes missions au sein de l'entreprise :<br /><br />
                  </div>
                </div>
                <div class="fade-in-section1 is-visible text-tab">
                  <div style="font-size: 15px">
                    <li>
                      Refonte visuelle et structurelle du site officiel de
                      Cood.<br />
                    </li>
                    <li>
                      Création de maquette dans le cadre de la refonte du site
                      internet.<br />
                    </li>
                    <li>
                      Création d'une documentation interactive | quasar-
                      vue.js<br />
                    </li>
                    <li>
                      Refonte de certains jeux et tutos avec la technologie
                      blockly sur le cood studio<br />
                    </li>
                    <li>
                      Création d'illustration d'univers de jeu à travers des
                      assets existants.<br />
                    </li>
                    <li>Communication hebdomadaires.<br /></li>
                    <li>
                      Méthode agile, participation au développement des
                      applications.<br />
                    </li>
                  </div>
                </div>
              </ul>
            </q-tab-panel>

            <q-tab-panel name="WCS">
              <div class="title-panel text-h5 q-mb-md">
                Equivalent BAC+2 certification développeur web et web mobile à
                la
                <p style="color: #40cfef; display: inline-block">
                  <a
                    style="color: #40cfef"
                    target="_blank"
                    href="https://www.wildcodeschool.com/"
                    ><u>Wild Code School</u></a
                  >
                </p>
                <i style="font-size: 10px; margin-left: 10px"
                  >Diplôme obtenu✔️</i
                >
              </div>
              <ul class="job-description">
                <div class="fade-in-section1 is-visible text-tab">
                  <li>
                    Etude sur les langages de programmation HTML/CSS, Python,
                    JavaScript.<br />
                  </li>
                  <li>Design: UX UI<br /></li>
                  <li>
                    Découverte et initiation de logiciels : MySql, PhpMyAdmin,
                    VSC Visual Studio Code, Github, Gitlab<br />
                  </li>
                  <li>Méthode agile : SCRUM<br /></li>
                  <li>Relation Client pour projet<br /></li>
                  <li>
                    Relation Client fictif exemple jeu Pokemon :
                    <a href="https://pokemon-battle.netlify.app/"
                      ><b><u>Pokemon-Battle</u></b></a
                    >
                    ou
                    <a href="https://51.38.188.2/"
                      ><b><u>Déliv'green</u></b></a
                    >
                    étant une application mettant en relation des livreurs
                    particulier et professionnels. Une application où l'on peut
                    être coursier et un simple client souhaitant faire livrer
                    son colis.<br />
                  </li>
                  <li>Participation à des hackathons.<br /></li>
                </div>
                <div class="fade-in-section1 is-visible text-tab">
                  <li>
                    D'autre projets on été réalisés sous forme d'hackathon, des
                    défis à relever en seulement trois jours.
                  </li>
                </div>
              </ul>
            </q-tab-panel>

            <q-tab-panel name="Alter">
              <div class="title-panel text-h5 q-mb-md">
                Stage d'apprentissage chez
                <p style="color: #40cfef; display: inline-block">
                  Alter Ego Digital
                </p>
              </div>
              <ul class="job-description">
                <div class="fade-in-section1 is-visible text-tab">
                  <li>
                    Développeur web front-end d'une durée de six semaines.<br />
                    Start-up permettant d'aider les autres entreprises à réduire
                    leur taux d'absentéisme, à réduire les douleurs physiques
                    des salariés à travers une plateforme proposant des
                    exercices physiques... <br />
                    Refonte d'une fonctionnalité.
                  </li>
                </div>
              </ul>
            </q-tab-panel>

            <q-tab-panel name="Krea">
              <div class="title-panel text-h5 q-mb-md">
                Stage d'apprentissage chez
                <p style="color: #40cfef; display: inline-block">Krea'Lab</p>
              </div>
              <ul class="job-description">
                <div
                  class="fade-in-section1 is-visible text-tab"
                  style="transition-delay: 100ms"
                >
                  <li>
                    Stage dans la start-up de Krea'lab de quatre semaines.<br />
                    Participation à trois projets (front-end) "Labo-Lamé"
                    "Primmo" "logigramme primmo".
                  </li>
                </div>
              </ul>
            </q-tab-panel>
          </q-tab-panels>
        </template>
      </q-splitter>
    </div>

    <div id="projects">
      <div class="section-header">
        <span class="section-title">Créations</span>
      </div>

      <div class="project-container">
        <ul class="projects-grid">
          <div>
            <li class="projects-card">
              <div class="card-header">
                <div class="folder-icon">
                  <svg
                    class="MuiSvgIcon-root"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    style="font-size: 35px"
                  >
                    <path
                      d="M20 6h-8l-1.41-1.41C10.21 4.21 9.7 4 9.17 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-1 12H5c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1z"
                    ></path>
                  </svg>
                </div>
                <span class="external-links">
                  <svg
                    class="MuiSvgIcon-root"
                    @click="carouselFlyers = true"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    style="font-size: 25px; color: var(--lightest-slate)"
                  >
                    <path
                      d="M19 4H5c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h4v-2H5V8h14v10h-4v2h4c1.1 0 2-.9 2-2V6c0-1.1-.89-2-2-2zm-7 6l-4 4h3v6h2v-6h3l-4-4z"
                    ></path>
                  </svg>
                  <q-dialog v-model="carouselFlyers">
                    <q-carousel
                      transition-prev="slide-right"
                      transition-next="slide-left"
                      swipeable
                      animated
                      v-model="slide"
                      control-color="primary"
                      navigation
                      padding
                      height="800px"
                      max-width="100%"
                      class="bg-white shadow-1 rounded-borders"
                    >
                      <q-carousel-slide
                        :name="1"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/FlyersJPO.png"
                          alt="Affiche journée porte ouverte"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="2"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/FlyersVisuelFormation.png"
                          alt="Affiche, Visuel de formation"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="3"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/FlyersSekuritelle2.png"
                          alt="Première affiche de Sékurit'elles"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="4"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/FlyersSekuritelle1.png"
                          alt="Deuxieme affiche de Sékuit'elles"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="5"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/FlyersCartedeVœux31.png"
                          alt="Carte de voeux 31"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="6"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/FlyersCartedeVœux32.png"
                          alt="Carte de voeux 32"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="7"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/FlyersCartedeVœux21.png"
                          alt="Carte de voeux 21"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="8"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/FlyersCartedeVœux22.png"
                          alt="Carte de voeux 22"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="9"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/FlyersCartedeVœux11.png"
                          alt="Carte de voeux 11"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="10"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/FlyersCartedeVœux12.png"
                          alt="Carte de Voeux 12"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                    </q-carousel>
                  </q-dialog>
                </span>
              </div>
              <div class="card-title" style="font-size: 15px; color: grey">
                B&amp;C Formation
              </div>
              <div class="card-title">Création de flyers</div>
              <div class="card-desc">
                Chez B&amp;C Formation, j’ai réalisé la création d’affiches pour
                divers événements, notamment des portes ouvertes, des
                collaborations avec des partenaires, ainsi que des cartes de
                vœux et d’autres supports visuels destinés à renforcer la
                communication de l’entreprise.
              </div>
              <div class="card-tech">Design / Graphique</div>
            </li>
          </div>

          <div>
            <li class="projects-card">
              <div class="card-header">
                <div class="folder-icon">
                  <svg
                    class="MuiSvgIcon-root"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    style="font-size: 35px"
                  >
                    <path
                      d="M20 6h-8l-1.41-1.41C10.21 4.21 9.7 4 9.17 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-1 12H5c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1z"
                    ></path>
                  </svg>
                </div>
                <span class="external-links">
                  <svg
                    class="MuiSvgIcon-root"
                    @click="carouselKit = true"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    style="font-size: 25px; color: var(--lightest-slate)"
                  >
                    <path
                      d="M19 4H5c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h4v-2H5V8h14v10h-4v2h4c1.1 0 2-.9 2-2V6c0-1.1-.89-2-2-2zm-7 6l-4 4h3v6h2v-6h3l-4-4z"
                    ></path>
                  </svg>
                  <q-dialog v-model="carouselKit">
                    <q-carousel
                      transition-prev="slide-right"
                      transition-next="slide-left"
                      swipeable
                      animated
                      v-model="slide"
                      control-color="primary"
                      navigation
                      padding
                      height="800px"
                      max-width="100%"
                      class="bg-white shadow-1 rounded-borders"
                    >
                      <q-carousel-slide
                        :name="1"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/brochure1.png"
                          alt="Brochure1"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="2"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/brochure2.png"
                          alt="Brochure 2"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="4"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/brochure4.jpg"
                          alt="Brochure 4"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="5"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/chemise.png"
                          alt="Chemise a rabat"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="6"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/chemise2.jpg"
                          alt="Chemise a rabat"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="7"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/kakemono.jpg"
                          alt="Kakemono"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="8"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/kakemono1.png"
                          alt="Kakemono 1"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="9"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/kakemono2.png"
                          alt="Kakemono 2"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="10"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/kakemono3.png"
                          alt="Kakemono 3"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="11"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/kakemono4.png"
                          alt="Kakemono 4"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="12"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/Houssetable.png"
                          alt="housse de table 1"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="13"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/houssetablle.png"
                          alt="Housse de table 2"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                    </q-carousel>
                  </q-dialog>
                </span>
              </div>
              <div class="card-title" style="font-size: 15px; color: grey">
                B&amp;C Formation
              </div>
              <div class="card-title">
                Création de kit pour des salons étudiant
              </div>
              <div class="card-desc">
                Chez B&amp;C Formation, j’ai conçu des kits pour des événements
                comme des portes ouvertes et le Salon de l'Étudiant, incluant la
                création de maquettes, le chiffrement, et la conception de
                goodies avant de finaliser les commandes.
              </div>
              <div class="card-tech">Design / Graphique</div>
            </li>
          </div>

          <div>
            <li class="projects-card">
              <div class="card-header">
                <div class="folder-icon">
                  <svg
                    class="MuiSvgIcon-root"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    style="font-size: 35px"
                  >
                    <path
                      d="M20 6h-8l-1.41-1.41C10.21 4.21 9.7 4 9.17 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-1 12H5c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1z"
                    ></path>
                  </svg>
                </div>
                <span class="external-links">
                  <svg
                    class="MuiSvgIcon-root"
                    @click="carouselId = true"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    style="font-size: 25px; color: var(--lightest-slate)"
                  >
                    <path
                      d="M19 4H5c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h4v-2H5V8h14v10h-4v2h4c1.1 0 2-.9 2-2V6c0-1.1-.89-2-2-2zm-7 6l-4 4h3v6h2v-6h3l-4-4z"
                    ></path>
                  </svg>
                  <q-dialog v-model="carouselId">
                    <q-carousel
                      transition-prev="slide-right"
                      transition-next="slide-left"
                      swipeable
                      animated
                      v-model="slide"
                      control-color="primary"
                      navigation
                      padding
                      height="800px"
                      max-width="100%"
                      class="bg-white shadow-1 rounded-borders"
                    >
                      <q-carousel-slide
                        :name="1"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/rollUp1.png"
                          alt="Roll up 1"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="2"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/rollUp2.png"
                          alt="Roll Up 2"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="3"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/rollUp3.png"
                          alt="roll Up 3"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="4"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/rollUp4.png"
                          alt="Roll Up 4"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                    </q-carousel>
                  </q-dialog>
                </span>
              </div>
              <div class="card-title" style="font-size: 15px; color: grey">
                B&amp;C Formation
              </div>
              <div class="card-title">
                Création de roll-up pour B&amp;C Formation
              </div>
              <div class="card-desc">
                J'ai B&amp;C Formation, j'ai conçu des roll-ups selon l'identité
                visuel.
              </div>
              <div class="card-tech">Design / Graphique</div>
            </li>
          </div>

          <div>
            <li class="projects-card">
              <div class="card-header">
                <div class="folder-icon">
                  <svg
                    class="MuiSvgIcon-root"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    style="font-size: 35px"
                  >
                    <path
                      d="M20 6h-8l-1.41-1.41C10.21 4.21 9.7 4 9.17 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-1 12H5c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1z"
                    ></path>
                  </svg>
                </div>
                <span class="external-links">
                  <svg
                    class="MuiSvgIcon-root"
                    @click="carouselRSNews = true"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    style="font-size: 25px; color: var(--lightest-slate)"
                  >
                    <path
                      d="M19 4H5c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h4v-2H5V8h14v10h-4v2h4c1.1 0 2-.9 2-2V6c0-1.1-.89-2-2-2zm-7 6l-4 4h3v6h2v-6h3l-4-4z"
                    ></path>
                  </svg>
                  <q-dialog v-model="carouselRSNews">
                    <q-carousel
                      transition-prev="slide-right"
                      transition-next="slide-left"
                      swipeable
                      animated
                      v-model="slide"
                      control-color="primary"
                      navigation
                      padding
                      height="800px"
                      max-width="100%"
                      class="bg-white shadow-1 rounded-borders"
                    >
                      <q-carousel-slide
                        :name="1"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/Rs.png"
                          alt="Réseaux sociaux 1"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="2"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/RsJPO.png"
                          alt="Réseaux sociaux 2"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="3"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/RsPorteouverte.png"
                          alt="Réseaux sociaux 3"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="4"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/RsRejoindre.png"
                          alt="Réseaux sociaux 4"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="5"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/RsSalon.png"
                          alt="Réseaux sociaux 5"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="6"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/News1.png"
                          alt="Newsletter page 1"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="7"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/News2.png"
                          alt="Newsletter Page 2"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="8"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/News3.png"
                          alt="Newsletter Page 3"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="9"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/News4.png"
                          alt="Newsletter Page 4"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="10"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/News5.png"
                          alt="Newsletter Page 5"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                    </q-carousel>
                  </q-dialog>
                </span>
              </div>
              <div class="card-title" style="font-size: 15px; color: grey">
                B&amp;C Formation
              </div>
              <div class="card-title">
                Création de poste sur les réseaux sociaux et de newsletter
              </div>
              <div class="card-desc">
                Chez B&amp;C Formation, j’ai penser, créer et plannifié des
                postes sur les réseaux sociaux et développé une fonctionnalité
                de newsletters en codant sur le site WordPress, permettant de
                tenir informés employés et collaborateurs des actualités de
                l’entreprise.
              </div>
              <div class="card-tech">Design / Graphique</div>
            </li>
          </div>

          <div>
            <li class="projects-card">
              <div class="card-header">
                <div class="folder-icon">
                  <svg
                    class="MuiSvgIcon-root"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    style="font-size: 35px"
                  >
                    <path
                      d="M20 6h-8l-1.41-1.41C10.21 4.21 9.7 4 9.17 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-1 12H5c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1z"
                    ></path>
                  </svg>
                </div>
                <span class="external-links">
                  <a
                    class="open-icon"
                    target="_blank"
                    href="https://www.local-et-bocal.fr/"
                  >
                    <svg
                      class="MuiSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      style="font-size: 25px; color: var(--lightest-slate)"
                    >
                      <path
                        d="M19 4H5c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h4v-2H5V8h14v10h-4v2h4c1.1 0 2-.9 2-2V6c0-1.1-.89-2-2-2zm-7 6l-4 4h3v6h2v-6h3l-4-4z"
                      ></path>
                    </svg>
                  </a>
                </span>
              </div>
              <div class="card-title" style="font-size: 15px; color: grey">
                La Fabrique de Com
              </div>
              <div class="card-title">
                Aide à la conception du site Local et Bocal
              </div>
              <div class="card-desc">
                J’ai collaboré avec une graphiste et un illustrateur pour
                enrichir le site de Local et Bocal, l’un de nos clients. J’ai
                également codé certaines fonctionnalités pour éviter
                l’utilisation de plugins payants.
              </div>
              <div class="card-tech">Wordpress, Php, Webdesign</div>
            </li>
          </div>

          <div>
            <li class="projects-card">
              <div class="card-header">
                <div class="folder-icon">
                  <svg
                    class="MuiSvgIcon-root"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    style="font-size: 35px"
                  >
                    <path
                      d="M20 6h-8l-1.41-1.41C10.21 4.21 9.7 4 9.17 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-1 12H5c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1z"
                    ></path>
                  </svg>
                </div>
                <span class="external-links">
                  <a
                    class="open-icon"
                    target="_blank"
                    href="https://www.envie.org/"
                  >
                    <svg
                      class="MuiSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      style="font-size: 25px; color: var(--lightest-slate)"
                    >
                      <path
                        d="M19 4H5c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h4v-2H5V8h14v10h-4v2h4c1.1 0 2-.9 2-2V6c0-1.1-.89-2-2-2zm-7 6l-4 4h3v6h2v-6h3l-4-4z"
                      ></path>
                    </svg>
                  </a>
                </span>
              </div>
              <div class="card-title" style="font-size: 15px; color: grey">
                La Fabrique de Com
              </div>
              <div class="card-title">Aide à la conception du site Envie</div>
              <div class="card-desc">
                J’ai collaboré avec une graphiste et un illustrateur pour
                enrichir le site d'Envie, l’un de nos clients. J’ai également
                développé une barre de recherche permettant de trouver des
                téléphones dans le catalogue.
              </div>
              <div class="card-tech">Wordpress, Php, Webdesign</div>
            </li>
          </div>

          <div>
            <li class="projects-card">
              <div class="card-header">
                <div class="folder-icon">
                  <svg
                    class="MuiSvgIcon-root"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    style="font-size: 35px"
                  >
                    <path
                      d="M20 6h-8l-1.41-1.41C10.21 4.21 9.7 4 9.17 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-1 12H5c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1z"
                    ></path>
                  </svg>
                </div>
                <span class="external-links">
                  <svg
                    class="MuiSvgIcon-root"
                    @click="carousel = true"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    style="font-size: 25px; color: var(--lightest-slate)"
                  >
                    <path
                      d="M19 4H5c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h4v-2H5V8h14v10h-4v2h4c1.1 0 2-.9 2-2V6c0-1.1-.89-2-2-2zm-7 6l-4 4h3v6h2v-6h3l-4-4z"
                    ></path>
                  </svg>
                  <q-dialog v-model="carousel">
                    <q-carousel
                      transition-prev="slide-right"
                      transition-next="slide-left"
                      swipeable
                      animated
                      v-model="slide"
                      control-color="primary"
                      navigation
                      padding
                      height="500px"
                      max-width="100%"
                      class="bg-white shadow-1 rounded-borders"
                    >
                      <q-carousel-slide
                        :name="1"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/extra-terrestre-int.png"
                          alt="extra-terrestre-int"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="2"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/Forêt-ext.png"
                          alt="Forêt-ext"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="3"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/Foret-int.png"
                          alt="Foret-int"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="4"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/monde-merveilleur-exterieure.png"
                          alt="monde-merveilleur-exterieure"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="5"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/monde-merveilleurx-int.png"
                          alt="monde-merveilleur-int"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="6"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/monde-montagne-int.png"
                          alt="monde-montagne-int"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="7"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/montagne-ext.png"
                          alt="montagne-ext"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="8"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/extra-terrestre-ext.png"
                          alt="extra-terrestre-ext"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="9"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/ville-ext.png"
                          alt="ville-ext"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="10"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/Ville-int.png"
                          alt="Ville-int"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                    </q-carousel>
                  </q-dialog>
                </span>
              </div>
              <div class="card-title" style="font-size: 15px; color: grey">
                Cood
              </div>
              <div class="card-title">Création d'univers de jeux</div>
              <div class="card-desc">
                Création d'univers de jeux en collaboration avec un directeur
                artistique. L’objectif était d’assembler les assets créés afin
                de concevoir un univers intégré dans le Cood Studio pour les
                joueurs.
              </div>
              <div class="card-tech">Design / Graphique</div>
            </li>
          </div>

          <div>
            <li class="projects-card">
              <div class="card-header">
                <div class="folder-icon">
                  <svg
                    class="MuiSvgIcon-root"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    style="font-size: 35px"
                  >
                    <path
                      d="M20 6h-8l-1.41-1.41C10.21 4.21 9.7 4 9.17 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-1 12H5c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1z"
                    ></path>
                  </svg>
                </div>
                <span class="external-links">
                  <svg
                    class="MuiSvgIcon-root"
                    @click="carouselCood = true"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    style="font-size: 25px; color: var(--lightest-slate)"
                  >
                    <path
                      d="M19 4H5c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h4v-2H5V8h14v10h-4v2h4c1.1 0 2-.9 2-2V6c0-1.1-.89-2-2-2zm-7 6l-4 4h3v6h2v-6h3l-4-4z"
                    ></path>
                  </svg>
                  <q-dialog v-model="carouselCood">
                    <q-carousel
                      transition-prev="slide-right"
                      transition-next="slide-left"
                      swipeable
                      animated
                      v-model="slide"
                      control-color="primary"
                      navigation
                      padding
                      height="800px"
                      max-width="100%"
                      class="bg-white shadow-1 rounded-borders"
                    >
                      <q-carousel-slide
                        :name="1"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/RefonteCood.png"
                          alt="Refonte avane"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="2"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/RefonteApres.png"
                          alt="Refonte du site apres"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="3"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/RefonteAccueil.png"
                          alt="Refonte du site quelques example"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="4"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/RefonteCood2.png"
                          alt="Refonte du site quelques example"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="5"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/RefonteCharte.png"
                          alt="Charte graphique du site"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                    </q-carousel>
                  </q-dialog>
                </span>
              </div>
              <div class="card-title" style="font-size: 15px; color: grey">
                Cood
              </div>
              <div class="card-title">Refonte du site Cood</div>
              <div class="card-desc">
                Refonte du site officiel : Clarification des objectifs; Cadrage
                du projet; Planification de l'arborescence; Référentiel des
                exigences fontionnelles; Référencement SEO; Wireframming/
                Maquettes; Création de contenu; Ui Design; Méthode agile.
              </div>
              <div class="card-tech">
                CMS (Wordpress), HTML/CSS, WebDesign, InDesign
              </div>
            </li>
          </div>

          <div>
            <li class="projects-card">
              <div class="card-header">
                <div class="folder-icon">
                  <svg
                    class="MuiSvgIcon-root"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    style="font-size: 35px"
                  >
                    <path
                      d="M20 6h-8l-1.41-1.41C10.21 4.21 9.7 4 9.17 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-1 12H5c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1z"
                    ></path>
                  </svg>
                </div>
                <span class="external-links">
                  <svg
                    class="MuiSvgIcon-root"
                    @click="carouselRsCood = true"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    style="font-size: 25px; color: var(--lightest-slate)"
                  >
                    <path
                      d="M19 4H5c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h4v-2H5V8h14v10h-4v2h4c1.1 0 2-.9 2-2V6c0-1.1-.89-2-2-2zm-7 6l-4 4h3v6h2v-6h3l-4-4z"
                    ></path>
                  </svg>
                  <q-dialog v-model="carouselRsCood">
                    <q-carousel
                      transition-prev="slide-right"
                      transition-next="slide-left"
                      swipeable
                      animated
                      v-model="slide"
                      control-color="primary"
                      navigation
                      padding
                      height="800px"
                      max-width="100%"
                      class="bg-white shadow-1 rounded-borders"
                    >
                      <q-carousel-slide
                        :name="1"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/RsCood.png"
                          alt="Reseaux sociaux Cood"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="2"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/RsCood1.png"
                          alt="Reseaux sociaux Cood"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                    </q-carousel>
                  </q-dialog>
                </span>
              </div>
              <div class="card-title" style="font-size: 15px; color: grey">
                Cood
              </div>
              <div class="card-title">Community Management</div>
              <div class="card-desc">
                Communication hebdomadaire : Création de plusieurs mini-jeux
                pour les réseaux sociaux, comme le jeu des 7 erreurs, afin
                d'amuser nos joueurs. Ces posts étaient publiés exclusivement le
                samedi et dimanche.
              </div>
              <div class="card-tech">Canva, Design</div>
            </li>
          </div>

          <div>
            <li class="projects-card">
              <div class="card-header">
                <div class="folder-icon">
                  <svg
                    class="MuiSvgIcon-root"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    style="font-size: 35px"
                  >
                    <path
                      d="M20 6h-8l-1.41-1.41C10.21 4.21 9.7 4 9.17 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-1 12H5c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1z"
                    ></path>
                  </svg>
                </div>
                <span class="external-links">
                  <svg
                    class="MuiSvgIcon-root"
                    @click="carouselWCSDeliv = true"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    style="font-size: 25px; color: var(--lightest-slate)"
                  >
                    <path
                      d="M19 4H5c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h4v-2H5V8h14v10h-4v2h4c1.1 0 2-.9 2-2V6c0-1.1-.89-2-2-2zm-7 6l-4 4h3v6h2v-6h3l-4-4z"
                    ></path>
                  </svg>
                  <q-dialog v-model="carouselWCSDeliv">
                    <q-carousel
                      transition-prev="slide-right"
                      transition-next="slide-left"
                      swipeable
                      animated
                      v-model="slide"
                      control-color="primary"
                      navigation
                      padding
                      height="800px"
                      max-width="100%"
                      class="bg-white shadow-1 rounded-borders"
                    >
                      <q-carousel-slide
                        :name="1"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/Deliv1.png"
                          alt="Delivgreen site capture 1"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                      <q-carousel-slide
                        :name="2"
                        class="column no-wrap flex-center"
                      >
                        <img
                          src="../assets/Deliv2.png"
                          alt="Delivgreen site capture 2"
                          style="width: 100%; height: 100%"
                        />
                      </q-carousel-slide>
                    </q-carousel>
                  </q-dialog>
                </span>
              </div>
              <div class="card-title" style="font-size: 15px; color: grey">
                Wild Code School
              </div>
              <div class="card-title">Projet Déliv'green</div>
              <div class="card-desc">
                Application mettant en relation des livreurs particuliers et
                professionnels. Une application où l'on peut être coursier et un
                simple client souhaitant faire livrer son colis !
              </div>
              <div class="card-tech">
                JavaScript, React.js, Bootstrap, PhpMyAdmin, Sql
              </div>
            </li>
          </div>

          <div>
            <li class="projects-card">
              <div class="card-header">
                <div class="folder-icon">
                  <svg
                    class="MuiSvgIcon-root"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    style="font-size: 35px"
                  >
                    <path
                      d="M20 6h-8l-1.41-1.41C10.21 4.21 9.7 4 9.17 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-1 12H5c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1z"
                    ></path>
                  </svg>
                </div>
                <span class="external-links">
                  <a
                    class="open-icon"
                    target="_blank"
                    href="https://pokemon-battle.netlify.app/"
                  >
                    <svg
                      class="MuiSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      style="font-size: 25px; color: var(--lightest-slate)"
                    >
                      <path
                        d="M19 4H5c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h4v-2H5V8h14v10h-4v2h4c1.1 0 2-.9 2-2V6c0-1.1-.89-2-2-2zm-7 6l-4 4h3v6h2v-6h3l-4-4z"
                      ></path>
                    </svg>
                  </a>
                </span>
              </div>
              <div class="card-title" style="font-size: 15px; color: grey">
                Wild Code School
              </div>
              <div class="card-title">Projet PokéBattle</div>
              <div class="card-desc">
                Petit jeu de combat dans le thème "Pokemon". Le jeu met en scène
                un combat avec un personnage choisi aléatoirement.
              </div>
              <div class="card-tech">JavaScript, React.js, PhpMyAdmin, Sql</div>
            </li>
          </div>

          <div>
            <li class="projects-card">
              <div class="card-header">
                <div class="folder-icon">
                  <svg
                    class="MuiSvgIcon-root"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    style="font-size: 35px"
                  >
                    <path
                      d="M20 6h-8l-1.41-1.41C10.21 4.21 9.7 4 9.17 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-1 12H5c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1z"
                    ></path>
                  </svg>
                </div>
                <span class="external-links">
                  <a
                    class="open-icon"
                    target="_blank"
                    href="https:tapisgrimaud.com"
                  >
                    <svg
                      class="MuiSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      style="font-size: 25px; color: var(--lightest-slate)"
                    >
                      <path
                        d="M19 4H5c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h4v-2H5V8h14v10h-4v2h4c1.1 0 2-.9 2-2V6c0-1.1-.89-2-2-2zm-7 6l-4 4h3v6h2v-6h3l-4-4z"
                      ></path>
                    </svg>
                  </a>
                </span>
              </div>
              <div class="card-title">Projet e-commerce</div>
              <div class="card-desc">
                Projet e-commerce de tapisgrimaud.com et trousselite.com.<br /><br />
                Les sites offrent également une expérience de navigation fluide,
                une sécurité de paiement optimale et une livraison rapide et
                fiable.
              </div>
              <div class="card-tech">e-commerce / WebMarketing</div>
            </li>
          </div>
        </ul>
      </div>
    </div>

    <div class="footer">
      <p>N'hésitez pas à visiter la version mobile !</p>

      <div>Construit et réalisé par Sibel Koç</div>
      <div>Tous droits réservés ©</div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted } from "vue";
import { ref } from "vue";

export default {
  name: "Web-page",
  props: {},
  setup() {
    const dialog = ref(false);

    onMounted(() => {
      document.addEventListener("scroll", animateIfInView);
    });
    onUnmounted(() => {
      document.removeEventListener("scroll", animateIfInView);
    });

    function animateIfInView() {
      console.log("je rentre");
      var monTableau = document.getElementsByClassName("fade-in-section");
      for (let i = 0; i < monTableau.length; i++) {
        const element = monTableau[i];
        if (isElementInViewport(element)) {
          element.classList.add("is-visible");
        } else {
          // (Optional) Fade out when out of view
          element.classList.remove("is-visible");
        }
      }

      function isElementInViewport(el) {
        var rect = el.getBoundingClientRect();

        return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <=
            (window.innerHeight ||
              document.documentElement
                .clientHeight) /*or $(window).height() */ &&
          rect.right <=
            (window.innerWidth ||
              document.documentElement.clientWidth) /*or $(window).width() */
        );
      }
    }
    return {
      tab: ref("BC_Formation"),
      splitterModel: ref(20),
      carousel: ref(false),
      carouselFlyers: ref(false),
      carouselKit: ref(false),
      carouselId: ref(false),
      carouselRSNews: ref(false),
      carouselCood: ref(false),
      carouselRsCood: ref(false),
      carouselWCSDeliv: ref(false),
      slide: ref(1),
      dialog,
      small: ref(false),
      small1: ref(false),
      persistent: ref(false),

      open() {
        dialog.value = true;
      },
    };
  },
};
</script>

<style scoped>
.content {
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

/* home--------------------- */
#home {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10%;
  padding-top: 3%;
  min-height: 100vh;
}
a #home {
  color: #64ffda;
}

.wrapper {
  font-size: 36px;
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  justify-content: left;
  margin-top: 80px;
  /* width: 100%; */
}

.premierSection {
  /* margin-top:80px; */
  margin-right: 20px;
  display: flex;
  justify-content: flex-start;
  padding: 15px; /* Ajoutez un padding si nécessaire */
  box-sizing: border-box; /* Inclut la largeur des bordures et du padding dans le calcul de la largeur */
}

/* .col1-wrapper{
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
} */

.col2-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

/* .content-sibelkreation {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "made_tommy", sans-serif;
  font-size: calc(30px + 4vw);
  color: #94745b;
  text-shadow: 0 0 2px;
  align-items: flex-end;
  font-weight: 300;
  display: flex;
}

.content-sibelkreation .ok {
  position: absolute;
}

.content-sibelkreation .ok:nth-child(1) {
  color: transparent;
  -webkit-text-stroke: 2px #94745b;
}

.content-sibelkreation .ok:nth-child(2) {
  color: #94745b;
  animation: animate 4s ease-in-out infinite;
} */

.text-home {
  font-family: "made_tommy";
  color: var(--slate);
  max-width: 60%;
  font-size: 18px;
  text-align: justify;
  margin: 0;
  width: 80%;
  padding-bottom: 20px;
  margin-top: 20px;
  font-weight: 500;
}

.intro-contact {
  font-weight: bolder;
  padding: 10px 30px;
  cursor: pointer;
  border: 1px solid #40cfef;
  border-radius: 4px;
  font-size: 15px;
  align-content: center;
  justify-content: center;
}

/* about---------------------------- */
.q-pa-sm {
  padding: 8px 8px;
  width: 50%;
  display: flex;
}
.display {
  display: flex;
  width: 100%;
}

#about {
  padding-left: 10%;
  padding-right: 25%;
  min-height: 80vh;
}
.fade-in-section {
  opacity: 0;
  -webkit-transform: translate(0, 50px);
  -moz-transform: translate(0, 50px);
  -ms-transform: translate(0, 50px);
  transform: translate(0, 50px);
  -webkit-transition: opacity 0.6s 0.1s, transform 0.6s ease;
  transition: opacity 0.6s 0.1s, transform 0.6s ease;
  transition-delay: 0ms;
  color: #40cfef;
}

.fade-in-section1 {
  color: #40cfef;
}

.fade-in-section.is-visible {
  opacity: 1 !important;
  -webkit-transform: translate(0, 0) !important;
  -moz-transform: translate(0, 0) !important;
  -ms-transform: translate(0, 0) !important;
  transform: translate(0, 0) !important;
}

.section-header {
  display: flex;
  padding-bottom: 42px;
  width: 100vw;
}
.section-header:after {
  content: "";
  display: block;
  width: 300px;
  margin-top: 39px;
  height: 1px;
  margin-left: 20px;
  background-color: #6185b8;
}
.section-title {
  font-family: "made_tommy";
  color: #fff;
  font-size: 46px;
  font-weight: 700;
  text-shadow: 0 0.5px 2px, 0 0 4px, 0 0 2em #0a102f, 0 0 0.1em #0a102f,
    0 0 0.1em #0a102f, 0 8px 0px #0a102f;
}
.about-content {
  display: flex;
  flex-direction: row;
}
.about-description {
  font-family: "made_tommy";

  color: var(--slate);
  max-width: 600px;
  font-size: 18px;
  text-align: justify;
  margin: 0;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.about-description ul {
  padding-top: 18px;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(2, minmax(300px, 20px));
}

ul {
  list-style: none;
  margin-left: -40px;
}

.about-description li {
  position: relative;
  padding-left: 20px;
  font-size: 18px;
  color: var(--slate);
}
.about-description li:before {
  content: "▹ ";
  color: var(--green-bright);
  position: absolute;
  left: 0;
}
/* .about-image {
  padding-left: 40px;
} */
/* .about-image img {
  max-width: 300px;
  height: auto;
  border-radius: 10px;
  margin-left: 30px;
  box-shadow: 6px 13px 23px -5px rgb(0 0 0 / 46%);
  border: none;
} */
/* Experiences---------------------------- */

#experience {
  padding-left: 10%;
  padding-right: 25%;
  min-height: 70vh;
  padding-top: 10%;
}

.q-tab-panels {
  background: transparent;
}

.q-tab-panel {
  background-color: transparent !important;
  overflow: hidden;
}
.text-tab {
  text-align: start;
}
.job-description {
  max-width: 100%;
  font-family: "made_tommy", sans-serif;
  font-size: 20px;
  background-color: transparent;
}
.job-description li {
  position: relative;
  padding-left: 30px;
  padding-bottom: 16px;
  font-size: 15px;
  text-align: justify;
}
.job-description li:before {
  content: "▹ ";
  position: absolute;
  left: 0;
}
ul {
  list-style: none;
  margin-left: -40px;
}
.title-panel {
  font-family: "made_tommy";
  color: #fff;
  font-weight: 700;
  text-align: start;
  justify-content: left;
}

/* Créations---------------------------- */

#projects {
  padding-left: 10%;
  padding-right: 25%;
  min-height: 80vh;
  padding-top: 6%;
}
.projects-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  grid-gap: 10px;
}
.projects-card {
  cursor: default;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  padding: 2rem 1.75rem;
  background-color: #210533;
  border-radius: 16px;
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header {
  margin-top: -20px;
  display: flex;
  padding: 1.25em 0 !important;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: none !important;
}
.card-title {
  font-family: "made_tommy";
  color: #40cfef;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}
.folder-icon {
  color: #40cfef;
}
.github-icon {
  margin-top: 6px;
}
.open-icon {
  margin-left: 10px;
}
.card-desc {
  font-family: "made_tommy";
  font-size: 15px;
  font-weight: 300;
}
.card-tech {
  font-size: 14px;
  font-family: "made_tommy";
  font-weight: 300;
  margin-top: 20px;
  color: #40cfef;
}

/* menu--------------------- */
.sidebar-nav {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  right: 0;
  padding-right: 5%;
  bottom: 10%;
  background-color: transparent;
}

.sidebar-links {
  display: flex;
  flex-direction: column;
  text-align: right;
  font-family: "made_tommy";

  letter-spacing: 0em;
  line-height: 1.6em;
  font-size: 16px;
  padding: 0.2em;
  font-weight: bold;
}

a {
  text-decoration: none !important;
  color: var(--green-bright) !important;
}

a:hover {
  color: var(--lightest-slate) !important;
}

.sidebar-logos {
  padding-top: 42px;
  width: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 8px;
  color: #40cfef;
}

.MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

.q-carousel {
  max-width: 100%;
}
.footer {
  font-family: "made_tommy";
  color: var(--slate);
  text-align: center;
  width: 100%;
  font-size: 16px;
  padding-bottom: 40px;

  align-items: center;
  padding-top: 50px;
}

@keyframes animate {
  0%,
  100% {
    clip-path: polygon(
      0% 45%,
      16% 44%,
      33% 50%,
      54% 60%,
      70% 61%,
      84% 59%,
      100% 52%,
      100% 100%,
      0% 100%
    );
  }

  50% {
    clip-path: polygon(
      0% 60%,
      15% 65%,
      34% 66%,
      51% 62%,
      67% 50%,
      84% 45%,
      100% 46%,
      100% 100%,
      0% 100%
    );
  }
}
</style>

<style lang="scss">
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  scroll-behavior: smooth;
}

// /*BOUTON TELECHARGMENT PROGRAMME**/
// :root {
//   /* color type A */
//   --line_color: #555555;
//   --back_color: #d4af92;

//   /* color type B */
//   /* --line_color : #1b1919 ;
//     --back_color : #E9ECFF  ; */

//   /* color type C */
//   /* --line_color : #00135C ;
//     --back_color : #DEFFFA  ; */
// }

// .button {
//   position: relative;
//   z-index: 0;
//   justify-content: left;
//   width: 450px;
//   height: 56px;
//   text-decoration: none;
//   font-size: 14px;
//   font-weight: bold;
//   color: var(--line_color);
//   letter-spacing: 2px;
//   transition: all 0.3s ease;
//   scale: 0.9;
// }
// .button__text {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   height: 100%;
// }
// .button::before,
// .button::after,
// .button__text::before,
// .button__text::after {
//   content: "";
//   position: absolute;
//   height: 3px;
//   border-radius: 2px;
//   background: var(--line_color);
//   transition: all 0.5s ease;
// }
// .button::before {
//   top: 0;
//   left: 54px;
//   width: calc(100% - 56px * 2 - 16px);
// }
// .button::after {
//   top: 0;
//   right: 54px;
//   width: 8px;
// }
// .button__text::before {
//   bottom: 0;
//   right: 54px;
//   width: calc(100% - 56px * 2 - 16px);
// }
// .button__text::after {
//   bottom: 0;
//   left: 54px;
//   width: 8px;
// }
// .button__line {
//   position: absolute;
//   top: 0;
//   width: 56px;
//   height: 100%;
//   overflow: hidden;
// }
// .button__line::before {
//   content: "";
//   position: absolute;
//   top: 0;
//   width: 150%;
//   height: 100%;
//   box-sizing: border-box;
//   border-radius: 300px;
//   border: solid 3px var(--line_color);
// }
// .button__line:nth-child(1),
// .button__line:nth-child(1)::before {
//   left: 0;
// }
// .button__line:nth-child(2),
// .button__line:nth-child(2)::before {
//   right: 0;
// }
// .button:hover {
//   letter-spacing: 6px;
// }
// .button:hover::before,
// .button:hover .button__text::before {
//   width: 8px;
// }
// .button:hover::after,
// .button:hover .button__text::after {
//   width: calc(100% - 56px * 2 - 16px);
// }
// .button__drow1,
// .button__drow2 {
//   position: absolute;
//   z-index: -1;
//   border-radius: 16px;
//   transform-origin: 16px 16px;
// }
// .button__drow1 {
//   top: -16px;
//   left: 40px;
//   width: 32px;
//   height: 0;
//   transform: rotate(30deg);
// }
// .button__drow2 {
//   top: 44px;
//   left: 77px;
//   width: 32px;
//   height: 0;
//   transform: rotate(-127deg);
// }
// .button__drow1::before,
// .button__drow1::after,
// .button__drow2::before,
// .button__drow2::after {
//   content: "";
//   position: absolute;
// }
// .button__drow1::before {
//   bottom: 0;
//   left: 0;
//   width: 0;
//   height: 32px;
//   border-radius: 16px;
//   transform-origin: 16px 16px;
//   transform: rotate(-60deg);
// }
// .button__drow1::after {
//   top: -10px;
//   left: 45px;
//   width: 0;
//   height: 32px;
//   border-radius: 16px;
//   transform-origin: 16px 16px;
//   transform: rotate(69deg);
// }
// .button__drow2::before {
//   bottom: 0;
//   left: 0;
//   width: 0;
//   height: 32px;
//   border-radius: 16px;
//   transform-origin: 16px 16px;
//   transform: rotate(-146deg);
// }
// .button__drow2::after {
//   bottom: 26px;
//   left: -40px;
//   width: 0;
//   height: 32px;
//   border-radius: 16px;
//   transform-origin: 16px 16px;
//   transform: rotate(-262deg);
// }
// .button__drow1,
// .button__drow1::before,
// .button__drow1::after,
// .button__drow2,
// .button__drow2::before,
// .button__drow2::after {
//   background: var(--back_color);
// }
// .button:hover .button__drow1 {
//   animation: drow1 ease-in 0.06s;
//   animation-fill-mode: forwards;
// }
// .button:hover .button__drow1::before {
//   animation: drow2 linear 0.08s 0.06s;
//   animation-fill-mode: forwards;
// }
// .button:hover .button__drow1::after {
//   animation: drow3 linear 0.03s 0.14s;
//   animation-fill-mode: forwards;
// }
// .button:hover .button__drow2 {
//   animation: drow4 linear 0.06s 0.2s;
//   animation-fill-mode: forwards;
// }
// .button:hover .button__drow2::before {
//   animation: drow3 linear 0.03s 0.26s;
//   animation-fill-mode: forwards;
// }
// .button:hover .button__drow2::after {
//   animation: drow5 linear 0.06s 0.32s;
//   animation-fill-mode: forwards;
// }
// @keyframes drow1 {
//   0% {
//     height: 0;
//   }
//   100% {
//     height: 100px;
//   }
// }
// @keyframes drow2 {
//   0% {
//     width: 0;
//     opacity: 0;
//   }
//   10% {
//     opacity: 0;
//   }
//   11% {
//     opacity: 1;
//   }
//   100% {
//     width: 120px;
//   }
// }
// @keyframes drow3 {
//   0% {
//     width: 0;
//   }
//   100% {
//     width: 80px;
//   }
// }
// @keyframes drow4 {
//   0% {
//     height: 0;
//   }
//   100% {
//     height: 120px;
//   }
// }
// @keyframes drow5 {
//   0% {
//     width: 0;
//   }
//   100% {
//     width: 124px;
//   }
// }

/* 本体には関係ないスタイル */
.container {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
}

/**MOUSE SCORLLDOWN */
.mouse {
  //   /*fullscree center but no horizontal scrollbar*/
  display: flex;
  height: 20vh;
  width: 80vw;
  max-width: 100vw;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  overflow-x: hidden;
}

/* copy from here */
.scrolldown {
  --color: #40cfef;
  --sizeX: 30px;
  --sizeY: 50px;
  position: relative;
  width: var(--sizeX);
  height: var(--sizeY);
  margin-left: var(sizeX / 2);
  border: calc(var(--sizeX) / 10) solid var(--color);
  border-radius: 50px;
  box-sizing: border-box;
  margin-bottom: 16px;
}

.scrolldown::before {
  content: "";
  position: absolute;
  bottom: 30px;
  left: 50%;
  width: 6px;
  height: 6px;
  margin-left: -3px;
  background-color: var(--color);
  border-radius: 100%;
  animation: scrolldown-anim 2s infinite;
  box-sizing: border-box;
  box-shadow: 0px -5px 3px 1px #ffffff66;
}

@keyframes scrolldown-anim {
  0% {
    opacity: 0;
    height: 6px;
  }
  40% {
    opacity: 1;
    height: 10px;
  }
  80% {
    transform: translate(0, 20px);
    height: 10px;
    opacity: 0;
  }
  100% {
    height: 3px;
    opacity: 0;
  }
}
.chevrons {
  padding: 6px 0 0 0;
  margin-left: -3px;
  margin-top: 48px;
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.chevrondown {
  margin-top: -6px;
  position: relative;
  border: solid var(--color);
  border-width: 0 3px 3px 0;
  display: inline-block;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
}
.chevrondown:nth-child(odd) {
  animation: pulse 500ms ease infinite alternate;
}
.chevrondown:nth-child(even) {
  animation: pulse 500ms ease infinite alternate 250ms;
}
@keyframes pulse {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}
/*copy until here */

@import url(//fonts.googleapis.com/css?family=Vibur);

.logo {
  text-align: center;
  // width: 10%;
  // height: 250px;
  margin: auto;
  // position: absolute;
  top: 22.5%;
  user-select: none;
  animation: fadein 2s;
}

.logo b {
  font: 400 14vh "Vibur";
  color: #fee;
  // text-shadow: 0 -40px 100px, 0 0 2px, 0 0 1em #0a102f, 0 0 0.5em #0a102f, 0 0 0.1em #0a102f, 0 10px 3px #0a102f;
  text-shadow: 0 -20px 100px, 0 0 8px, 0 0 1em #0a102f, 0 0 0.2em #0a102f,
    0 0 0.1em #0a102f, 0 1px 3px #0a102f;
}
.logo b span {
  animation: blink linear infinite 2s;
}
.logo b span:nth-of-type(2) {
  animation: blink linear infinite 3s;
}
@keyframes blink {
  78% {
    color: inherit;
    text-shadow: inherit;
  }
  79% {
    color: #333;
  }
  80% {
    text-shadow: none;
  }
  81% {
    color: inherit;
    text-shadow: inherit;
  }
  82% {
    color: #333;
    text-shadow: none;
  }
  83% {
    color: inherit;
    text-shadow: inherit;
  }
  92% {
    color: #333;
    text-shadow: none;
  }
  92.5% {
    color: inherit;
    text-shadow: inherit;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

/* New Title */
// #btn-twtr{
//   clear:both;
//   color:#fff;
//   border:2px solid;
//   border-radius:3px;
//   text-align:center;
//   text-decoration:none;
//   display:block;
//   font-family:sans-serif;
//   font-size:14px;
//   width:13em;
//   padding:5px 10px;
//   font-weight:600;
//   position:absolute;
//   bottom:20px;
//   left:0;
//   right:0;
//   margin:0 auto;
//   background:rgba(0,0,0,0.2);
//   box-shadow:0 0 0px 3px rgba(0,0,0,0.2);
//   opacity:0.4
// }#btn-twtr:hover{color:#fff;opacity:1}

/*planet*/
$m-01: #170329;
$m-02: #364c6b;
$m-03: #506f9c;
$m-04: #506f9c;

@import url("https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap");

// .contentP {
//    width: 300px;
//    height: 300px;
//    position: relative;
//    display: flex;
//    justify-content: center;
//    align-items: center;

//    .planet {
//       width: 65%;
//       height: 65%;
//       background-color: $m-02;
//       border-radius: 100%;
//       position: absolute;
//       display: flex;
//       align-items: center;
//       transform-origin: center center;
//       box-shadow: inset 2px -10px 0px rgba(0, 0, 0, 0.1);

//   box-shadow: 0 -20px 100px, 0 0 8px, 0 0 1em #0a102f, 0 0 0.2em #0a102f, 0 0 0.1em #0a102f, 0 1px 3px #0a102f;

//       color:#fff;

//       animation: planet 5s ease infinite alternate;

//       @keyframes planet {
//          0% {
//             transform: rotate(10deg);
//          }

//          100% {
//             transform: rotate(-10deg);
//          }
//       }

//       /* planet ring */
//       .ring {
//          position: absolute;
//          width: 300px;
//          height: 300px;
//          border-radius: 100%;
//          background-color: $m-04;
//          display: flex;
//          align-items: center;
//          justify-content: center;
//          transform-origin: 33% center;
//          box-shadow: 2px -10px 0px rgba(0, 0, 0, 0.1),
//             inset -5px -10px 0px rgba(0, 0, 0, 0.1);
//            box-shadow: 0 -10px 20px, 0 0 7px, 0 0 5em #0a102f, 0 0 0.5em #0a102f, 0 0 0.1em #0a102f, 0 18px 20px #0a102f;

//          animation: ring 3s ease infinite;

//          @keyframes ring {
//             0% {
//                transform: rotateX(110deg) rotateZ(0deg) translate(-50px, 5px);
//             }

//             100% {
//                transform: rotateX(110deg) rotateZ(360deg) translate(-50px, 5px);
//             }
//          }

//          /* small ball */
//          &:before {
//             content: "";
//             position: absolute;
//             width: 10px;
//             height: 30px;
//             border-radius: 100%;
//             background-color: $m-03;
//             z-index: 2;
//             left: calc(0px - 5px);
//             box-shadow: inset -3px 3px 0px rgba(0, 0, 0, 0.2);
//          }

//          /* inner ring */
//          &:after {
//             content: "";
//             position: absolute;
//             width: 240px;
//             height: 240px;
//             border-radius: 100%;
//             background-color: $m-03;
//             box-shadow: inset 2px -10px 0px rgba(0, 0, 0, 0.1);
//          }
//       }

//       /* to cover the back of the ring */
//       .cover-ring {
//          position: absolute;
//          width: 100%;
//          height: 50%;
//          border-bottom-left-radius: 80%;
//          border-bottom-right-radius: 80%;
//          border-top-left-radius: 100px;
//          border-top-right-radius: 100px;
//          transform: translate(0px, -17px);
//          background-color: $m-02;
//          z-index: 2;
//          box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.1);
//       }

//       /* planet spots */
//       .spots {
//          width: 100%;
//          height: 100%;
//          display: flex;
//          align-items: center;
//          justify-content: center;
//          position: absolute;
//          z-index: 2;

//          span {
//             width: 30px;
//             height: 30px;
//             background-color: $m-01;
//             position: absolute;
//             border-radius: 100%;
//             box-shadow: inset -2px 3px 0px rgba(0, 0, 0, 0.3);
//             animation: dots 5s ease infinite alternate;

//             @keyframes dots {
//                0% {
//                   box-shadow: inset -3px 3px 0px rgba(0, 0, 0, 0.3);

//                }
//                100% {
//                   box-shadow: inset 3px 3px 0px rgba(0, 0, 0, 0.3);

//                }
//             }

//             &:nth-child(1) {
//                top: 20px;
//                right: 50px;
//             }

//             &:nth-child(2) {
//                top: 40px;
//                left: 50px;
//                width: 15px;
//                height: 15px;
//             }

//             &:nth-child(3) {
//                top: 80px;
//                left: 20px;
//                width: 25px;
//                height: 25px;
//             }

//             &:nth-child(4) {
//                top: 80px;
//                left: 90px;
//                width: 40px;
//                height: 40px;
//             }

//             &:nth-child(5) {
//                top: 160px;
//                left: 70px;
//                width: 15px;
//                height: 15px;
//             }

//             &:nth-child(6) {
//                top: 165px;
//                left: 125px;
//                width: 10px;
//                height: 10px;
//             }

//             &:nth-child(7) {
//                top: 90px;
//                left: 150px;
//                width: 15px;
//                height: 15px;
//             }
//          }
//       }
//    }

// }

.contentPP {
  width: 200px;
  height: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .planett {
    width: 65%;
    height: 65%;
    background-color: $m-02;
    border-radius: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    transform-origin: center center;
    box-shadow: inset 2px -10px 0px rgba(0, 0, 0, 0.1);

    box-shadow: 0 -20px 100px, 0 0 8px, 0 0 1em #0a102f, 0 0 0.2em #0a102f,
      0 0 0.1em #0a102f, 0 1px 3px #0a102f;

    color: #fff;

    animation: planett 5s ease infinite alternate;

    @keyframes planett {
      0% {
        transform: rotate(10deg);
      }

      100% {
        transform: rotate(-10deg);
      }
    }

    /* planet ring */
    .ringg {
      position: absolute;
      width: 210px;
      height: 210px;
      border-radius: 100%;
      background-color: $m-04;
      display: flex;
      align-items: center;
      justify-content: center;
      transform-origin: 30% center;
      box-shadow: 10px -10px 0px rgba(0, 0, 0, 0.1),
        inset -5px -10px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0 -10px 20px, 0 0 7px, 0 0 5em #0a102f, 0 0 0.5em #0a102f,
        0 0 0.1em #0a102f, 0 18px 20px #0a102f;

      animation: ringg 3s ease infinite;

      @keyframes ringg {
        0% {
          transform: rotateX(110deg) rotateZ(0deg) translate(-50px, 5px);
        }

        100% {
          transform: rotateX(110deg) rotateZ(360deg) translate(-50px, 5px);
        }
      }

      /* small ball */
      &:before {
        content: "";
        position: absolute;
        width: 10px;
        height: 20px;
        border-radius: 100%;
        background-color: $m-03;
        z-index: 2;
        left: calc(0px - 5px);
        box-shadow: inset -5px 5px 0px rgba(0, 0, 0, 0.2);
      }

      /* inner ring */
      &:after {
        content: "";
        position: absolute;
        width: 200px;
        height: 220px;
        border-radius: 100%;
        background-color: $m-03;
        box-shadow: inset 2px -10px 0px rgba(0, 0, 0, 0.1);
      }
    }

    /* to cover the back of the ring */
    .cover-ringg {
      position: absolute;
      width: 100%;
      height: 60%;
      border-bottom-left-radius: 80%;
      border-bottom-right-radius: 80%;
      border-top-left-radius: 100px;
      border-top-right-radius: 100px;
      transform: translate(0px, -17px);
      background-color: $m-02;
      z-index: 2;
      box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.1);
    }

    /* planet spots */
    .spotss {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 2;

      span {
        width: 10px;
        height: 10px;
        background-color: $m-01;
        position: absolute;
        border-radius: 100%;
        box-shadow: inset -2px 3px 0px rgba(0, 0, 0, 0.3);
        animation: dots 5s ease infinite alternate;

        @keyframes dots {
          0% {
            box-shadow: inset -3px 3px 0px rgba(0, 0, 0, 0.3);
          }
          100% {
            box-shadow: inset 3px 3px 0px rgba(0, 0, 0, 0.3);
          }
        }

        &:nth-child(1) {
          top: 20px;
          right: 60px;
        }

        // &:nth-child(2) {
        //    top: 40px;
        //    left: 50px;
        //    width: 15px;
        //    height: 15px;
        // }

        &:nth-child(3) {
          top: 40px;
          left: 20px;
          width: 25px;
          height: 25px;
        }

        &:nth-child(4) {
          top: 20px;
          left: 90px;
          width: 20px;
          height: 20px;
        }

        &:nth-child(5) {
          top: 60px;
          left: 100px;
          width: 10px;
          height: 10px;
        }

        &:nth-child(6) {
          top: 105px;
          left: 90px;
          width: 5px;
          height: 5px;
        }

        &:nth-child(7) {
          top: 110px;
          left: 35px;
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}

// // ROOM3D  -------------------------------------------------
// /***********************/
// /***********************/
// /************************/
// /* Mixin para crear cubo ( Caras siempre al frente ) */
// /************************/
// /***********************/
// /***********************/
// /**/
// *, *::after, *::before {
//   // margin: 0;
//   // padding: 0;
//   // box-sizing: border-box;
//   user-select: none;
//   -webkit-tap-highlight-color: transparent;
// }

// /* Generic */
// .bodyRoom{
//   display: flex;
//   // justify-content: flex-start;
//   // align-items: flex-start;
//   margin-left: 90px;
//   transform: scale(0.8);
//   transform-style: preserve-3d;

// }

// .face {
//   position: absolute;
// }

// /***************/
// .house {
//   position: absolute;
//   width: 40vw;
//   height: 28vw;
//   transform: perspective(90vw) rotateX(75deg) rotateZ(45deg) translateZ(-9vw);
//     transform-style: preserve-3d;

// }

// .h-shadow {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 115%;
//   height: 100%;
//   box-shadow: 1.5vw -3vw 3vw black, 1.5vw 0.5vw 1.5vw black;
//     transform-style: preserve-3d;

// }

// .h-lights {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translateX(-50%) translateY(-50%);
//   width: 45vw;
//   height: 45vw;
//     transform-style: preserve-3d;

// }

// .h-light {
//   position: absolute;
// }
// .h-light:nth-of-type(1) {
//   bottom: 5vw;
//   right: 0;
//   width: 14vw;
//   height: 14vw;
//   border-radius: 50%;
//   background-image: radial-gradient(#1b182a, transparent);
//   filter: blur(1vw);
// }
// .h-light:nth-of-type(2) {
//   bottom: 18vw;
//   right: -1vw;
//   width: 2vw;
//   height: 12vw;
//   border-radius: 50%;
//   transform: rotateZ(-50deg);
//   background-image: radial-gradient(rgba(81, 137, 251, 0.45) 50%, rgba(40, 125, 210, 0.45), transparent);
//   box-shadow: -1vw -1vw 2vw 1vw rgba(131, 171, 252, 0.1);
//   filter: blur(1vw);
// }
// .h-light:nth-of-type(3) {
//   bottom: -2vw;
//   right: 17vw;
//   width: 5vw;
//   height: 12vw;
//   border-radius: 50%;
//   transform: rotateZ(-50deg);
//   background-image: radial-gradient(rgba(81, 137, 251, 0.5) 50%, rgba(40, 125, 210, 0.5), transparent);
//   filter: blur(2vw);
// }
// .h-light:nth-of-type(3)::before, .h-light:nth-of-type(3)::after {
//   content: "";
//   position: absolute;
//   width: 200%;
//   top: -6vw;
//   height: 400%;
//   background-image: linear-gradient(to bottom, rgba(40, 125, 210, 0.1), rgba(81, 137, 251, 0.1), transparent);
//   border-top-left-radius: 10vw;
//   border-top-right-radius: 10vw;
//   filter: blur(1.5vw);
//     transform-style: preserve-3d;

// }
// .h-light:nth-of-type(3)::before {
//   right: -50%;
//   transform-origin: top right;
//   transform: rotateZ(15deg);
//   box-shadow: -2vw -2vw 0 rgba(81, 137, 251, 0.075);
//     transform-style: preserve-3d;

// }
// .h-light:nth-of-type(3)::after {
//   left: -50%;
//   transform-origin: top left;
//   transform: rotateZ(-15deg);
//   box-shadow: 2vw -2vw 0 rgba(81, 137, 251, 0.075);
//     transform-style: preserve-3d;

// }
// .h-light:nth-of-type(4) {
//   bottom: 5vw;
//   left: 8vw;
//   width: 28vw;
//   height: 4vw;
//   transform-origin: top left;
//   transform: skewX(58deg);
//   background-image: linear-gradient(to right, rgba(81, 137, 251, 0.075) 10%, transparent 25%, transparent, rgba(0, 0, 0, 0.15));
//   filter: blur(0.25vw);
// }
// .h-light:nth-of-type(6) {
//   bottom: 14vw;
//   right: 2vw;
//   width: 8vw;
//   height: 16vw;
//   transform-origin: bottom left;
//   transform: skewY(49deg);
//   background-image: linear-gradient(to left, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7));
//   filter: blur(0.35vw);
// }

// /***************/
// /***************/
// .alt {
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 45vw;
//   height: 27vw;
//     transform-style: preserve-3d;

// }
// .alt__front {
//   width: 45vw;
//   height: 0.5vw;
//   transform-origin: bottom left;
//   transform: rotateX(-90deg) translateZ(26.5vw);
//     transform-style: preserve-3d;

// }
// .alt__back {
//   width: 45vw;
//   height: 0.5vw;
//   transform-origin: top left;
//   transform: rotateX(-90deg) rotateY(180deg) translateX(-40vw) translateY(-0.5vw);
//     transform-style: preserve-3d;

// }
// .alt__right {
//   width: 27vw;
//   height: 0.5vw;
//   transform-origin: top left;
//   transform: rotateY(90deg) rotateZ(-90deg) translateZ(45vw) translateX(-27vw) translateY(-0.5vw);
//     transform-style: preserve-3d;

// }
// .alt__left {
//   width: 27vw;
//   height: 0.5vw;
//   transform-origin: top left;
//   transform: rotateY(-90deg) rotateZ(90deg) translateY(-0.5vw);
//     transform-style: preserve-3d;

// }
// .alt__top {
//   width: 45vw;
//   height: 27vw;
//   transform-origin: top left;
//   transform: translateZ(0.5vw);
//     transform-style: preserve-3d;

// }
// .alt__bottom {
//   width: 27vw;
//   height: 27vw;
//   transform-origin: top left;
//   transform: rotateY(180deg) translateX(-27vw);
//     transform-style: preserve-3d;

// }
// .alt__front {
//   background-color: #9E99C1;
//     transform-style: preserve-3d;

// }
// .alt__back {
//   background-color: #383358;
//     transform-style: preserve-3d;

// }
// .alt__right {
//   background-color: #383358;
//     transform-style: preserve-3d;

// }
// .alt__left {
//   background-color: #FBFAFE;
//     transform-style: preserve-3d;

// }
// .alt__top {
//   background-image: linear-gradient(to bottom, #0b0c1f, #383358, #9E99C1);
//     transform-style: preserve-3d;

// }
// .alt__top .light:nth-of-type(1) {
//   position: absolute;
//   height: 100%;
//   width: 100%;
//   background-image: linear-gradient(to bottom, rgba(20, 61, 103, 0.75), rgba(81, 137, 251, 0.75), transparent);
//     transform-style: preserve-3d;

// }
// .alt__top .light:nth-of-type(2) {
//   position: absolute;
//   left: 4vw;
//   height: 100%;
//   width: 6vw;
//   background-image: linear-gradient(to bottom, transparent 20%, rgba(40, 125, 210, 0.75), rgba(81, 137, 251, 0.25) 80%);
//   filter: blur(0.1vw);
//     transform-style: preserve-3d;

// }
// .alt__top .light:nth-of-type(3) {
//   position: absolute;
//   bottom: 10vw;
//   left: 5vw;
//   width: 6vw;
//   height: 3vw;
//   border-radius: 50%;
//   transform: rotateZ(42deg);
//   background-image: radial-gradient(rgba(131, 171, 252, 0.75) 50%, rgba(32, 99, 167, 0.75));
//   filter: blur(0.55vw);
//     transform-style: preserve-3d;

// }
// .alt__top .light:nth-of-type(4) {
//   position: absolute;
//   bottom: 7vw;
//   left: 4vw;
//   width: 8.5vw;
//   height: 2vw;
//   border-radius: 50%;
//   transform: rotateZ(40deg);
//   background-image: radial-gradient(rgba(131, 171, 252, 0.75) 50%, rgba(32, 99, 167, 0.75));
//   filter: blur(0.55vw);
// }
// .alt__top .light:nth-of-type(5) {
//   position: absolute;
//   bottom: 3.5vw;
//   left: 4.5vw;
//   width: 6vw;
//   height: 2vw;
//   border-radius: 50%;
//   transform: rotateZ(40deg);
//   background-image: radial-gradient(rgba(141, 178, 252, 0.75) 50%, rgba(32, 99, 167, 0.75));
//   filter: blur(0.75vw);
// }
// .alt__top .light:nth-of-type(6) {
//   position: absolute;
//   bottom: 3vw;
//   left: 0.5vw;
//   width: 4vw;
//   height: 2vw;
//   border-radius: 50%;
//   transform: rotateZ(40deg);
//   background-image: radial-gradient(rgba(141, 178, 252, 0.75) 50%, rgba(32, 99, 167, 0.75));
//   filter: blur(0.35vw);
// }
// .alt__top .light:nth-of-type(7) {
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-image: linear-gradient(to right, black, #5189fb 10%, transparent 20%);
// }
// .alt__top .light:nth-of-type(7)::before {
//   content: "";
//   position: absolute;
//   width: 0;
//   height: 100%;
//   background-image: linear-gradient(to right, rgba(5, 70, 199, 0.6), transparent 60%);
//     transform-style: preserve-3d;

// }
// .alt__top .light:nth-of-type(7)::after {
//   content: "";
//   position: absolute;
//   width: 0%;
//   height: 20%;
//   background-image: linear-gradient(to bottom, rgba(6, 78, 224, 0.5), transparent 60%);
//     transform-style: preserve-3d;

// }
// .alt__top .light:nth-of-type(8) {
//   position: absolute;
//   bottom: 5vw;
//   left: 10vw;
//   width: 6vw;
//   height: 4vw;
//   border-radius: 50%;
//   transform: rotateZ(40deg);
//   background-image: radial-gradient(rgba(255, 255, 255, 0.1) 50%, rgba(128, 121, 174, 0.1));
//   filter: blur(0.8vw);
// }
// .alt__bottom {
//   background-color: #383358;
//     transform-style: preserve-3d;

// }

// .alb {
//   position: absolute;
//   left: 0;
//   bottom: 0;
//   width: 27vw;
//   height: 1vw;
//     transform-style: preserve-3d;

// }
// .alb__front {
//   width: 27vw;
//   height: 2vw;
//   transform-origin: bottom left;
//   transform: rotateX(-90deg) translateZ(-1vw);
//     transform-style: preserve-3d;

// }
// .alb__back {
//   width: 27vw;
//   height: 2vw;
//   transform-origin: top left;
//   transform: rotateX(-90deg) rotateY(180deg) translateX(-27vw) translateY(-2vw);
//     transform-style: preserve-3d;

// }
// .alb__right {
//   width: 1vw;
//   height: 2vw;
//   transform-origin: top left;
//   transform: rotateY(90deg) rotateZ(-90deg) translateZ(27vw) translateX(-1vw) translateY(-2vw);
//     transform-style: preserve-3d;

// }
// .alb__left {
//   width: 1vw;
//   height: 2vw;
//   transform-origin: top left;
//   transform: rotateY(-90deg) rotateZ(90deg) translateY(-2vw);
//     transform-style: preserve-3d;

// }
// .alb__top {
//   width: 27vw;
//   height: 1vw;
//   transform-origin: top left;
//   transform: translateZ(2vw);
//     transform-style: preserve-3d;

// }
// .alb__bottom {
//   width: 27vw;
//   height: 1vw;
//   transform-origin: top left;
//   transform: rotateY(180deg) translateX(-27vw);
//     transform-style: preserve-3d;

// }
// .alb__front {
//   background-image: linear-gradient(to right, #9E99C1 40%, #8f89b7);
// }
// .alb__back {
//   background-color: #383358;
// }
// .alb__right {
//   background-color: #383358;
// }
// .alb__left {
//   background-color: #FBFAFE;
// }
// .alb__top {
//   background-image: linear-gradient(to right, #FBFAFE 40%, #eae5fa);
// }
// .alb__bottom {
//   background-color: #383358;
// }

// .arb {
//   position: absolute;
//   right: 0;
//   bottom: 0;
//   width: 1vw;
//   height: 28vw;
//     transform-style: preserve-3d;

// }
// .arb__front {
//   width: 1vw;
//   height: 2vw;
//   transform-origin: bottom left;
//   transform: rotateX(-90deg) translateZ(26vw);
//     transform-style: preserve-3d;

// }
// .arb__back {
//   width: 1vw;
//   height: 2vw;
//   transform-origin: top left;
//   transform: rotateX(-90deg) rotateY(180deg) translateX(-1vw) translateY(-2vw);
// }
// .arb__right {
//   width: 28vw;
//   height: 2vw;
//   transform-origin: top left;
//   transform: rotateY(90deg) rotateZ(-90deg) translateZ(1vw) translateX(-28vw) translateY(-2vw);
// }
// .arb__left {
//   width: 28vw;
//   height: 2vw;
//   transform-origin: top left;
//   transform: rotateY(-90deg) rotateZ(90deg) translateY(-2vw);
// }
// .arb__top {
//   width: 1vw;
//   height: 28vw;
//   transform-origin: top left;
//   transform: translateZ(2vw);
// }
// .arb__bottom {
//   width: 1vw;
//   height: 28vw;
//   transform-origin: top left;
//   transform: rotateY(180deg) translateX(-1vw);
// }
// .arb__front {
//   background-color: #8f89b7;
// }
// .arb__back {
//   background-color: #383358;
// }
// .arb__right {
//   background-image: linear-gradient(to right, #282347 40%, #0f0e17);
// }
// .arb__left {
//   background-color: #9E99C1;
// }
// .arb__top {
//   background-image: linear-gradient(to top, #FBFAFE, #3b3469 25%, #2e2a48 75%, #9E99C1);
// }
// .arb__top::before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 30%;
//   top: 0;
//   background-image: linear-gradient(to bottom, transparent, rgba(81, 137, 251, 0.85), transparent);
//     transform-style: preserve-3d;

// }
// .arb__bottom {
//   background-color: #383358;
// }

// /***************/
// /***************/
// .blt {
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 1vw;
//   height: 27vw;
//   transform: translateZ(0.5vw);
//     transform-style: preserve-3d;

// }
// .blt__front {
//   width: 1vw;
//   height: 20vw;
//   transform-origin: bottom left;
//   transform: rotateX(-90deg) translateZ(7vw);
//     transform-style: preserve-3d;

// }
// .blt__back {
//   width: 1vw;
//   height: 20vw;
//   transform-origin: top left;
//   transform: rotateX(-90deg) rotateY(180deg) translateX(-1vw) translateY(-20vw);
// }
// .blt__right {
//   width: 27vw;
//   height: 20vw;
//   transform-origin: top left;
//   transform: rotateY(90deg) rotateZ(-90deg) translateZ(1vw) translateX(-27vw) translateY(-20vw);
// }
// .blt__left {
//   width: 27vw;
//   height: 20vw;
//   transform-origin: top left;
//   transform: rotateY(-90deg) rotateZ(90deg) translateY(-20vw);
// }
// .blt__top {
//   width: 1vw;
//   height: 27vw;
//   transform-origin: top left;
//   transform: translateZ(20vw);
// }
// .blt__bottom {
//   width: 1vw;
//   height: 27vw;
//   transform-origin: top left;
//   transform: rotateY(180deg) translateX(-1vw);
// }
// .blt__front {
//   background-image: linear-gradient(to bottom, #383358, #9E99C1);
// }
// .blt__back {
//   background-color: #383358;
// }
// .blt__right {
//   background-image: linear-gradient(to bottom, #151225, #383358, #383358 90%, #242040);
// }
// .blt__right::before {
//   content: "";
//   position: absolute;
//   bottom: 0;
//   width: 100%;
//   height: 0.75vw;
//   background-image: linear-gradient(to bottom, #8f89b7, #287dd2);
//   border-top: 0.1vw solid #282347;
//   border-bottom: 0.1vw solid #282347;
//     transform-style: preserve-3d;

// }
// .blt__right::after {
//   left:0;
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   background-image: linear-gradient(to bottom, rgba(6, 78, 224, 0.35), transparent 30%, transparent 70%, rgba(40, 125, 210, 0.35));
//     transform-style: preserve-3d;

// }
// .blt__left {
//   background-color: #FBFAFE;
// }
// .blt__bottom {
//   background-color: #383358;
// }

// .blt2 {
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 2vw;
//   height: 27vw;
//   transform: translateZ(20.5vw);
// }
// .blt2__front {
//   width: 2vw;
//   height: 0.75vw;
//   transform-origin: bottom left;
//   transform: rotateX(-90deg) translateZ(26.25vw);
// }
// .blt2__back {
//   width: 2vw;
//   height: 0.75vw;
//   transform-origin: top left;
//   transform: rotateX(-90deg) rotateY(180deg) translateX(-2vw) translateY(-0.75vw);
// }
// .blt2__right {
//   width: 27vw;
//   height: 0.75vw;
//   transform-origin: top left;
//   transform: rotateY(90deg) rotateZ(-90deg) translateZ(2vw) translateX(-27vw) translateY(-0.75vw);
// }
// .blt2__left {
//   width: 27vw;
//   height: 0.75vw;
//   transform-origin: top left;
//   transform: rotateY(-90deg) rotateZ(90deg) translateY(-0.75vw);
// }
// .blt2__top {
//   width: 2vw;
//   height: 27vw;
//   transform-origin: top left;
//   transform: translateZ(0.75vw);
// }
// .blt2__bottom {
//   width: 2vw;
//   height: 27vw;
//   transform-origin: top left;
//   transform: rotateY(180deg) translateX(-2vw);
// }
// .blt2__front {
//   background-color: #383358;
// }
// .blt2__back {
//   background-color: #383358;
// }
// .blt2__right {
//   background-image: linear-gradient(to right, #8f89b7, #9E99C1, #7169a4);
// }
// .blt2__right::before {
//   left:0;
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   bottom: 0;
//   border-bottom: 0.1vw solid rgba(251, 250, 254, 0.75);
//   background-image: linear-gradient(to top, rgba(81, 137, 251, 0.35), transparent);
//     transform-style: preserve-3d;

// }
// .blt2__left {
//   background-color: #FBFAFE;
// }
// .blt2__top {
//   background-image: linear-gradient(to top, #9E99C1, #FBFAFE);
// }
// .blt2__bottom {
//   background-color: #383358;
// }

// .blb {
//   position: absolute;
//   left: 1vw;
//   top: 0;
//   width: 40vw;
//   height: 1vw;
//   transform: translateZ(0.5vw);
//     transform-style: preserve-3d;

// }
// .blb__front {
//   width: 45vw;
//   height: 20vw;
//   transform-origin: bottom left;
//   transform: rotateX(-90deg) translateZ(-19vw);
// }
// .blb__back {
//   width: 40vw;
//   height: 20vw;
//   transform-origin: top left;
//   transform: rotateX(-90deg) rotateY(180deg) translateX(-40vw) translateY(-20vw);
// }
// .blb__right {
//   width: 1vw;
//   height: 20vw;
//   transform-origin: top left;
//   transform: rotateY(90deg) rotateZ(-90deg) translateZ(40vw) translateX(-1vw) translateY(-20vw);
// }
// .blb__left {
//   width: 1vw;
//   height: 20vw;
//   transform-origin: top left;
//   transform: rotateY(-90deg) rotateZ(90deg) translateY(-20vw);
// }
// .blb__top {
//   width: 26vw;
//   height: 1vw;
//   transform-origin: top left;
//   transform: translateZ(20vw);
// }
// .blb__bottom {
//   width: 26vw;
//   height: 1vw;
//   transform-origin: top left;
//   transform: rotateY(180deg) translateX(-26vw);
// }
// .blb__front {
//   background-image: linear-gradient(to bottom, #4b4572, #595388 20%, #4b4572);
// }
// .blb__front::before {
//   left:0;
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   background-image: linear-gradient(to bottom, rgba(40, 125, 210, 0.25), transparent 20%, transparent 80%, rgba(40, 125, 210, 0.5)), linear-gradient(to right, rgba(31, 33, 88, 0.35), transparent), linear-gradient(to bottom, rgba(31, 33, 88, 0.35), transparent), linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent 20%, transparent 80%, rgba(40, 125, 210, 0.25));
//     transform-style: preserve-3d;

// }
// .blb__front::after {
//   left:0;
//   content: "";
//   position: absolute;
//   bottom: 0;
//   width: 100%;
//   height: 0.75vw;
//   background-image: linear-gradient(to bottom, #8f89b7, #287dd2);
//   border-top: 0.1vw solid #282347;
//   border-bottom: 0.1vw solid #282347;
//     transform-style: preserve-3d;

// }
// .blb__back {
//   background-color: #383358;
// }
// .blb__right {
//   background-image: linear-gradient(to bottom, #151225, #0f0e17 80%, #151225);
// }
// .blb__bottom {
//   background-color: #383358;
// }

// .blb2 {
//   position: absolute;
//   left: 2vw;
//   top: 0;
//   width: 40vw;
//   height: 1vw;
//   transform: translateZ(20.5vw);
//     transform-style: preserve-3d;

// }
// .blb2__front {
//   width: 45vw;
//   height: 0.75vw;
//   transform-origin: bottom left;
//   transform: rotateX(-90deg) translateZ(1.25vw);
// }
// .blb2__back {
//   width: 45vw;
//   height: 0.75vw;
//   transform-origin: top left;
//   transform: rotateX(-90deg) rotateY(180deg) translateX(-45vw) translateY(-0.75vw);
//     transform-style: preserve-3d;

// }
// .blb2__right {
//   width: 2vw;
//   height: 0.75vw;
//   transform-origin: top left;
//   transform:rotateY(90deg) rotateZ(-90deg) translateZ(45vw) translateX(-2vw) translateY(-0.75vw);
// }
// .blb2__left {
//   width: 2vw;
//   height: 0.75vw;
//   transform-origin: top left;
//   transform: rotateY(-90deg) rotateZ(90deg) translateY(-0.75vw);
// }
// .blb2__top {
//   width: 45vw;
//   height: 2vw;
//   transform-origin: top left;
//   transform: translateZ(0.75vw);
// }
// .blb2__bottom {
//   width: 40vw;
//   height: 2vw;
//   transform-origin: top left;
//   transform: rotateY(180deg) translateX(-45vw);
// }
// .blb2__front {
//   background-image: linear-gradient(to right, #7169a4, #9E99C1, #8f89b7);
// }
// .blb2__front::before {
//   left:0;
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   bottom: 0;
//   border-bottom: 0.1vw solid rgba(251, 250, 254, 0.75);
//   background-image: linear-gradient(to top, rgba(40, 125, 210, 0.25), transparent);
//     transform-style: preserve-3d;

// }
// .blb2__back {
//   background-color: #383358;
// }
// .blb2__right {
//   background-color: #151225;
// }
// .blb2__left {
//   background-color: #9E99C1;
// }
// .blb2__top {
//   background-image: linear-gradient(to left, #9E99C1, #FBFAFE);
// }
// .blb2__bottom {
//   background-color: #383358;
// }

// /***************/
// /***************/
// .bocina-l,
// .bocina-r {
//   position: absolute;
//   left: 1.5vw;
//   bottom: 5.25vw;
//   width: 0.75vw;
//   height: 1.5vw;
//   transform: translateZ(8vw);
//     transform-style: preserve-3d;

// }
// .bocina-l__front,
// .bocina-r__front {
//   width: 0.75vw;
//   height: 6vw;
//   transform-origin: bottom left;
//   transform: rotateX(-90deg) translateZ(-4.5vw);
//     transform-style: preserve-3d;

// }
// .bocina-l__back,
// .bocina-r__back {
//   width: 0.75vw;
//   height: 6vw;
//   transform-origin: top left;
//   transform: rotateX(-90deg) rotateY(180deg) translateX(-0.75vw) translateY(-6vw);
//     transform-style: preserve-3d;

// }
// .bocina-l__right,
// .bocina-r__right {
//   width: 1.5vw;
//   height: 6vw;
//   transform-origin: top left;
//   transform: rotateY(90deg) rotateZ(-90deg) translateZ(0.75vw) translateX(-1.5vw) translateY(-6vw);
//     transform-style: preserve-3d;

// }
// .bocina-l__left,
// .bocina-r__left {
//   width: 1.5vw;
//   height: 6vw;
//   transform-origin: top left;
//   transform: rotateY(-90deg) rotateZ(90deg) translateY(-6vw);
//     transform-style: preserve-3d;

// }
// .bocina-l__top,
// .bocina-r__top {
//   width: 0.75vw;
//   height: 1.5vw;
//   transform-origin: top left;
//   transform: translateZ(6vw);
//     transform-style: preserve-3d;

// }
// .bocina-l__bottom,
// .bocina-r__bottom {
//   width: 0.75vw;
//   height: 1.5vw;
//   transform-origin: top left;
//   transform: rotateY(180deg) translateX(-0.75vw);
// }
// .bocina-l__right,
// .bocina-r__right {
//   background-image: radial-gradient(black, black);
// }
// .bocina-l__top,
// .bocina-r__top {
//   background-image: linear-gradient(to left, #d9d0f6, #9E99C1, #7169a4);
// }
// .bocina-l__bottom,
// .bocina-r__bottom {
//   background-color: #383358;
// }

// .bocina-l__front {
//   background-image: linear-gradient(to right, #5189fb, #595388 30%, #b7a5ed);
// }
// .bocina-l__back {
//   background-image: linear-gradient(to right, #383358, #232038);
// }
// .bocina-l__back::before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   background-image: linear-gradient(to right, rgba(81, 137, 251, 0.35) 65%, rgba(81, 137, 251, 0.95));
//     transform-style: preserve-3d;

// }
// .bocina-l__left {
//   background-color: #282347;
// }
// .bocina-l__left::before {
//   content: "";
//   position: absolute;
//   top: -10%;
//   left: 20%;
//   width: 120%;
//   height: 120%;
//   background-image: linear-gradient(to left, rgba(7, 23, 39, 0.75), rgba(0, 0, 0, 0.75));
//   filter: blur(0.5vw);
//     transform-style: preserve-3d;

// }

// .bocina-r {
//   top: 5.25vw;
// }
// .bocina-r__front {
//   background-image: linear-gradient(to right, #383358, #0f0e17);
// }
// .bocina-r__front::before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   background-image: linear-gradient(to left, rgba(81, 137, 251, 0.35) 65%, rgba(81, 137, 251, 0.95));
//     transform-style: preserve-3d;

// }
// .bocina-r__back {
//   background-image: linear-gradient(to right, #595388, #b7a5ed);
// }
// .bocina-r__left {
//   background-color: #282347;
// }
// .bocina-r__left::before {
//   content: "";
//   position: absolute;
//   top: -10%;
//   left: -20%;
//   width: 120%;
//   height: 120%;
//   background-image: linear-gradient(to right, rgba(7, 23, 39, 0.75), rgba(0, 0, 0, 0.75));
//   filter: blur(0.5vw);
//     transform-style: preserve-3d;

// }

// .repisa-t,
// .repisa-b,
// .repisa-c,
// .repisa-d,
// .repisa-e,
// .repisa-f,
// .repisa-g,
// .repisa-left,
// .repisa-right {
//   position: absolute;
//   left: 1.5vw;
//   top: 9vw;
//   width: 1.5vw;
//   height: 10vw;
//   transform: translateZ(16vw);
//     transform-style: preserve-3d;

// }
// .repisa-t__front,
// .repisa-b__front,
// .repisa-c__front,
// .repisa-d__front,
// .repisa-e__front,
// .repisa-f__front,
// .repisa-g__front,
// .repisa-left__front,
// .repisa-right__front  {
//   width: 1.5vw;
//   height: 0.25vw;
//   transform-origin: bottom left;
//   transform: rotateX(-90deg) translateZ(9.75vw);
//     transform-style: preserve-3d;
// }

// .repisa-t__back,
// .repisa-b__back,
// .repisa-c__back,
// .repisa-d__back,
// .repisa-e__back,
// .repisa-f__back,
// .repisa-g__back,
// .repisa-left__back,
// .repisa-right__back{
//   width: 1.5vw;
//   height: 0.25vw;
//   transform-origin: top left;
//   transform: rotateX(-90deg) rotateY(180deg) translateX(-1.5vw) translateY(-0.25vw);
//     transform-style: preserve-3d;

// }
// .repisa-t__right,
// .repisa-b__right,
// .repisa-c__right,
// .repisa-d__right,
// .repisa-r__right,
// .repisa-f__right,
// .repisa-g__right,
// .repisa-left__right,
// .repisa-right__right  {
//   width: 10vw;
//   height: 0.25vw;
//   transform-origin: top left;
//   transform: rotateY(90deg) rotateZ(-90deg) translateZ(1.5vw) translateX(-10vw) translateY(-0.25vw);
//     transform-style: preserve-3d;

// }
// .repisa-t__left,
// .repisa-b__left,
// .repisa-c__left,
// .repisa-d__letf,
// .repisa-e__left,
// .repisa-f__left,
// .repisa-g__left,
// .repisa-left__left,
// .repisa-right__left  {
//   width: 10vw;
//   height: 0.25vw;
//   transform-origin: top left;
//   transform: rotateY(-90deg) rotateZ(90deg) translateY(-0.25vw);
//     transform-style: preserve-3d;

// }
// .repisa-t__top,
// .repisa-b__top,
// .repisa-c__top,
// .repisa-d__top,
// .repisa-e__top,
// .repisa-f__top,
// .repisa-g__top,
// .repisa-left__top,
// .repisa-right__top  {
//   width: 1.5vw;
//   height: 10vw;
//   transform-origin: top left;
//   transform: translateZ(0.25vw);
//     transform-style: preserve-3d;

// }
// .repisa-t__bottom,
// .repisa-b__bottom,
// .repisa-c__bottom,
// .repisa-d__bottom,
// .repisa-e__bottom,
// .repisa-f__bottom,
// .repisa-g__bottom,
// .repisa-left__botom,
// .repisa-right__bottom  {
//   width: 1.5vw;
//   height: 10vw;
//   transform-origin: top left;
//   transform: rotateY(180deg) translateX(-1.5vw);
//     transform-style: preserve-3d;

// }
// .repisa-t__front,
// .repisa-b__front,
// .repisa-c__front,
// .repisa-d__front,
// .repisa-e__front,
// .repisa-f__front,
// .repisa-g__front,
// .repisa-left__front,
// .repisa-right__front  {
//   background-color: #9E99C1;
// }
// .repisa-t__back,
// .repisa-b__back,
// .repisa-c__back,
// .repisa-d__back,
// .repisa-e__back,
// .repisa-f__back,
// .repisa-g__back,
// .repisa-right__back,
// .repisa-right__back  {
//   background-color: #FBFAFE;
// }
// .repisa-t__right,
// .repisa-b__right,
// .repisa-c__right,
// .repisa-d__right,
// .repisa-e__right,
// .repisa-f__right,
// .repisa-g__right,
// .repisa-left__right,
// .repisa-right__right  {
//   background-color: #8079ae;
// }
// .repisa-t__left,
// .repisa-b__left,
// .repisa-c__left,
// .repisa-d__left,
// .repisa-e__left,
// .repisa-f__left,
// .repisa-g__left,
// .repisa-left__left,
// .repisa-right__left  {
//   background-color: #FBFAFE;
// }
// .repisa-t__top,
// .repisa-b__top,
// .repisa-c__top,
// .repisa-d__top,
// .repisa-e__top,
// .repisa-f__top,
// .repisa-g__top,
// .repisa-left__top,
// .repisa-right__top {
//   background-color: #d9d0f6;
// }
// .repisa-t__top::before,
// .repisa-b__top::before,
// .repisa-c__top::before,
// .repisa-d__top::before,
// .repisa-e__top::before,
// .repisa-f__top::before,
// .repisa-g__top::before,
// .repisa-left__top::before,
// .repisa-right__top::before  {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   background-image: linear-gradient(to right, rgba(6, 78, 224, 0.75), transparent);
//     transform-style: preserve-3d;

// }
// .repisa-t__bottom,
// .repisa-b__bottom,
// .repisa-c__bottom,
// .repisa-d__borrom,
// .repisa-e__bottom,
// .repisa-f__bottom,
// .repisa-g__bottom,
// .repisa-left__bottom,
// .repisa-right__bottom  {
//   background-color: #383358;
// }
// .repisa-t__bottom::before,
// .repisa-b__bottom::before,
// .repisa-c__bottom::before,
// .repisa-d__bottom::before,
// .repisa-e__bottom::before,
// .repisa-f__bottom::before,
// .repisa-g__bottom::before,
// .repisa-left__bottom::before,
// .repisa-right__bottom::before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   background-image: linear-gradient(to right, rgba(6, 78, 224, 0.75), transparent);
//     transform-style: preserve-3d;

// }

// .repisa-left{
//     transform: translateZ(10vw) translateY(-11vw)  rotateX(90deg) rotateY(90deg);
//    transform-style: preserve-3d;
// }

// .repisa-right{
//     transform: translateZ(10vw) translateY(-11vw)  rotateX(90deg) rotateY(90deg);
//     transform-style: preserve-3d;
// }

// .repisa-b{
//   transform: translateZ(9vw);
//     transform-style: preserve-3d;
// }

// .repisa-c{
//   transform: translateZ(5vw);
//     transform-style: preserve-3d;
// }

// .repisa-d{
//   transform: translateZ(4vw);
//     transform-style: preserve-3d;
// }
// .repisa-e{
//   transform: translateZ(3vw);
//     transform-style: preserve-3d;
// }
// .repisa-f{
//   transform: translateZ(2vw);
//     transform-style: preserve-3d;
// }
// .repisa-g{
//   transform: translateZ(1vw);
//     transform-style: preserve-3d;
// }

// .tv {
//   position: absolute;
//   left: 1.51vw;
//   top: 8vw;
//   width: 0.5vw;
//   height: 12vw;
//   // transform: translateZ(8vw);
//   transform: rotate(90deg) translateZ(180px) translateY(-350px) translateX(-100px);
//     transform-style: preserve-3d;

// }
// .tv__front {
//   width: 0.5vw;
//   height: 6vw;
//   transform-origin: bottom left;
//   transform: rotateX(-90deg) translateZ(6vw);
//     transform-style: preserve-3d;

// }
// .tv__back {
//   width: 0.5vw;
//   height: 6vw;
//   transform-origin: top left;
//   transform: rotateX(-90deg) rotateY(180deg) translateX(-0.5vw) translateY(-6vw);
//     transform-style: preserve-3d;

// }
// .tv__right {
//   width: 12vw;
//   height: 6vw;
//   transform-origin: top left;
//   transform: rotateY(90deg) rotateZ(-90deg) translateZ(0.5vw) translateX(-12vw) translateY(-6vw);
//     transform-style: preserve-3d;

// }
// .tv__left {
//   width: 12vw;
//   height: 6vw;
//   transform-origin: top left;
//   transform: rotateY(-90deg) rotateZ(90deg) translateY(-6vw);
//     transform-style: preserve-3d;

// }
// .tv__top {
//   width: 0.5vw;
//   height: 12vw;
//   transform-origin: top left;
//   transform: translateZ(6vw);
// }
// .tv__bottom {
//   width: 0.5vw;
//   height: 12vw;
//   transform-origin: top left;
//   transform: rotateY(180deg) translateX(-0.5vw);
// }
// .tv__front {
//   background-color: #0b0c1f;
//     transform-style: preserve-3d;

// }
// .tv__back {
//   background-color: #0b0c1f;
//     transform-style: preserve-3d;

// }
// .tv__right {
//   background-color: #9cbcfc;
//   border: 0.125vw solid black;
//   animation: pantalla-tv 0.25s infinite alternate;
//     transform-style: preserve-3d;

// }
// .tv__right::before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   box-shadow: 0.125vw 0.125vw 1vw rgba(81, 137, 251, 0.5), -0.125vw 0.125vw 1vw rgba(81, 137, 251, 0.5), 0.125vw -0.125vw 1vw rgba(81, 137, 251, 0.5), -0.125vw -0.125vw 1vw rgba(81, 137, 251, 0.5);
//   background-image: url("https://i.pinimg.com/originals/a9/29/ae/a929ae7a458c84affd8619a2e449274a.gif");
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-position: bottom;
//   opacity: 0.8;
//   left:0vw;
//     transform-style: preserve-3d;

// }
// .tv__left {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: #0b0c1f;
//     transform-style: preserve-3d;

// }
// .tv__left::before {
//   content: "";
//   position: absolute;
//   width: 13vw;
//   height: 7vw;
//   background-image: radial-gradient(rgba(36, 112, 188, 0.95), rgba(56, 121, 250, 0.95));
//   filter: blur(1.25vw);
//   animation: luz-tv 0.25s infinite alternate;
//     transform-style: preserve-3d;

// }
// .tv__top {
//   background-color: #0b0c1f;
// }
// .tv__bottom {
//   background-color: #0b0c1f;
// }

// .librero {
//   position: absolute;
//   left: 13vw;
//   top: 1vw;
//   width: 12vw;
//   height: 0.5vw;
//   transform: translateZ(7vw);
//     transform-style: preserve-3d;

// }
// .librero__front {
//   width: 12vw;
//   height: 0.25vw;
//   transform-origin: bottom left;
//   transform: rotateX(-90deg) translateZ(1.25vw);
//     transform-style: preserve-3d;

// }
// .librero__back {
//   width: 12vw;
//   height: 0.25vw;
//   transform-origin: top left;
//   transform: rotateX(-90deg) rotateY(180deg) translateX(-12vw) translateY(-0.25vw);
//     transform-style: preserve-3d;

// }
// .librero__right {
//   width: 1.5vw;
//   height: 0.25vw;
//   transform-origin: top left;
//   transform: rotateY(90deg) rotateZ(-90deg) translateZ(12vw) translateX(-1.5vw) translateY(-0.25vw);
//     transform-style: preserve-3d;

// }
// .librero__left {
//   width: 1.5vw;
//   height: 0.25vw;
//   transform-origin: top left;
//   transform: rotateY(-90deg) rotateZ(90deg) translateY(-0.25vw);
//     transform-style: preserve-3d;

// }
// .librero__top {
//   width: 12vw;
//   height: 1.5vw;
//   transform-origin: top left;
//   transform: translateZ(0.25vw);
//     transform-style: preserve-3d;

// }
// .librero__bottom {
//   width: 12vw;
//   height: 1.5vw;
//   transform-origin: top left;
//   transform: rotateY(180deg) translateX(-12vw);
//     transform-style: preserve-3d;

// }
// .librero__front {
//   background-color: #9E99C1;
//     transform-style: preserve-3d;

// }
// .librero__back {
//   background-color: #383358;
// }
// .librero__back::before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 4vw;
//   border-radius: 50%;
//   background-image: radial-gradient(rgba(106, 154, 251, 0.7) 50%, rgba(32, 99, 167, 0.6), transparent);
//   filter: blur(1.25vw);
//     transform-style: preserve-3d;

// }

// .librero__right {
//   background-color: #383358;
// }
// .librero__left {
//   background-color: #9E99C1;
// }
// .librero__top {
//   background-color: #d9d0f6;
// }
// .librero__top::before {
//   left:0;
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   background-image: linear-gradient(to bottom, rgba(31, 104, 249, 0.75), transparent);
//     transform-style: preserve-3d;

// }
// .librero__bottom {
//   background-color: #383358;
// }
// .librero__bottom::before {
//   left:0;
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   background-image: linear-gradient(to bottom, rgba(31, 104, 249, 0.75), transparent);
// }

// .libros {
//   position: absolute;
// }

// .libro {
//   position: absolute;
//   top: 1vw;
//   width: 0.5vw;
//   height: 1vw;
//   transform: translateZ(7.25vw);
// }
// .libro__front {
//   width: 0.5vw;
//   height: 1.5vw;
//   transform-origin: bottom left;
//   transform: rotateX(-90deg) translateZ(-0.5vw);
// }
// .libro__back {
//   width: 0.5vw;
//   height: 1.5vw;
//   transform-origin: top left;
//   transform: rotateX(-90deg) rotateY(180deg) translateX(-0.5vw) translateY(-1.5vw);
// }
// .libro__right {
//   width: 1vw;
//   height: 1.5vw;
//   transform-origin: top left;
//   transform: rotateY(90deg) rotateZ(-90deg) translateZ(0.5vw) translateX(-1vw) translateY(-1.5vw);
// }
// .libro__left {
//   width: 1vw;
//   height: 1.5vw;
//   transform-origin: top left;
//   transform: rotateY(-90deg) rotateZ(90deg) translateY(-1.5vw);
// }
// .libro__top {
//   width: 0.5vw;
//   height: 1vw;
//   transform-origin: top left;
//   transform: translateZ(1.5vw);
// }
// .libro__bottom {
//   width: 0.5vw;
//   height: 1vw;
//   transform-origin: top left;
//   transform: rotateY(180deg) translateX(-0.5vw);
// }
// .libro__front {
//   background-image: linear-gradient(to bottom, #9E99C1, #8079ae);
// }
// .libro__front::before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   background-image: linear-gradient(to bottom, rgba(81, 137, 251, 0.4), transparent);
//     transform-style: preserve-3d;

// }
// .libro__back {
//   background-color: #FBFAFE;
// }
// .libro__right {
//   background-color: #645c98;
// }
// .libro__right::before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   background-image: linear-gradient(to bottom, rgba(81, 137, 251, 0.85), rgba(40, 125, 210, 0.35));
//     transform-style: preserve-3d;

// }
// .libro__left {
//   background-color: #9E99C1;
// }
// .libro__top {
//   background-color: #d9d0f6;
// }
// .libro__top::before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   background-image: linear-gradient(to bottom, rgba(81, 137, 251, 0.85), rgba(40, 125, 210, 0.35));
//     transform-style: preserve-3d;

// }
// .libro__bottom {
//   background-color: #554d85;
// }
// .libro:nth-of-type(1) {
//   left: 16vw;
//   transform-origin: left;
//   transform: translateZ(7.25vw) rotateZ(-10deg);
// }
// .libro:nth-of-type(2) {
//   left: 17vw;
//   transform: translateZ(7.25vw) rotateZ(2deg);
// }
// .libro:nth-of-type(3) {
//   left: 17.75vw;
//   transform: translateZ(7.25vw) rotateZ(5deg);
// }
// .libro:nth-of-type(4) {
//   left: 19vw;
//   transform-origin: left;
//   transform: translateZ(7.25vw) rotateZ(10deg);
// }
// .libro:nth-of-type(5) {
//   left: 20vw;
//   transform-origin: left;
//   transform: translateZ(7.25vw) rotateZ(10deg);
// }
// .libro:nth-of-type(6) {
//   left: 23vw;
//   transform-origin: left;
//   transform: translateZ(7.25vw) rotateY(-90deg);
// }

// .fotos {
//   position: absolute;
//     transform-style: preserve-3d;

// }

// .foto {
//   position: absolute;
//   width: 0.125vw;
//   height: 2vw;
//   left: 2vw;
//   top: 11vw;
//   transform: translateZ(5.26vw);
//     transform-style: preserve-3d;

// }
// .foto__front {
//   width: 0.125vw;
//   height: 1.125vw;
//   transform-origin: bottom left;
//   transform: rotateX(-90deg) translateZ(1.125vw);
//     transform-style: preserve-3d;

// }
// .foto__back {
//   width: 0.125vw;
//   height: 1.125vw;
//   transform-origin: top left;
//   transform: rotateX(-90deg) rotateY(180deg) translateX(-0.125vw) translateY(-1.125vw);
//     transform-style: preserve-3d;

// }
// .foto__right {
//   width: 2.25vw;
//   height: 1.125vw;
//   transform-origin: top left;
//   transform: rotateY(90deg) rotateZ(-90deg) translateZ(0.125vw) translateX(-2.25vw) translateY(-1.125vw);
//     transform-style: preserve-3d;

// }
// .foto__left {
//   width: 2.25vw;
//   height: 1.125vw;
//   transform-origin: top left;
//   transform: rotateY(-90deg) rotateZ(90deg) translateY(-1.125vw);
// }
// .foto__top {
//   width: 0.125vw;
//   height: 2.25vw;
//   transform-origin: top left;
//   transform: translateZ(1.125vw);
// }
// .foto__bottom {
//   width: 0.125vw;
//   height: 2.25vw;
//   transform-origin: top left;
//   transform: rotateY(180deg) translateX(-0.125vw);
// }
// .foto__front {
//   background-image: linear-gradient(to bottom, #9E99C1, #8079ae);
// }
// .foto__front::before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   background-image: linear-gradient(to bottom, rgba(81, 137, 251, 0.4), transparent);
//     transform-style: preserve-3d;

// }
// .foto__back {
//   background-color: #FBFAFE;
// }
// .foto__right {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: #8f89b7;
//   border: 0.15vw solid #1f2158;
// }
// .foto__right::before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   background-image: repeating-linear-gradient(to bottom, #9E99C1 0.1vw, #b7a5ed 0.2vw);
//     transform-style: preserve-3d;

// }
// .foto__left {
//   background-color: #9E99C1;
// }
// .foto__top {
//   background-color: #d9d0f6;
// }
// .foto__top::before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   background-image: linear-gradient(to bottom, rgba(81, 137, 251, 0.85), rgba(40, 125, 210, 0.35));
//     transform-style: preserve-3d;

// }
// .foto__bottom {
//   background-color: #595388;
// }
// .foto__bottom::before {
//   content: "";
//   position: absolute;
//   right: -100%;
//   width: 400%;
//   height: 100%;
//   background-color: rgba(11, 12, 31, 0.75);
//   filter: blur(0.15vw);
//     transform-style: preserve-3d;

// }
// .foto:nth-of-type(1) {
//   top: 10.5vw;
//   transform: translateZ(5.26vw) rotateZ(5deg);
// }
// .foto:nth-of-type(2) {
//   top: 15vw;
//   transform: translateZ(5.26vw) rotateZ(-15deg);
// }

// .cajas {
//   position: absolute;
// }

// .caja {
//   position: absolute;
//   width: 1vw;
//   height: 1.5vw;
//   left: 2vw;
//   transform: translateZ(16.35vw);
//     transform-style: preserve-3d;

// }
// .caja__front {
//   width: 1vw;
//   height: 0.75vw;
//   transform-origin: bottom left;
//   transform: rotateX(-90deg) translateZ(0.75vw);
//     transform-style: preserve-3d;

// }
// .caja__back {
//   width: 1vw;
//   height: 0.75vw;
//   transform-origin: top left;
//   transform: rotateX(-90deg) rotateY(180deg) translateX(-1vw) translateY(-0.75vw);
//     transform-style: preserve-3d;

// }
// .caja__right {
//   width: 1.5vw;
//   height: 0.75vw;
//   transform-origin: top left;
//   transform: rotateY(90deg) rotateZ(-90deg) translateZ(1vw) translateX(-1.5vw) translateY(-0.75vw);
//     transform-style: preserve-3d;

// }
// .caja__left {
//   width: 1.5vw;
//   height: 0.75vw;
//   transform-origin: top left;
//   transform: rotateY(-90deg) rotateZ(90deg) translateY(-0.75vw);
// }
// .caja__top {
//   width: 1vw;
//   height: 1.5vw;
//   transform-origin: top left;
//   transform: translateZ(0.75vw);
// }
// .caja__bottom {
//   width: 1vw;
//   height: 1.5vw;
//   transform-origin: top left;
//   transform: rotateY(180deg) translateX(-1vw);
// }
// .caja__front {
//   background-image: linear-gradient(to bottom, #9E99C1, #8079ae);
// }
// .caja__front::before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   background-image: linear-gradient(to bottom, rgba(81, 137, 251, 0.4), transparent);
//     transform-style: preserve-3d;

// }
// .caja__back {
//   background-color: #9E99C1;
// }
// .caja__right {
//   background-color: #645c98;
// }
// .caja__right::before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   background-image: linear-gradient(to bottom, rgba(81, 137, 251, 0.85), rgba(40, 125, 210, 0.35));
// }
// .caja__left {
//   background-color: #9E99C1;
// }
// .caja__top {
//   background-color: #d9d0f6;
// }
// .caja__top::before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   background-image: linear-gradient(to bottom, rgba(81, 137, 251, 0.85), rgba(40, 125, 210, 0.35));
// }
// .caja__bottom {
//   background-color: #595388;
// }
// .caja__bottom::before {
//   content: "";
//   position: absolute;
//   right: -50%;
//   width: 150%;
//   height: 120%;
//   background-color: rgba(31, 33, 88, 0.5);
//   filter: blur(0.15vw);
// }
// .caja:nth-of-type(1) {
//   top: 11.6vw;
// }
// .caja:nth-of-type(2) {
//   top: 13.5vw;
// }
// .caja:nth-of-type(3) {
//   top: 17vw;
// }

// .cuadro-l,
// .cuadro-r {
//   position: absolute;
//   left: 13vw;
//   top: 1vw;
//   width: 5vw;
//   height: 0.5vw;
//   transform: translateZ(10vw);
//     transform-style: preserve-3d;

// }
// .cuadro-l__front,
// .cuadro-r__front {
//   width: 5vw;
//   height: 7vw;
//   transform-origin: bottom left;
//   transform: rotateX(-90deg) translateZ(-6.75vw);
//     transform-style: preserve-3d;

// }
// .cuadro-l__back,
// .cuadro-r__back {
//   width: 5vw;
//   height: 7vw;
//   transform-origin: top left;
//   transform: rotateX(-90deg) rotateY(180deg) translateX(-5vw) translateY(-7vw);
//     transform-style: preserve-3d;

// }
// .cuadro-l__right,
// .cuadro-r__right {
//   width: 0.25vw;
//   height: 7vw;
//   transform-origin: top left;
//   transform: rotateY(90deg) rotateZ(-90deg) translateZ(5vw) translateX(-0.25vw) translateY(-7vw);
//     transform-style: preserve-3d;

// }
// .cuadro-l__left,
// .cuadro-r__left {
//   width: 0.25vw;
//   height: 7vw;
//   transform-origin: top left;
//   transform: rotateY(-90deg) rotateZ(90deg) translateY(-7vw);
//     transform-style: preserve-3d;

// }
// .cuadro-l__top,
// .cuadro-r__top {
//   width: 5vw;
//   height: 0.25vw;
//   transform-origin: top left;
//   transform: translateZ(7vw);
// }
// .cuadro-l__bottom,
// .cuadro-r__bottom {
//   width: 5vw;
//   height: 0.25vw;
//   transform-origin: top left;
//   transform: rotateY(180deg) translateX(-5vw);
// }
// .cuadro-l__front,
// .cuadro-r__front {
//   background-repeat: no-repeat;
//   background-size: contain;
//   background-position: center;
// }
// .cuadro-l__back,
// .cuadro-r__back {
//   background-color: #383358;
//   box-shadow: 0.35vw 0.35vw 0.35vw rgba(81, 137, 251, 0.2), -0.35vw 0.35vw 0.35vw rgba(81, 137, 251, 0.2), -0.35vw -0.35vw 0.35vw rgba(81, 137, 251, 0.2), 0.35vw -0.35vw 0.35vw rgba(81, 137, 251, 0.2);
// }
// .cuadro-l__right,
// .cuadro-r__right {
//   background-color: #383358;
// }
// .cuadro-l__left,
// .cuadro-r__left {
//   background-color: #9E99C1;
// }
// .cuadro-l__top,
// .cuadro-r__top {
//   background-color: #9E99C1;
// }
// .cuadro-l__bottom,
// .cuadro-r__bottom {
//   background-color: #383358;
// }

// .cuadro-l__front {
//   background-image: url("https://rawcdn.githack.com/ricardoolivaalonso/Codepen/43200238c3177b02a97423fa6cc23f8bfcc5c105/Room/cuadro-1.jpg");
//   background-color: #D51E24;
//   border: 1vw solid #D51E24;
// }
// .cuadro-l__front::before {
//   content: "";
//   position: absolute;
//   top: -1vw;
//   left: -1vw;
//   width: 5vw;
//   height: 7vw;
//   background-origin: padding-box;
//   background-image: linear-gradient(to bottom, rgba(40, 125, 210, 0.15), rgba(40, 125, 210, 0.15));
// }

// .cuadro-r {
//   left: 20vw;
// }
// .cuadro-r__front {
//   background-image: url("https://rawcdn.githack.com/ricardoolivaalonso/Codepen/43200238c3177b02a97423fa6cc23f8bfcc5c105/Room/cuadro-2.jpg");
//   background-color: #0F1110;
//   border: 0.5vw solid #0F1110;
// }
// .cuadro-r__front::before {
//   content: "";
//   position: absolute;
//   top: -0.5vw;
//   left: -0.5vw;
//   width: 5vw;
//   height: 7vw;
//   background-origin: padding-box;
//   background-image: linear-gradient(to bottom, rgba(40, 125, 210, 0.15), rgba(40, 125, 210, 0.15));
// }

// .puerta-c {
//   position: absolute;
//   left: 3vw;
//   top: 1vw;
//   width: 8vw;
//   height: 0.5vw;
//   // transform: translateZ(0.5vw);
//   transform: rotate(90deg) translate(190px, 75px) translateZ(0.51vw);
//     transform-style: preserve-3d;

// }
// .puerta-c::before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 200%;
//   background-color: rgba(0, 0, 0, 0.65);
//   filter: blur(0.5vw);
//     transform-style: preserve-3d;

// }

// .puerta {
//   position: absolute;
//   left: 0.5vw;
//   top: 0;
//   width: 7vw;
//   height: 0.5vw;
//     transform-style: preserve-3d;

// }
// .puerta__front {
//   width: 7vw;
//   height: 16vw;
//   transform-origin: bottom left;
//   transform: rotateX(-90deg) translateZ(-15.75vw);
//     transform-style: preserve-3d;

// }
// .puerta__back {
//   width: 7vw;
//   height: 16vw;
//   transform-origin: top left;
//   transform: rotateX(-90deg) rotateY(180deg) translateX(-7vw) translateY(-16vw);
//     transform-style: preserve-3d;

// }
// .puerta__right {
//   width: 0.25vw;
//   height: 16vw;
//   transform-origin: top left;
//   transform: rotateY(90deg) rotateZ(-90deg) translateZ(7vw) translateX(-0.25vw) translateY(-16vw);
//     transform-style: preserve-3d;

// }
// .puerta__left {
//   width: 0.25vw;
//   height: 16vw;
//   transform-origin: top left;
//   transform: rotateY(-90deg) rotateZ(90deg) translateY(-16vw);
//     transform-style: preserve-3d;

// }
// .puerta__top {
//   width: 7vw;
//   height: 0.25vw;
//   transform-origin: top left;
//   transform: translateZ(16vw);
//     transform-style: preserve-3d;

// }
// .puerta__bottom {
//   width: 7vw;
//   height: 0.25vw;
//   transform-origin: top left;
//   transform: rotateY(180deg) translateX(-7vw);
//     transform-style: preserve-3d;

// }
// .puerta__front {
//   background-image: linear-gradient(to bottom, #0F1110, #121332);
//   border: 0.125vw solid #5189fb;
// }
// .puerta__front::before {
//   left:0;
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   background-image: radial-gradient(transparent 50%, rgba(4, 61, 174, 0.25));
//   box-shadow: 0.65vw 0.65vw 0.5vw rgba(81, 137, 251, 0.6), -0.65vw 0.65vw 0.5vw rgba(81, 137, 251, 0.6), -0.65vw -0.65vw 0.5vw rgba(81, 137, 251, 0.6), 0.65vw -0.65vw 0.5vw rgba(81, 137, 251, 0.6);
// }
// .puerta__front::after {
//   content: "";
//   position: absolute;
//   top: 50%;
//   right: 0.75vw;
//   width: 1vw;
//   height: 0.25vw;
//   background-color: #9E99C1;
//   box-shadow: 0.125vw 0.125vw 0.25vw rgba(81, 137, 251, 0.6), -0.125vw 0.125vw 0.25vw rgba(81, 137, 251, 0.6), -0.125vw -0.125vw 0.25vw rgba(81, 137, 251, 0.6), 0.125vw -0.125vw 0.25vw rgba(81, 137, 251, 0.6);
//   transform-style: preserve-3d;

// }

// .puerta-l,
// .puerta-r {
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 0.5vw;
//   height: 0.5vw;
// }
// .puerta-l__front,
// .puerta-r__front {
//   width: 0.5vw;
//   height: 16vw;
//   transform-origin: bottom left;
//   transform: rotateX(-90deg) translateZ(-15.5vw);
// }
// .puerta-l__back,
// .puerta-r__back {
//   width: 0.5vw;
//   height: 16vw;
//   transform-origin: top left;
//   transform: rotateX(-90deg) rotateY(180deg) translateX(-0.5vw) translateY(-16vw);
// }
// .puerta-l__right,
// .puerta-r__right {
//   width: 0.5vw;
//   height: 16vw;
//   transform-origin: top left;
//   transform: rotateY(90deg) rotateZ(-90deg) translateZ(0.5vw) translateX(-0.5vw) translateY(-16vw);
// }
// .puerta-l__left,
// .puerta-r__left {
//   width: 0.5vw;
//   height: 16vw;
//   transform-origin: top left;
//   transform: rotateY(-90deg) rotateZ(90deg) translateY(-16vw);
// }
// .puerta-l__top,
// .puerta-r__top {
//   width: 0.5vw;
//   height: 0.5vw;
//   transform-origin: top left;
//   transform: translateZ(16vw);
// }
// .puerta-l__bottom,
// .puerta-r__bottom {
//   width: 0.5vw;
//   height: 0.5vw;
//   transform-origin: top left;
//   transform: rotateY(180deg) translateX(-0.5vw);
// }
// .puerta-l__front,
// .puerta-r__front {
//   background-color: #121332;
// }
// .puerta-l__back,
// .puerta-r__back {
//   background-color: #0b0c1f;
// }
// .puerta-l__right,
// .puerta-r__right {
//   background-color: #0b0c1f;
// }
// .puerta-l__left,
// .puerta-r__left {
//   background-color: #121332;
// }
// .puerta-l__bottom,
// .puerta-r__bottom {
//   background-color: #0b0c1f;
// }

// .puerta-r {
//   left: calc(100% - .5vw);
// }

// .puerta-t {
//   left: 0;
//   top: 0;
//   width: 8vw;
//   height: 0.5vw;
//   transform: translateZ(16vw);
// }
// .puerta-t__front {
//   width: 8vw;
//   height: 0.5vw;
//   transform-origin: bottom left;
//   transform: rotateX(-90deg) translateZ(0vw);
// }
// .puerta-t__back {
//   width: 8vw;
//   height: 0.5vw;
//   transform-origin: top left;
//   transform: rotateX(-90deg) rotateY(180deg) translateX(-8vw) translateY(-0.5vw);
// }
// .puerta-t__right {
//   width: 0.5vw;
//   height: 0.5vw;
//   transform-origin: top left;
//   transform: rotateY(90deg) rotateZ(-90deg) translateZ(8vw) translateX(-0.5vw) translateY(-0.5vw);
// }
// .puerta-t__left {
//   width: 0.5vw;
//   height: 0.5vw;
//   transform-origin: top left;
//   transform: rotateY(-90deg) rotateZ(90deg) translateY(-0.5vw);
// }
// .puerta-t__top {
//   width: 8vw;
//   height: 0.5vw;
//   transform-origin: top left;
//   transform: translateZ(0.5vw);
// }
// .puerta-t__bottom {
//   width: 8vw;
//   height: 0.5vw;
//   transform-origin: top left;
//   transform: rotateY(180deg) translateX(-8vw);
// }
// .puerta-t__front {
//   background-color: #121332;
// }
// .puerta-t__back {
//   background-color: #0b0c1f;
// }
// .puerta-t__right {
//   background-color: #0b0c1f;
// }
// .puerta-t__left {
//   background-color: #121332;
// }
// .puerta-t__top {
//   background-color: #1f2158;
// }
// .puerta-t__bottom {
//   background-color: #0b0c1f;
// }

// .muro {
//   position: absolute;
//   left: 1vw;
//   top: 6vw;
//   width: 0.5vw;
//   height: 16vw;
//   // transform: translateZ(0.51vw);
//   transform: rotate(90deg) translateZ(0.51vw) translate(-200px, -550px);
//     transform-style: preserve-3d;

// }
// .muro__front {
//   width: 4vw;
//   height: 18vw;
//   transform-origin: bottom left;
//   transform: rotateX(-90deg) translateZ(-9vw);
// }
// .muro__back {
//   width: 4vw;
//   height: 17vw;
//   transform-origin: top left;
//   // transform: rotateX(-90deg) rotateY(180deg) translateX(-0.5vw) translateY(-18vw);
//   transform: rotateX(-90deg) rotateY(180deg) translateX(-7vw) translate(-0.51vw) translateY(-18.6vw);
// }
// .muro__right {
//   width: 7vw;
//   height: 18vw;
//   transform-origin: top left;
//   transform: rotateY(90deg) rotateZ(-90deg) translateZ(0.5vw) translateX(-9vw) translateY(-18vw);
// }
// .muro__left {
//   width: 7vw;
//   height: 4vw;
//   transform-origin: top left;
//   transform: rotate(90deg) translateZ(0.51vw) translateY(1vw) translate(1.5vw, -90px) translateX(0px)
//   // transform: rotateY(-90deg) rotateZ(90deg) translateY(-18vw);
// }
// .muro__top {
//   width: 4vw;
//   height: 7vw;
//   transform-origin: top left;
//   transform: translateZ(18vw) translateY(1vw) translate(0vw, 10px);
// }
// .muro__bottom {
//   width: 0.5vw;
//   height: 16vw;
//   transform-origin: top left;
//   transform: rotateY(180deg) translateX(-0.5vw);
//   top:-40px;
// }
// .muro__front {
//   background-color: #0b0c1f;
// }
// .muro__back {
//   background-color: #0b0c1f;
// }
// .muro__right {
//   display: flex;
//   justify-content: center;
//   background-image: radial-gradient(circle, #1f2158, #0b0c1f);
//   overflow: hidden;
// }
// .muro__right::before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   background-image: linear-gradient(to bottom, rgba(40, 125, 210, 0.25), transparent 30%, transparent 70%, rgba(40, 125, 210, 0.15));
// }
// .muro__right::after {
//   content: "";
//   position: absolute;
//   bottom: 0;
//   left: 5%;
//   width: 90%;
//   height: 25%;
//   background-image: linear-gradient(to bottom, rgba(4, 5, 13, 0.75), rgba(4, 5, 13, 0.95));
//   filter: blur(0.75vw);
//     transform-style: preserve-3d;

// }
// .muro__left {
//   background-color: #0b0c1f;
//   // box-shadow: 0.5vw 0.5vw 0.6vw rgba(81, 137, 251, 0.3), 0.5vw -0.5vw 0.6vw rgba(81, 137, 251, 0.3), -0.5vw 0.5vw 0.6vw rgba(81, 137, 251, 0.3), -0.5vw -0.5vw 0.6vw rgba(81, 137, 251, 0.3);
// }
// .muro__top {
//   background-color: #0b0c1f;
// }
// .muro__top::before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   bottom: 0;
//   // background-image: linear-gradient(to left, rgba(40, 125, 210, 0.5), rgba(81, 137, 251, 0.25));
// }
// .muro__bottom {
//   background-color: #0b0c1f;
// }
// .muro__bottom::before {
//   content: "";
//   position: absolute;
//   top: 0;
//   right: 0;
//   width: 5vw;
//   height: 100%;
//   background-image: linear-gradient(to left, #0b0c1f, transparent 50%);
//   transform-origin: right bottom;
//   transform: skewY(-20deg);
//   filter: blur(0.25vw);
// }

// .sillon-c {
//   position: absolute;
//   right: 2vw;
//   bottom: 6vw;
//   width: 6vw;
//   height: 16vw;
//   transform: translateZ(0.5vw);
//     transform-style: preserve-3d;

// }
// .sillon-c::before {
//   content: "";
//   position: absolute;
//   top: -10%;
//   left: -10%;
//   width: 120%;
//   height: 120%;
//   background-color: rgba(11, 12, 31, 0.85);
//   filter: blur(0.75vw);
// }

// .sillon-b {
//   position: absolute;
//   left: 0;
//   top: 1vw;
//   width: 6vw;
//   height: 14vw;
// }
// .sillon-b__front {
//   width: 6vw;
//   height: 3vw;
//   transform-origin: bottom left;
//   transform: rotateX(-90deg) translateZ(11vw);
// }
// .sillon-b__back {
//   width: 6vw;
//   height: 3vw;
//   transform-origin: top left;
//   transform: rotateX(-90deg) rotateY(180deg) translateX(-6vw) translateY(-3vw);
// }
// .sillon-b__right {
//   width: 14vw;
//   height: 3vw;
//   transform-origin: top left;
//   transform: rotateY(90deg) rotateZ(-90deg) translateZ(6vw) translateX(-14vw) translateY(-3vw);
// }
// .sillon-b__left {
//   width: 14vw;
//   height: 3vw;
//   transform-origin: top left;
//   transform: rotateY(-90deg) rotateZ(90deg) translateY(-3vw);
// }
// .sillon-b__top {
//   width: 6vw;
//   height: 14vw;
//   transform-origin: top left;
//   transform: translateZ(3vw);
// }
// .sillon-b__bottom {
//   width: 6vw;
//   height: 14vw;
//   transform-origin: top left;
//   transform: rotateY(180deg) translateX(-6vw);
// }
// .sillon-b__front {
//   background-color: #121332;
// }
// .sillon-b__back {
//   background-color: #0b0c1f;
// }
// .sillon-b__right {
//   background-color: #0b0c1f;
// }
// .sillon-b__left {
//   background-image: linear-gradient(to bottom, #121332, #0b0c1f);
// }
// .sillon-b__left::before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   background-image: linear-gradient(to bottom, rgba(40, 125, 210, 0.1), transparent);
// }
// .sillon-b__top {
//   background-image: linear-gradient(to right, #1f2158, #121332);
//   border-left: 0.1vw solid rgba(158, 153, 193, 0.5);
// }
// .sillon-b__top::before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   background-image: linear-gradient(to right, rgba(40, 125, 210, 0.25), transparent);
// }
// .sillon-b__bottom {
//   background-color: #0b0c1f;
// }

// .sillon-l,
// .sillon-r {
//   position: absolute;
//   left: 0;
//   bottom: 0;
//   width: 6vw;
//   height: 1vw;
// }
// .sillon-l__front,
// .sillon-r__front {
//   width: 6vw;
//   height: 4.5vw;
//   transform-origin: bottom left;
//   transform: rotateX(-90deg) translateZ(-3.5vw);
// }
// .sillon-l__back,
// .sillon-r__back {
//   width: 6vw;
//   height: 4.5vw;
//   transform-origin: top left;
//   transform: rotateX(-90deg) rotateY(180deg) translateX(-6vw) translateY(-4.5vw);
// }
// .sillon-l__right,
// .sillon-r__right {
//   width: 1vw;
//   height: 4.5vw;
//   transform-origin: top left;
//   transform: rotateY(90deg) rotateZ(-90deg) translateZ(6vw) translateX(-1vw) translateY(-4.5vw);
// }
// .sillon-l__left,
// .sillon-r__left {
//   width: 1vw;
//   height: 4.5vw;
//   transform-origin: top left;
//   transform: rotateY(-90deg) rotateZ(90deg) translateY(-4.5vw);
// }
// .sillon-l__top,
// .sillon-r__top {
//   width: 6vw;
//   height: 1vw;
//   transform-origin: top left;
//   transform: translateZ(4.5vw);
// }
// .sillon-l__bottom,
// .sillon-r__bottom {
//   width: 6vw;
//   height: 1vw;
//   transform-origin: top left;
//   transform: rotateY(180deg) translateX(-6vw);
// }
// .sillon-l__front,
// .sillon-r__front {
//   background-image: linear-gradient(to bottom, #121332, #0b0c1f);
// }
// .sillon-l__back,
// .sillon-r__back {
//   background-color: #0b0c1f;
// }
// .sillon-l__right,
// .sillon-r__right {
//   background-image: linear-gradient(to bottom, #0b0c1f, black);
// }
// .sillon-l__left,
// .sillon-r__left {
//   background-image: linear-gradient(to bottom, #121332, #0b0c1f);
// }
// .sillon-l__top,
// .sillon-r__top {
//   background-image: linear-gradient(to right, #1f2158, #0f102b);
//   border-bottom: 0.1vw solid rgba(158, 153, 193, 0.5);
// }
// .sillon-l__top::before,
// .sillon-r__top::before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   background-image: linear-gradient(to right, rgba(40, 125, 210, 0.75), transparent);
// }
// .sillon-l__bottom,
// .sillon-r__bottom {
//   background-color: #0b0c1f;
// }

// .sillon-r {
//   top: 0;
// }

// .sillon-t {
//   position: absolute;
//   right: 0;
//   top: 1vw;
//   width: 1vw;
//   height: 1vw;
//   transform-origin: right;
//   transform: rotateY(7deg);
// }
// .sillon-t__front {
//   width: 1vw;
//   height: 6.5vw;
//   transform-origin: bottom left;
//   transform: rotateX(-90deg) translateZ(7.5vw);
// }
// .sillon-t__back {
//   width: 1vw;
//   height: 6.5vw;
//   transform-origin: top left;
//   transform: rotateX(-90deg) rotateY(180deg) translateX(-1vw) translateY(-6.5vw);
// }
// .sillon-t__right {
//   width: 14vw;
//   height: 6.5vw;
//   transform-origin: top left;
//   transform: rotateY(90deg) rotateZ(-90deg) translateZ(1vw) translateX(-14vw) translateY(-6.5vw);
// }
// .sillon-t__left {
//   width: 14vw;
//   height: 6.5vw;
//   transform-origin: top left;
//   transform: rotateY(-90deg) rotateZ(90deg) translateY(-6.5vw);
// }
// .sillon-t__top {
//   width: 1vw;
//   height: 14vw;
//   transform-origin: top left;
//   transform: translateZ(6.5vw);
// }
// .sillon-t__bottom {
//   width: 1vw;
//   height: 14vw;
//   transform-origin: top left;
//   transform: rotateY(180deg) translateX(-1vw);
// }
// .sillon-t__front {
//   background-image: linear-gradient(to bottom, #0b0c1f, #070814);
// }
// .sillon-t__back {
//   background-image: linear-gradient(to bottom, #0b0c1f, #0b0c1f);
// }
// .sillon-t__right {
//   background-image: linear-gradient(to bottom, #0b0c1f, black);
// }
// .sillon-t__left {
//   background-color: #121332;
// }
// .sillon-t__top {
//   background-image: linear-gradient(to right, #1f2158, #0f102b);
//   border-bottom: 0.1vw solid rgba(158, 153, 193, 0.5);
// }
// .sillon-t__top::before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   background-image: linear-gradient(to right, rgba(40, 125, 210, 0.5), transparent);
// }
// .sillon-t__bottom {
//   background-color: #0b0c1f;
// }

// .mesa-c {
//   position: absolute;
//   left: 7vw;
//   top: 9.5vw;
//   width: 10vw;
//   height: 9vw;
//   transform: translateZ(0.5vw);
//     transform-style: preserve-3d;

// }

// .mesa-shadow {
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   border-radius: 10%;
//   background-color: rgba(11, 12, 31, 0.95);
//   filter: blur(1vw);
//   transform: translateZ(0);
// }

// .mesa {
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 10vw;
//   height: 9vw;
//   transform: translateZ(2vw);
// }
// .mesa__front {
//   width: 10vw;
//   height: 0.5vw;
//   transform-origin: bottom left;
//   transform: rotateX(-90deg) translateZ(8.5vw);
// }
// .mesa__back {
//   width: 10vw;
//   height: 0.5vw;
//   transform-origin: top left;
//   transform: rotateX(-90deg) rotateY(180deg) translateX(-10vw) translateY(-0.5vw);
// }
// .mesa__right {
//   width: 9vw;
//   height: 0.5vw;
//   transform-origin: top left;
//   transform: rotateY(90deg) rotateZ(-90deg) translateZ(10vw) translateX(-9vw) translateY(-0.5vw);
// }
// .mesa__left {
//   width: 9vw;
//   height: 0.5vw;
//   transform-origin: top left;
//   transform: rotateY(-90deg) rotateZ(90deg) translateY(-0.5vw);
// }
// .mesa__top {
//   width: 10vw;
//   height: 9vw;
//   transform-origin: top left;
//   transform: translateZ(0.5vw);
// }
// .mesa__bottom {
//   width: 10vw;
//   height: 9vw;
//   transform-origin: top left;
//   transform: rotateY(180deg) translateX(-10vw);
// }
// .mesa__front {
//   background-image: linear-gradient(to right, #0b0c1f, black);
// }
// .mesa__front::before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   background-image: linear-gradient(45deg, rgba(81, 137, 251, 0.125), rgba(180, 205, 253, 0.25), transparent 55%);
// }
// .mesa__back {
//   background-color: #0b0c1f;
// }
// .mesa__right {
//   background-color: black;
// }
// .mesa__left {
//   background-color: #121332;
// }
// .mesa__top {
//   background-image: linear-gradient(45deg, #0e0f27, #04050d);
//   overflow: hidden;
// }
// .mesa__top::before {
//   content: "";
//   position: absolute;
//   bottom: 0;
//   width: 50%;
//   height: 50%;
//   background-image: linear-gradient(45deg, rgba(81, 137, 251, 0.5), rgba(251, 250, 254, 0.125) 50%, transparent 55%);
//   filter: blur(0.5vw);
// }
// .mesa__top::after {
//   content: "";
//   position: absolute;
//   left: 2vw;
//   top: 2vw;
//   width: 20%;
//   height: 50%;
//   border-radius: 50%;
//   background-color: rgba(40, 125, 210, 0.079);
//   transform: rotateZ(-40deg);
//   filter: blur(0.75vw);
//     transform-style: preserve-3d;

// }
// .mesa__bottom {
//   background-color: #0b0c1f;
// }

// .mesa-p {
//   position: absolute;
//   width: 0.25vw;
//   height: 0.25vw;
// }
// .mesa-p__front {
//   width: 0.25vw;
//   height: 1.75vw;
//   transform-origin: bottom left;
//   transform: rotateX(-90deg) translateZ(-1.5vw);
// }
// .mesa-p__back {
//   width: 0.25vw;
//   height: 1.75vw;
//   transform-origin: top left;
//   transform: rotateX(-90deg) rotateY(180deg) translateX(-0.25vw) translateY(-1.75vw);
// }
// .mesa-p__right {
//   width: 0.25vw;
//   height: 1.75vw;
//   transform-origin: top left;
//   transform: rotateY(90deg) rotateZ(-90deg) translateZ(0.25vw) translateX(-0.25vw) translateY(-1.75vw);
// }
// .mesa-p__left {
//   width: 0.25vw;
//   height: 1.75vw;
//   transform-origin: top left;
//   transform: rotateY(-90deg) rotateZ(90deg) translateY(-1.75vw);
// }
// .mesa-p__top {
//   width: 0.25vw;
//   height: 0.25vw;
//   transform-origin: top left;
//   transform: translateZ(1.75vw);
// }
// .mesa-p__bottom {
//   width: 0.25vw;
//   height: 0.25vw;
//   transform-origin: top left;
//   transform: rotateY(180deg) translateX(-0.25vw);
// }
// .mesa-p__front {
//   background-color: #0b0c1f;
// }
// .mesa-p__back {
//   background-color: #0b0c1f;
// }
// .mesa-p__right {
//   background-color: black;
// }
// .mesa-p__left {
//   background-color: #121332;
// }
// .mesa-p__top {
//   background-color: #1f2158;
// }
// .mesa-p__bottom {
//   background-color: #0b0c1f;
// }
// .mesa-p__bottom::before {
//   content: "";
//   position: absolute;
//   width: 600%;
//   height: 200%;
//   top: 0;
//   right: 0;
//   border-radius: 10%;
//   transform: translateZ(-0.1vw);
//   background-color: rgba(0, 0, 0, 0.75);
//   filter: blur(0.35vw);
// }
// .mesa-p:nth-of-type(1) {
//   left: 0.5vw;
//   top: 0.5vw;
// }
// .mesa-p:nth-of-type(2) {
//   right: 0.5vw;
//   top: 0.5vw;
// }
// .mesa-p:nth-of-type(3) {
//   left: 0.5vw;
//   bottom: 0.5vw;
// }
// .mesa-p:nth-of-type(4) {
//   right: 0.5vw;
//   bottom: 0.5vw;
// }

// .tablet {
//   position: absolute;
//   left: 13vw;
//   top: 12vw;
//   width: 2vw;
//   height: 2.5vw;
//   transform: translateZ(3.01vw) rotateZ(15deg);
//     transform-style: preserve-3d;

// }
// .tablet__front {
//   width: 2vw;
//   height: 0.125vw;
//   transform-origin: bottom left;
//   transform: rotateX(-90deg) translateZ(2.375vw);
//     transform-style: preserve-3d;

// }
// .tablet__back {
//   width: 2vw;
//   height: 0.125vw;
//   transform-origin: top left;
//   transform: rotateX(-90deg) rotateY(180deg) translateX(-2vw) translateY(-0.125vw);
// }
// .tablet__right {
//   width: 2.5vw;
//   height: 0.125vw;
//   transform-origin: top left;
//   transform: rotateY(90deg) rotateZ(-90deg) translateZ(2vw) translateX(-2.5vw) translateY(-0.125vw);
// }
// .tablet__left {
//   width: 2.5vw;
//   height: 0.125vw;
//   transform-origin: top left;
//   transform: rotateY(-90deg) rotateZ(90deg) translateY(-0.125vw);
// }
// .tablet__top {
//   width: 2vw;
//   height: 2.5vw;
//   transform-origin: top left;
//   transform: translateZ(0.125vw);
// }
// .tablet__bottom {
//   width: 2vw;
//   height: 2.5vw;
//   transform-origin: top left;
//   transform: rotateY(180deg) translateX(-2vw);
// }
// .tablet__front {
//   background-color: #8079ae;
// }
// .tablet__back {
//   background-color: #383358;
// }
// .tablet__right {
//   background-color: #383358;
// }
// .tablet__left {
//   background-color: #9E99C1;
// }
// .tablet__top {
//   background-color: #83abfc;
//   border: 0.2vw solid rgba(81, 137, 251, 0.25);
//   animation: pantalla-tablet 0.25s infinite;
// }
// .tablet__bottom {
//   background-color: black;
//   box-shadow: 0.25vw 0.25vw 0.5vw rgba(24, 74, 124, 0.75), -0.25vw 0.25vw 0.5vw rgba(24, 74, 124, 0.75), -0.25vw -0.25vw 0.5vw rgba(24, 74, 124, 0.75), 0.25vw -0.25vw 0.5vw rgba(24, 74, 124, 0.75);
// }

// /**********************/
// /**********************/
// @keyframes pantalla-tablet {
//   from {
//     background-color: #6a9afb;
//   }
// }
// @keyframes pantalla-tv {
//   from {
//     background-color: #6a9afb;
//     box-shadow: 0.125vw 0.125vw 0vw rgba(81, 137, 251, 0), -0.125vw 0.125vw 0vw rgba(81, 137, 251, 0), 0.125vw -0.125vw 0vw rgba(81, 137, 251, 0), -0.125vw -0.125vw 0vw rgba(81, 137, 251, 0);
//   }
// }
// @keyframes luz-tv {
//   from {
//     background-image: radial-gradient(rgba(36, 112, 188, 0.8), rgba(56, 121, 250, 0.8));
//   }
// }
</style>
